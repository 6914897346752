import { Component, EventEmitter, OnInit, ɵbypassSanitizationTrustHtml } from "@angular/core";
import { LessonsService } from "src/services/lessons.service";
import { CdkDragDrop, CdkDropList, CdkDrag, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormControl } from "@angular/forms";
import DOMPurify from 'dompurify';
import { SafeHtml } from "@angular/platform-browser";
import { Question, isQuestion, validateQuestion } from "src/types/question";
import { ToastsService } from "src/services/toasts.service";
import { GradeLevel, Lesson, Module, isCategory, isCourse, isCurriculum, isGrade, isLesson, isSubject } from "src/types/modules";
import { ModulesService } from "src/services/modules.service";
import { UsersService } from "src/services/users.service";
import { User } from "src/types/users";
import { ModuleType, gameModeMapping, getModuleType, moduleMapping, qaMapping } from "./ui-mappings";
import { Router, ActivatedRoute, Params} from "@angular/router";


type FilterOptions = '' | 'Floater'

@Component({
    selector: 'admin-catalog-legacy',
    templateUrl: './admin-catalog-legacy.component.html',
    styleUrls: ['./admin-catalog-legacy.component.scss'],
})
export class AdminCatalogLegacyPage implements OnInit{

    user: User | null = null;

    creating: boolean = false;
    editing: boolean = false;
    changingSubOrder: boolean = false;

    confirmModalOpen: boolean = false;
    confirmModalMessage: string = 'Are you sure you want to delete this lesson?';
    confirmModalText: string = 'Delete';
    declineModalText: string = 'Cancel';

    moduleTypeDropdownActive: boolean = false;

    editModalOpen: boolean = false;
    moduleSave$: EventEmitter<void> = new EventEmitter<void>();
    closeBackground$: EventEmitter<void> = new EventEmitter<void>();
    nameModel: string = '';
    descriptionModel: string = '';
    parentId: string;

    questionEditModalOpen: boolean = false;

    searchControl: FormControl = new FormControl('');

    moduleType: ModuleType = 'Lessons';
    moduleTypeOptions: ModuleType[] = ['Lessons', 'Courses', 'Categories', 'Grades', 'Subjects', 'Curriculum'];

    page: number = 0;
    pageLimit: number = 10;
    pageLimitOptions: number[] = [5, 10, 25, 50];

    filter: FilterOptions = '';
    filterOptions: FilterOptions[] = ['', 'Floater'];

    modules: (Module & { expanded: boolean })[] = [];
    currentModule: any;
    currentModuleType: ModuleType | null = null;
    currentModuleIndex: number | null = null;
    currentSubIndex: number | null = null;
    currentQuestion: Question | null = null;
    currentQuestionIndex: number | null = null;

    gradeLevel: GradeLevel | null = null;

    searching: boolean = false;


    constructor(private lessonsService: LessonsService, private modulesService: ModulesService, private toastsService: ToastsService, private usersService: UsersService, private router: Router, private route: ActivatedRoute) {
        this.usersService.setUserFocusMode(false);
        
        this.moduleSave$.subscribe(() => {
            this.saveModule();
        });

        this.closeBackground$.subscribe(() => {
            this.editModalOpen = false;
            this.creating = false;
            this.resetAllValues();
        });
      

        // Check for stored module type in sessionStorage
        const cachedModuleType = sessionStorage.getItem('admin_currentModuleType');
        if(cachedModuleType && this.moduleTypeOptions.findIndex((i) => cachedModuleType === i) === -1) {
            this.moduleType = 'Lessons';
            sessionStorage.setItem('admin_currentModuleType', 'Lessons');
        } else if(cachedModuleType) this.moduleType = cachedModuleType as ModuleType;

        // Set mappings for search functions
        moduleMapping['Lessons'].searchFunction = this.lessonsService.getLessons.bind(this.lessonsService);
        moduleMapping['Courses'].searchFunction = this.modulesService.getCourses.bind(this.modulesService);
        moduleMapping['Categories'].searchFunction = this.modulesService.getCategories.bind(this.modulesService);
        moduleMapping['Grades'].searchFunction = this.modulesService.getGrades.bind(this.modulesService);
        moduleMapping['Subjects'].searchFunction = this.modulesService.getSubjects.bind(this.modulesService);
        moduleMapping['Curriculum'].searchFunction = this.modulesService.getCurriculum.bind(this.modulesService);

        // Set mappings for create functions
        moduleMapping['Lessons'].createFunction = this.lessonsService.createLesson.bind(this.lessonsService);
        moduleMapping['Courses'].createFunction = this.modulesService.createCourse.bind(this.modulesService);
        moduleMapping['Categories'].createFunction = this.modulesService.createCategory.bind(this.modulesService);
        moduleMapping['Grades'].createFunction = this.modulesService.createGrade.bind(this.modulesService);
        moduleMapping['Subjects'].createFunction = this.modulesService.createSubject.bind(this.modulesService);
        moduleMapping['Curriculum'].createFunction = this.modulesService.createCurriculum.bind(this.modulesService);

        // Set mappings for update functions
        moduleMapping['Lessons'].updateFunction = this.lessonsService.updateLesson.bind(this.lessonsService);
        moduleMapping['Courses'].updateFunction = this.modulesService.updateCourse.bind(this.modulesService);
        moduleMapping['Categories'].updateFunction = this.modulesService.updateCategory.bind(this.modulesService);
        moduleMapping['Grades'].updateFunction = this.modulesService.updateGrade.bind(this.modulesService);
        moduleMapping['Subjects'].updateFunction = this.modulesService.updateSubject.bind(this.modulesService);
        moduleMapping['Curriculum'].updateFunction = this.modulesService.updateCurriculum.bind(this.modulesService);

        // Set mappings for delete functions
        moduleMapping['Lessons'].deleteFunction = this.lessonsService.deleteLesson.bind(this.lessonsService);
        moduleMapping['Courses'].deleteFunction = this.modulesService.deleteCourse.bind(this.modulesService);
        moduleMapping['Categories'].deleteFunction = this.modulesService.deleteCategory.bind(this.modulesService);
        moduleMapping['Grades'].deleteFunction = this.modulesService.deleteGrade.bind(this.modulesService);
        moduleMapping['Subjects'].deleteFunction = this.modulesService.deleteSubject.bind(this.modulesService);
        moduleMapping['Curriculum'].deleteFunction = this.modulesService.deleteCurriculum.bind(this.modulesService);

        this.init();
    }
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            const query = params['query'] || '';
            const page = +params['page'] || this.page; 
            const limit = +params['limit'] || this.pageLimit;
            const moduleType = params['moduleType'] || this.moduleType;
            this.searchControl.setValue(query, {emitEvent: false});
            this.page = page;
            this.pageLimit = limit;
            this.moduleType = moduleType;
            this.search();
        });
    }
    async init() {
        this.search();
        this.user = await this.usersService.getUser();
    }

    questionBackgroundClose() {
        this.questionEditModalOpen = false;
        // Wait for animation to finish, then set currentQuestion to null
        setTimeout(() => {
            this.resetAllValues();
        }, 500);
    };

    async search() {
        this.searching = true;
        this.resetAllValues();
    

        const query = this.searchControl.value;
        const page = this.page; 
        const limit = this.pageLimit;
    
        this.modules = await moduleMapping[this.moduleType].searchFunction(page, limit, query, this.filter);
        this.modules = this.modules.map((item: any) => ({ ...item, expanded: false }));
        this.updateBrowserQueryParams(query, page, limit);
    
        this.searching = false;
    }
    updateBrowserQueryParams(query: string, page: number, limit: number) {
        const queryParams: { [key: string]: any } = {
            page,
            limit,
            moduleType: this.moduleType,
            query
        };

        // update the URL's query parameters
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: queryParams,
            queryParamsHandling: 'merge', 
            replaceUrl: false
        });
    }

    async moduleTypeChange() {
        sessionStorage.setItem('admin_currentModuleType', this.moduleType);
        this.searchControl.reset();
        if(!this.searching) this.search();
    }

    async pageLimitChange() {
        this.search();
    }

    filterChange() {
        this.search();
    }

    copyId($event: any, id: string) {
        $event.stopPropagation();
        navigator.clipboard.writeText(id);
    }

    gotoParentAnyModule($event: any, module: Module) {
        $event.stopPropagation();
        const moduleType = getModuleType(module);
        const parentIdField = moduleMapping[moduleType].parentIdField;
        const parentId = (module as any)[parentIdField];
        this.moduleType = moduleMapping[moduleType].parentModuleType;
        this.closeBackground$.emit();
        this.searchControl.setValue(parentId);
        this.search();
    }

    async saveModule() {
        try {
            if(!this.creating && !this.editing && !this.changingSubOrder) {
                this.toastsService.addToast({ title: 'Update Error', description: 'No changes were detected.' });
                return;
            }

            if(this.creating) {
                this.createModule();
                return;
            }

            const moduleType: ModuleType = this.currentModuleType || getModuleType(this.currentModule);
            let updates: any = {};

            if(this.editing) {
                updates.name = this.nameModel;
                updates.description = this.descriptionModel;

                const currentParentId = this.currentModule[moduleMapping[moduleType].parentIdField];
                if(moduleType !== 'Curriculum' && this.parentId && currentParentId && this.parentId !== currentParentId) {
                    this.currentModule[moduleMapping[moduleType].parentIdField] = this.parentId;
                    updates[moduleMapping[moduleType].parentIdField] = this.parentId;
                }
            }

            if(this.changingSubOrder) {
                updates[moduleMapping[moduleType].subModuleTypePlural.toLowerCase()] = this.currentModule[moduleMapping[moduleType].subModuleTypePlural.toLowerCase()];
            }

            // Set to either _id (if its parent module) or id (if it's a sub module)
            const updateId = this.currentModule._id || this.currentModule.id;

            const response = await moduleMapping[moduleType].updateFunction(updateId, updates);
            this.editModalOpen = false;
            this.currentModule.name = updates.name;
            this.currentModule.description = updates.description;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                this.currentModule = null;
                this.resetAllValues();
            }, 500);
            this.toastsService.addToast({ title: 'Update Successful', description: 'Module has been successfully updated.' });
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({ title: 'Server error', description: message });
        }
    }

    async saveQuestion() {
        try {
            if(!this.currentQuestion) return;
            try {
                validateQuestion(this.currentQuestion);
            } catch(error: any) {
                this.toastsService.addToast({ title: 'Question Error', description: error.message, type: "error" });
                return;
            }

            if(this.creating) {
                this.currentModule.questions.push(this.currentQuestion);
                const response = await this.lessonsService.updateLesson(this.currentModule._id, { questions: this.currentModule.questions });

                this.creating = false;
                this.questionEditModalOpen = false;
                // Wait for animation to finish, then set currentQuestion to null
                setTimeout(() => {
                    this.resetAllValues();
                }, 500);
                return;
            }

            if(this.currentQuestionIndex === null) return;
            await this.lessonsService.updateLesson(this.currentModule._id, { questions: this.currentModule.questions });
            this.currentModule.questions[this.currentQuestionIndex] = this.currentQuestion;
            this.questionEditModalOpen = false;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                this.resetAllValues();
            }, 500);
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({ title: 'Server error', description: message });
        }
    }

    getSubItems(module: Module): any[] {
        const moduleType: ModuleType = getModuleType(module);
        const subType = moduleMapping[moduleType].subModuleTypePlural.toLowerCase();
        const response = (module as any)[subType];
        return response || [];
    }

    getSubTitle(item: any) {
        if(isQuestion(item)) {
            return item.data.instruction;
        } 
        return item.name;
    }

    getSubDescription(item: any) {
        if(isQuestion(item)) {
            return item.data.sentence;
        } 
        return item.description;
    }

    backgroundClick() {
        this.moduleTypeDropdownActive = false;
    }

    gotoLessonQaMode(lesson: any) {
        const lessonId = lesson._id || lesson.id;
        this.router.navigate(['student', 'admin', 'lesson', lessonId]);
    }

    drop(event: CdkDragDrop<any[]>, moduleIndex: any) {
        this.changingSubOrder = true;
        const module = this.modules[moduleIndex];
        this.currentModule = module;
        const moduleType: ModuleType = getModuleType(module);
        const subType = moduleMapping[moduleType].subModuleTypePlural.toLowerCase();
        moveItemInArray((module as any)[subType], event.previousIndex, event.currentIndex);
        this.saveModule();
    }

    selectItem(module: Module, moduleType?: ModuleType, parentId?: string) {
        this.editing = true;
        this.editModalOpen = true;
        this.currentModule = module;
        this.descriptionModel = module.description;
        this.nameModel = module.name;

        if(!moduleType) moduleType = getModuleType(module);
        this.currentModuleType = moduleType;
        if(!parentId) parentId = (module as any)[moduleMapping[moduleType].parentIdField] as string || '';
        this.parentId = parentId;
    }

    selectSub(module: Module, moduleIndex: number, sub: any, subIndex: number) {
        if(this.moduleType === 'Lessons') {
            this.selectQuestion(module, moduleIndex, sub, subIndex);
        } else {
            const moduleType: ModuleType = moduleMapping[this.moduleType].subModuleTypePlural;
            this.selectItem(sub, moduleType, module._id);
        }
    }

    selectQuestion(lesson: any, lessonIndex: number, question: any, qIndex: number) {
        this.currentModule = lesson;
        this.currentModuleIndex = lessonIndex;
        this.currentQuestion = question;
        this.currentQuestionIndex = qIndex;
        this.questionEditModalOpen = true;
    }

    gotoParent(parentId: string) {
        const moduleType: ModuleType = this.currentModuleType || getModuleType(this.currentModule);
        this.closeBackground$.emit();
        this.moduleType = moduleMapping[moduleType].parentModuleType;
        this.searchControl.setValue(parentId);
        this.search();
    }

    gotoSub($event: any, subId: string) {
        $event.stopPropagation();
        this.moduleType = moduleMapping[this.moduleType].subModuleTypePlural;
        this.searchControl.setValue(subId);
        this.search();
    }

    sanitize(text: string): SafeHtml {
        const sanitizedContent = DOMPurify.sanitize(text);
        return ɵbypassSanitizationTrustHtml(sanitizedContent);
    }

    resetAllValues() {
        this.currentModule = null;
        this.currentModuleIndex = null;
        this.currentQuestion = null;
        this.currentQuestionIndex = null;
        this.currentSubIndex = null;
        this.creating = false;
        this.editing = false;
        this.changingSubOrder = false;
        this.nameModel = '';
        this.descriptionModel = '';
        this.parentId = '';
    }



    // Functions for modals

    declineFn() {
        this.confirmModalOpen = false;
        this.resetAllValues();
    }

    subOpenCreateModal($event: any, parentModule: Module, parentModuleIndex: number, moduleType?: ModuleType | 'Questions') {
        if(moduleType === 'Questions') this.currentModule = parentModule;
        this.currentModuleIndex = parentModuleIndex;
        this.parentId = parentModule._id;
        this.openCreateModal($event, moduleType);
    }

    openCreateModal($event: any, moduleType?: ModuleType | 'Questions') {
        $event.stopPropagation();
        if(!moduleType) moduleType = this.moduleType;
        if(moduleType !== 'Questions') this.currentModuleType = moduleType;

        this.creating = true;

        if(moduleType === 'Questions') {
            const defaultType = (this.currentModule !== null && this.currentModule.defaultQuestionType) || (this.currentModule !== null && this.currentModule.questions[0]?.type);
            this.currentQuestion = { 
                type: defaultType || 'selectTheWord',
                data: {
                    sentence: '',
                    instruction: '',
                    words: []
                }
            } as any;
            this.questionEditModalOpen = true;
            return;
        }

        this.currentModule = {
            name: '',
            description: ''
        };

        // The following is done to trick the checks into thinking they are of whatever module type.
        if(moduleType === 'Curriculum') this.currentModule.subjects = [];
        else this.currentModule[moduleMapping[moduleType].parentIdField] = '';

        if(moduleType !== 'Grades') this.gradeLevel = null; else this.gradeLevel = 'PK';
        this.editModalOpen = true;
    }

    async createModule() {
        this.currentModule.name = this.nameModel;
        this.currentModule.description = this.descriptionModel;
        try {
            let newModule: Module | null = null;

            const moduleType: ModuleType = getModuleType(this.currentModule);
            this.currentModule[moduleMapping[moduleType].parentIdField] = this.parentId;

            if(moduleType === 'Grades') {
                if(this.gradeLevel === null) {
                    this.toastsService.addToast({ title: 'Creation Error', description: 'Grade Level must be set.' });
                    return;
                }
                this.currentModule.gradeLevel = this.gradeLevel;
            }

            newModule = await moduleMapping[moduleType].createFunction(this.currentModule);
            
            if(newModule) {
                // If there is a currentModuleIndex set, it means we're creating a sub module. Push it into the parent module on UI.
                if(this.currentModuleIndex !== null) {
                    const subItemsLabel = moduleMapping[this.moduleType].subModuleTypePlural.toLowerCase();
                    (this.modules[this.currentModuleIndex] as any)[subItemsLabel].push(newModule);
                } else {
                    this.modules.push({ ...newModule, expanded: false });
                }
            }
            this.editModalOpen = false;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                this.resetAllValues();
            }, 500);
            this.toastsService.addToast({ title: 'Creation Successful', description: 'Module has been successfully created.' });
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({ title: 'Server error', description: message });
        }
    }

    openDeleteModal($event: any, itemForDeletion: Module | Question, moduleIndex: number, parentModule?: Module, subModuleIndex?: number) {
        $event.stopPropagation();
        
        if(isQuestion(itemForDeletion)) {
            if(subModuleIndex === undefined || parentModule === undefined) return;
            this.currentModule = parentModule;
            this.currentModuleIndex = moduleIndex;
            this.currentQuestion = itemForDeletion;
            this.currentQuestionIndex = subModuleIndex;
            this.confirmModalMessage = `Are you sure you want to delete this question?`;
        } else if(subModuleIndex !== undefined) {
            this.currentModule = itemForDeletion;
            this.currentModuleIndex = moduleIndex;
            this.currentSubIndex = subModuleIndex;
            this.confirmModalMessage = `Are you sure you want to delete this ${this.getSubModuleType().toLowerCase()}?`;
        } else {
            this.currentModule = itemForDeletion;
            this.currentModuleIndex = moduleIndex;
            this.confirmModalMessage = `Are you sure you want to delete this ${this.getModuleType().toLowerCase()}?`;
        }
        this.confirmModalOpen = true;
    }

    async deleteFn() {
        if(this.currentQuestionIndex !== null) {
            this.deleteQuestion();
            return;
        }
        this.deleteModule();
    }

    async deleteModule() {
        try {
            let response: any = null;
            if(this.currentModule)
            if(isLesson(this.currentModule)) {
                response = await this.lessonsService.deleteLesson(this.currentModule._id);
            } else if(isCourse(this.currentModule)) {
                response = await this.modulesService.deleteCourse(this.currentModule._id);
            } else if(isCategory(this.currentModule)) {
                response = await this.modulesService.deleteCategory(this.currentModule._id);
            } else if(isGrade(this.currentModule)) {
                response = await this.modulesService.deleteGrade(this.currentModule._id);
            } else if(isSubject(this.currentModule)) {
                response = await this.modulesService.deleteSubject(this.currentModule._id);
            } else if(isCurriculum(this.currentModule)) {
                response = await this.modulesService.deleteCurriculum(this.currentModule._id);
            }

            // This means it was none of the above because it is a sub
            if(response === null && this.currentSubIndex !== null) {
                const subType = this.getSubModuleType();
                if(subType === 'Lesson') {
                    response = await this.lessonsService.deleteLesson(this.currentModule.id);
                } else if(subType === 'Course') {
                    response = await this.modulesService.deleteCourse(this.currentModule.id);
                } else if(subType === 'Category') {
                    response = await this.modulesService.deleteCategory(this.currentModule.id);
                } else if(subType === 'Grade') {
                    response = await this.modulesService.deleteGrade(this.currentModule.id);
                } else if(subType === 'Subject') {
                    response = await this.modulesService.deleteSubject(this.currentModule.id);
                }
            }

            if(response && response.acknowledged && this.currentModuleIndex !== null) {
                this.toastsService.addToast({ title: 'Deletion Successful', description: 'Module has been successfully deleted.' });
                if(this.currentSubIndex !== null) {
                    const subItemsLabel = moduleMapping[this.moduleType].subModuleTypePlural;
                    (this.modules[this.currentModuleIndex] as any)[subItemsLabel].splice(this.currentSubIndex, 1);
                } else {
                    this.modules.splice(this.currentModuleIndex, 1);
                }
            } else {
                this.toastsService.addToast({ title: 'Unknown Error', description: 'An unkown error has occurred. Deletion could not be confirmed.' });
            }
            this.confirmModalOpen = false;
            this.resetAllValues();
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({ title: 'Server error', description: message });
        }
    }

    async deleteQuestion() {
        try {
            this.currentModule.questions.splice(this.currentQuestionIndex, 1);
            this.lessonsService.updateLesson(this.currentModule._id, { questions: this.currentModule.questions });
            this.confirmModalOpen = false;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                this.resetAllValues();
            }, 500);
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({ title: 'Server error', description: message });
        }
    }



    // Handle texts for dynamic module selection

    getModuleType() {
        if(this.moduleType === 'Categories') return 'Category';
        if(this.moduleType === 'Curriculum') return 'Curriculum';
        return this.moduleType.charAt(0).toUpperCase() + this.moduleType.substring(1, this.moduleType.length - 1);
    }

    getSubModuleType() {
        const type = moduleMapping[this.moduleType].subModuleType;
        return type || 'Unknown';
    }

    getSubTitleField() {
        if(this.moduleType === 'Lessons') {
            return 'Sentence';
        }
        return 'Name';
    }

    getSubDescriptionField() {
        if(this.moduleType === 'Lessons') {
            return 'Instruction';
        }
        return 'Description';
    }

    getChildModuleType() {
        const type = moduleMapping[this.moduleType].subModuleTypePlural;
        return type || 'Unknown';
    }



    // Get colors and texts for UI

    getGameModeField(module: Module, field: string) {
        if(!isLesson(module)) return;
        if(module.questions.length === 0) return gameModeMapping['unknown'][field];
        const mapping = gameModeMapping[module.questions[0].type];
        if(!mapping) return gameModeMapping['unknown'][field];
        return mapping[field];
    }

    getQAField(question: any, field: string) {
        let status = question.qaStatus || 'unknown';
        if(question.valid !== undefined && !question.valid) return qaMapping['flagged'][field];
        const mapping = qaMapping[status];
        if(!mapping) return qaMapping['unknown'][field];
        return mapping[field];
    }

}