import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ModulesTabComponent } from './modules-tab.component';
import { CosmittTableModule } from 'src/components/c-table/c-table.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CosmittPopoverModule } from 'src/components/c-popover/c-popover.module';
import { CosmittModalModule } from 'src/components/modal/modal.module';
import { CosmittTextEditorModule } from 'src/components/c-text-editor/c-text-editor.module';
import { CosmittInputModule } from 'src/components/c-input/c-input.module';
import { ConfirmModalModule } from 'src/components/confirm-modal/confirm-modal.module';
import { ModuleEditorModule } from 'src/components/module-editor/module-editor.module';
import { ClickOutsideModule } from 'src/directives/click-outside.module';
import { CosmittButtonGroupModule } from 'src/components/c-button-group/c-button-group.module';
import { CosmittSelectModule } from 'src/components/c-select/c-select.module';

@NgModule({
  declarations: [ModulesTabComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    CosmittTableModule,
    CosmittPopoverModule,
    CosmittModalModule,
    CosmittTextEditorModule,
    CosmittInputModule,
    ConfirmModalModule,
    ModuleEditorModule,
    ClickOutsideModule,
    CosmittButtonGroupModule,
    CosmittSelectModule
  ],
  exports: [ModulesTabComponent],
})
export class ModulesTabModule {}
