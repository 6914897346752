import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SearchInputComponent } from "./search-input.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [SearchInputComponent],
    imports: [ReactiveFormsModule, FormsModule, BrowserModule],
    exports: [SearchInputComponent]
})
export class SearchInputModule {}  