import { BaseQuestion, Question, QuestionType } from "./question";

export type GradeLevel = 'PK' | 'K' | '1' | '2'| '3' | '4' | '5' | '6' | '7' | '8' | '9' | '10' | '11' | '12';

export type ModuleQaStatus = 'notChecked' | "flagged" | "manuallyVerified" | "automaticallyVerified"| "floater";

export interface BaseModule {
    _id: string;
    name: string;
    description: string;
    tags?: [];
    speechData?: {
        name: string;
        description: string;
    };
    qaStatus: ModuleQaStatus[];
    creator: {
        userId: string;
        name: string;
        type: string;
    };
    exp?: number;
    gold?: number;
    gems?: number;
    photoUrl: string;
    prerequisites?: Prerequisites;
    type: string;
    createdOn?: Date;
}

export interface Prerequisites {
    gradeIds?: string[];
    courseIds?: string[];
    lessonIds?: string[];
}

export interface CosmittSkill {
    _id: string;
    name: string;
    tag: string;
    description: string;
    color: string;
}

export interface CurriculumLesson {
    _id: string;
    name: string;
    description: string;
    photoUrl?: string;
    gradeSkillIds: string[];
}

export interface CurriculumLevel {
    _id: string;
    name: string;
    description: string;
    lessons: CurriculumLesson[];
}

export interface CurriculumGrade {
    _id: string;
    name: string;
    description: string;
    skills: CurriculumSkill[];
    levels: CurriculumLevel[];
}

export interface CurriculumSkill {
    _id: string;
    tag: string;
    name: string;
    description: string;
    baseSkillReferenceId: string; // This relates to an entry in the 'skill' collection
    color: string;
}

export interface CurriculumSubject {
    _id: string;
    name: string;
    description: string;
    photoUrl: string;
    grades: CurriculumGrade[];
}

export interface Curriculum {
    _id: string;
    name: string;
    description: string;
    qaStatus: ModuleQaStatus[],
    photoUrl: string,
    createdOn?: Date,
    subjects: CurriculumSubject[];
}

export const isCurriculum = (module: Module): module is Curriculum => {
    return module !== undefined && (module as any).subjects !== undefined;
}



export interface Subject extends BaseModule {
    curriculumId: string;
    grades: {
        name: string;
        description: string;
        id: string;
        photoUrl: string;
    }[];
}

export const isSubject = (module: Module): module is Subject => {
    return module !== undefined && ((module as any).grades !== undefined || (module as any).curriculumId !== undefined);
}



export interface Grade extends BaseModule {
    gradeLevel: GradeLevel;
    subjectId: string;
    categories: {
        name: string;
        description: string;
        id: string;
        photoUrl: string;
    }[];
}

export const isGrade = (module: Module): module is Grade => {
    return module !== undefined && ((module as any).categories !== undefined || (module as any).subjectId !== undefined);
}



export interface Category extends BaseModule {
    gradeId: string;
    courses: [{
        name: string;
        description: string;
        id: string;
        photoUrl: string;
    }];
}

export const isCategory = (module: Module): module is Category => {
    return module !== undefined && ((module as any).courses !== undefined || (module as any).gradeId !== undefined);
}



export interface Course extends BaseModule {
    categoryId: string;
    lessons: {
        name: string;
        description: string;
        id: string;
        photoUrl: string;
        tags?: []
    }[];
}

export const isCourse = (module: Module): module is Course => {
    return module !== undefined && ((module as any).lessons !== undefined || (module as any).categoryId !== undefined);
}



export interface Lesson extends BaseModule {
    courseId: string;
    questions: Question[];
    introSteps: LessonStep[];
    defaultInstruction?: string;
    defaultType?: QuestionType;
}
export const isLesson = (module: Module): module is Lesson => {
    return module !== undefined && ((module as any).questions !== undefined || (module as any).courseId !== undefined);
}

export type CharacterPosition = 'left' | 'right' | 'hidden';
export type CharacterOptions = 'DALE';
export type CharacterEmotion = 'idle' | 'smile' | 'angry' | 'stern';
export type LessonExtraType = 'VIDEO' | 'IMAGE';
export type LessonStepType = 'STATIC' | 'GUIDED_QUESTION' | 'QUESTION';

export interface LessonStep {
    _id: string;
    type: LessonStepType;
    firstCharacter: {
        character: string;
        emote: string;
        position: CharacterPosition
    };
    secondCharacter: {
        character: string;
        emote: string;
        position: CharacterPosition
    };
    textData: {
        displayText: string;
        voicedTextData: {
            text: string;
            character: string;
            key: string;
        }
    };
    extras?: {
        type: LessonExtraType;
        data: {
            url?: string;
        };
    };
    data?: any;
}

export const validateLessonStep = (step: LessonStep): boolean => {
    if(!step.textData.displayText) return false;
    if(!step.textData.voicedTextData.text) return false;

    return true;
}

export interface StaticLessonStep extends LessonStep {
    data?: any;
}

export const isStaticLessonStep = (lessonStep: LessonStep): lessonStep is StaticLessonStep => {
    return lessonStep.type === 'STATIC';
}

export interface LessonStepAnswerReply {
    beforeAnsweringVoicedText: string;
    answeredCorrectVoicedText: string;
    speechData: {
        beforeAnsweringVoicedText?: string;
        answeredCorrectVoicedText?: string;
    }
}

export interface GuidedQuestionLessonStep extends LessonStep {
    data: {
        question: Question;
        answerReplies: LessonStepAnswerReply[];
    };
}

export const isGuidedQuestionLessonStep = (lessonStep: LessonStep): lessonStep is GuidedQuestionLessonStep => {
    return lessonStep.type === 'GUIDED_QUESTION';
}

export interface QuestionLessonStep extends LessonStep {
    data: {
        question: Question;
        answerReplies: LessonStepAnswerReply[];
        answeredIncorrectVoicedText: string;
        speechData: {
            answeredIncorrectVoicedText: string;
        }
    };
}

export const isQuestionLessonStep = (lessonStep: LessonStep): lessonStep is QuestionLessonStep => {
    return lessonStep.type === 'QUESTION';
}



export interface Skill {
    _id: string;
    name: string;
    tag: string;
    description: string;
    color: string;
}



export type Module = BaseModule | Curriculum | Subject | Grade | Course | Lesson;