<div class="app-container" [ngClass]="themeClass">
    <router-outlet></router-outlet>
    <cl-modal [open]="isModalOpen" [allowBackgroundClose]="false" [removePadding]="true" [glass]="true">
        <div class="modal-content" *ngIf="isModalOpen">
            <h1> Compatibility Notice</h1>
            <p>
                Our app is only compatible with larger screens. Please access it from a desktop or tablet device.
            </p>
        </div>
    </cl-modal>
</div>
<toasts></toasts>