import { NgModule } from "@angular/core";
import { LessonProgressModule } from "src/components/lesson-progress/lesson-progress.module";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { QuestionMapperModule } from "src/components/question-mapper/question-mapper.module";
import { LessonPage } from "./lesson.component";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { IntroStepModule } from "./intro-steps/intro-steps.module";

@NgModule({
    declarations: [LessonPage],
    imports: [CosmittModalModule, QuestionMapperModule, LessonProgressModule, RouterModule, BrowserModule, LessonProgressModule, IntroStepModule],
    exports: [LessonPage]
})
export class LessonModule {};