import { NgModule } from "@angular/core";
import { CharacterRenderComponent } from "./character-render.component";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CharacterRenderComponent],
    imports: [BrowserModule, CommonModule],
    exports: [CharacterRenderComponent]
})
export class CharacterRenderModule {}  