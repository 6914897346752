import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { AdminCatalogLegacyPage } from "./admin-catalog-legacy.component";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { ModuleEditorModule } from "src/components/module-editor/module-editor.module";
import { QuestionEditorModule } from "src/components/question-editor/question-editor.module";
import { ConfirmModalModule } from "src/components/confirm-modal/confirm-modal.module";
import { CosmittSelectModule } from "src/components/c-select/c-select.module";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
    declarations: [AdminCatalogLegacyPage],
    imports: [
        BrowserModule,
        RouterModule, 
        DragDropModule,
        FormsModule, 
        ReactiveFormsModule,
        CosmittModalModule, 
        ModuleEditorModule, 
        QuestionEditorModule, 
        ConfirmModalModule, 
        CosmittSelectModule
    ],
    exports: [AdminCatalogLegacyPage]
})
export class AdminCatalogLegacyModule {}  