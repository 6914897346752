import { NgModule } from '@angular/core';
import { CurriculumModuleComponent } from './curriculum-module.component';
import { CommonModule } from '@angular/common';
import { CosmittPopoverModule } from 'src/components/c-popover/c-popover.module';
import { ClickOutsideModule } from 'src/directives/click-outside.module';
import { CosmittChipsModule } from 'src/components/c-chips/c-chips.module';

@NgModule({
  declarations: [CurriculumModuleComponent],
  imports: [CommonModule, CosmittPopoverModule, ClickOutsideModule, CosmittChipsModule],
  exports: [CurriculumModuleComponent],
})
export class CurriculumModuleModule {}
