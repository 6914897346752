import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittImageUploaderComponent } from "./c-image-uploader.component";
import { BrowserModule } from "@angular/platform-browser";
import { DragDropModule } from "@angular/cdk/drag-drop";

@NgModule({
    declarations: [CosmittImageUploaderComponent],
    imports: [BrowserModule, FormsModule, DragDropModule],
    exports: [CosmittImageUploaderComponent]
})
export class CosmittImageUploaderModule {}  