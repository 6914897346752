import { NgModule } from "@angular/core";
import { LessonProgressModule } from "src/components/lesson-progress/lesson-progress.module";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { QuestionMapperModule } from "src/components/question-mapper/question-mapper.module";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { AdminLessonPage } from "./lesson.component";
import { AdminQaSidebarModule } from "src/components/admin-qa-sidebar/admin-qa-sidebar.module";
import { QuestionEditorModule } from "src/components/question-editor/question-editor.module";
import { CosmittSelectModule } from "src/components/c-select/c-select.module";
import { CosmittTextareaModule } from "src/components/c-textarea/c-textarea.module";

@NgModule({
    declarations: [AdminLessonPage],
    imports: [CosmittModalModule, QuestionMapperModule, LessonProgressModule, RouterModule, BrowserModule, AdminQaSidebarModule, QuestionEditorModule, CosmittSelectModule, CosmittTextareaModule],
    exports: [AdminLessonPage]
})
export class AdminLessonModule {};