import { NgModule } from "@angular/core";
import { LoginPage } from "./login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CosmittCheckboxModule } from "src/components/c-checkbox/c-checkbox.module";
import { BrowserModule } from "@angular/platform-browser";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { TeacherCreateAccountComponent } from "./teacher-create-account/teacher-create-account.component";
import { LoginStepModule } from "./login-step/login-step.module";
import { ForgotPasswordStepModule } from "./forgot-password-step/forgot-password-step.module";
import { ResetPasswordStepModule } from "./reset-password-step/reset-password-step.module";
import { CreateAccountStepModule } from "./create-account-step/create-account-step.module";
import { PrivacyPolicyModule } from "./privacy-policy/privacy-policy.module";
import { TermsOfServiceModule } from "./terms-of-service/terms-of-service.module";

@NgModule({
    declarations: [LoginPage, TeacherCreateAccountComponent],
    imports: [
        BrowserModule, 
        RouterModule, 
        FormsModule, 
        ReactiveFormsModule, 
        CosmittCheckboxModule, 
        CosmittInputModule, 
        CosmittSpinnerModule,
        LoginStepModule,
        ForgotPasswordStepModule,
        ResetPasswordStepModule,
        CreateAccountStepModule,
        PrivacyPolicyModule,
        TermsOfServiceModule
    ],
    exports: [LoginPage]
})
export class LoginPageModule {}  