import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { Subject } from "rxjs";
import { SpeakableService } from "src/services/speakable.service";
import { LessonStep } from "src/types/modules";

@Component({
    selector: 'intro-steps',
    templateUrl: './intro-steps.component.html',
    styleUrls: ['./intro-steps.component.scss']
})
export class IntroStepComponent implements OnChanges{

    @Input() steps: LessonStep[];
    @Output() finished: EventEmitter<void> = new EventEmitter<void>();

    currentStep: LessonStep;
    currentStepIndex: number = 0;
    nextStepIndexCheck: number = 0;
    guidedQuestionAnswerCounter: number = 0;
    studentQuestionAnswerCounter: number = 0;

    speakingSubject: Subject<void> | null;
    paused: boolean = false;

    displayContinueText: boolean = false;
    fadeCharactersOut: boolean = false;

    guidedTrigger: EventEmitter<void> = new EventEmitter<void>();

    // Listen to click events on the host element
    @HostListener('click', ['$event'])
    onHostClick(event: MouseEvent) {
        if(this.paused) return;

        if(this.currentStep.type === 'QUESTION') return;

        if(this.currentStep.type === 'GUIDED_QUESTION') {
            this.guidedTrigger.emit();
            return;
        };

        this.goToNextStep();
    }

    constructor(private speakableService: SpeakableService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['steps'] && changes['steps'].currentValue) {
            this.currentStep = this.steps[0];
            this.currentStepIndex = 0;

            this.playStepSpeech();

            setTimeout(() => {
                if(this.currentStepIndex === 0) {
                    this.displayContinueText = true;
                }
            }, 5000);
        }
    }

    playStepSpeech(answerReplyIndex: number = 0) {
        switch (this.currentStep.type) {
            case 'STATIC':
                this.speakableService.playSpeakableByKey(this.currentStep.textData.voicedTextData.key, this.currentStep.textData.voicedTextData.text);
                break;
            case 'QUESTION':
                this.speakableService.playSpeakableByKey(this.currentStep.data.question.speechData?.instruction, this.currentStep.data.question.instruction);
                break;
            case 'GUIDED_QUESTION':
                this.speakableService.playSpeakableByKey(this.currentStep.data.answerReplies[answerReplyIndex].speechData.beforeAnsweringVoicedText, this.currentStep.data.answerReplies[answerReplyIndex].beforeAnsweringVoicedText);
                break;
        }
    }

    getDisplayText() {
        if(this.currentStep.type === 'QUESTION') {
            return this.currentStep.textData.displayText;
        } else if(this.currentStep.type === 'GUIDED_QUESTION') {
            return this.currentStep.data.answerReplies[0].beforeAnsweringVoicedText;
        } else {
            return this.currentStep.textData.displayText;
        }
    }

    goToNextStep() {
        this.currentStepIndex++;
        this.nextStepIndexCheck = this.currentStepIndex;
        this.displayContinueText = false;
        this.guidedQuestionAnswerCounter = 0;

        if(this.currentStepIndex >= this.steps.length) {
            this.fadeCharactersOut = true;

            setTimeout(() => {
                this.finished.emit();
            }, 1000);
            return;
        }

        this.currentStep = this.steps[this.currentStepIndex];
        this.speakableService.playSpeakableByKey(this.currentStep.textData.voicedTextData.key);
    }

    async answeredQuestionCorrectly() {
        switch (this.currentStep.type) {
            case 'QUESTION':
                this.currentStep.firstCharacter.emote = 'smile';
                this.paused = true;
                const questionSpeechResponse = await this.speakableService.playSpeakableByKey(this.currentStep.data.answerReplies[this.studentQuestionAnswerCounter].speechData.answeredCorrectVoicedText, this.currentStep.data.answerReplies[this.studentQuestionAnswerCounter].answeredCorrectVoicedText);

                this.studentQuestionAnswerCounter++;

                if(questionSpeechResponse !== undefined) {
                    this.speakingSubject = questionSpeechResponse;

                    questionSpeechResponse.subscribe(async () => {
                        if(this.studentQuestionAnswerCounter >= this.currentStep.data.answerReplies.length) {
                            this.paused = false;
                            return;
                        }

                        const playNextResponsePreSpeech = await this.speakableService.playSpeakableByKey(this.currentStep.data.answerReplies[this.studentQuestionAnswerCounter].speechData.beforeAnsweringVoicedText, this.currentStep.data.answerReplies[this.studentQuestionAnswerCounter].beforeAnsweringVoicedText);

                        if(playNextResponsePreSpeech !== undefined) {
                            this.speakingSubject = playNextResponsePreSpeech;

                            playNextResponsePreSpeech.subscribe(() => {
                                this.paused = false;
                            });
                        } else {
                            this.paused = false;
                        }
                    });
                } else {
                    this.paused = false;
                }

                break;
            case 'GUIDED_QUESTION':
                this.currentStep.firstCharacter.emote = 'idle';
                this.paused = true;
                const response = await this.speakableService.playSpeakableByKey(this.currentStep.data.answerReplies[this.guidedQuestionAnswerCounter].speechData.answeredCorrectVoicedText, this.currentStep.data.answerReplies[this.guidedQuestionAnswerCounter].answeredCorrectVoicedText);

                this.guidedQuestionAnswerCounter++;

                if(response !== undefined) {
                    this.speakingSubject = response;

                    response.subscribe(async () => {
                        if(this.guidedQuestionAnswerCounter >= this.currentStep.data.answerReplies.length) {
                            this.paused = false;
                            return;
                        }

                        const playNextResponsePreSpeech = await this.speakableService.playSpeakableByKey(this.currentStep.data.answerReplies[this.guidedQuestionAnswerCounter].speechData.beforeAnsweringVoicedText, this.currentStep.data.answerReplies[this.guidedQuestionAnswerCounter].beforeAnsweringVoicedText);

                        if(playNextResponsePreSpeech !== undefined) {
                            this.speakingSubject = playNextResponsePreSpeech;

                            playNextResponsePreSpeech.subscribe(() => {
                                this.paused = false;
                            });
                        } else {
                            this.paused = false;
                        }
                    });
                } else {
                    this.paused = false;
                }

                break;
        }
    }

    answeredQuestionIncorrectly() {
        switch (this.currentStep.type) {
            case 'QUESTION':
                this.currentStep.firstCharacter.emote = 'idle';
                break;
            case 'GUIDED_QUESTION':
                break;
        }
    }

    completedQuestion() {
        if(this.speakingSubject && !this.speakingSubject.closed && this.paused) {
            this.speakingSubject.subscribe(() => {
                this.goToNextStep();
            })
        } else {
            this.goToNextStep();
        }

        // setTimeout(() => {
        //     if(this.currentStepIndex === this.nextStepIndexCheck) {
        //         this.displayContinueText = true;
        //     }
        // }, 5000);
    }
}