<c-spinner *ngIf="loading"></c-spinner>
<ng-template #actionsMenu>
	<section class="actions-menu">
        <p class="caption" (click)="addPhonemeBefore()">Create before</p>
        <p class="caption" (click)="addPhonemeAfter()">Create after</p>
        <p class="caption" (click)="deletePhoneme()">Delete</p>
	</section>
</ng-template>

<cl-modal class="phoneme-selection-popup" [(open)]="phonemeEditOpen">
    <div class="content">
        <h4>Phonemes</h4>
        <div class="phonemes">
            <div *ngFor="let phoneme of getAllPhonemes()" (click)="selectNewPhoneme(phoneme)" class="phoneme-box">
                <h4>{{ phoneme.ipaSymbol }}</h4>
                <p class="caption disabled">{{ phoneme.graphemes }}</p>
            </div>
        </div>
    </div>
</cl-modal>

<c-input type="text" placeholder="List of spelling words..." label="Spelling list" actionText="Generate" [(cModel)]="spellingList" (actionE)="generateSpellingQuestion()"></c-input>

<div class="formatted-sentence" (click)="close()">
    <div *ngFor="let word of question.data.words" class="word-holder">
        <h4 class="word light">{{word.text}}</h4>
    </div>
    <div class="words">
        <div *ngFor="let word of question.data.words; let wordIndex = index" class="word-box">
            <img src="assets/icons/trash.svg" alt="Delete" class="delete-word-btn" (click)="deleteWord(wordIndex); $event.stopPropagation();">
            <span class="tooltip">Delete word</span>
            <h3>{{ word.word }}</h3>
            <p class="caption disabled">Phonemes</p>
            <div class="phoneme-list">
                <div *ngFor="let cPhoneme of word.correctPhonemes; let cpi = index" class="phoneme-box" (click)="selectCorrectPhoneme(wordIndex, cpi)" (contextmenu)="open($event, true, wordIndex, cpi); $event. preventDefault();">
                    <h4>{{ cPhoneme.phoneme }}</h4>
                    <p class="caption disabled">{{ getGraphemes(cPhoneme.phoneme) }}</p>
                </div>
            </div>
            <p class="caption disabled">False phonemes</p>
            <div class="phoneme-list">
                <div *ngFor="let fPhoneme of word.falsePhonemes; let fpi = index" class="phoneme-box" (click)="selectFalsePhoneme(wordIndex, fpi)" (contextmenu)="open($event, false, wordIndex, fpi); $event. preventDefault();">
                    <h4>{{ fPhoneme.phoneme }}</h4>
                    <p class="caption disabled">{{ getGraphemes(fPhoneme.phoneme) }}</p>
                </div>
            </div>
        </div>
    </div>
</div>