import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputComponent } from "./c-input.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [CosmittInputComponent],
    imports: [BrowserModule, FormsModule],
    exports: [CosmittInputComponent]
})
export class CosmittInputModule {}  