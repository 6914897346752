import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittTabsComponent } from "./c-tabs.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [CosmittTabsComponent],
    imports: [BrowserModule, FormsModule],
    exports: [CosmittTabsComponent]
})
export class CosmittTabsModule {}  