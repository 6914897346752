import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { BrowserModule } from "@angular/platform-browser";
import { CosmittTextEditorModule } from "src/components/c-text-editor/c-text-editor.module";
import { GroupingEditorComponent } from "./grouping-editor.component";

@NgModule({
    declarations: [GroupingEditorComponent],
    imports: [CosmittInputModule, FormsModule, BrowserModule, CosmittTextEditorModule],
    exports: [GroupingEditorComponent]
})
export class GroupingEditorModule {}  