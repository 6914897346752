<div class="container" [style.backgroundImage]="('url(' + (baseApiUrl + '/images/' + background | secureImage | async) + ')')"
  [style.height]="totemHeight * 1.791 + 'px'" [style.width]="totemHeight * 3.182 + 'px'">
  <div class="lesson-popout" [class.isActive]="isActive" [style.marginBottom]="totemHeight * 0.31 + 'px'">
    <div class="lesson-image">
      <div class="img"></div>
      <div class="rewards">
        <div class="experience">
          <h6>50</h6>
          <p class="caption">exp</p>
        </div>
        <div class="gold">
          <h6>50</h6>
          <p class="caption">gold</p>
        </div>
      </div>
    </div>

    <div class="content">
      <h4 [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(undefined, lesson.name)">{{ lesson.name }}</h4>
      <p [class.speakable]="isClickToSpeechActive()" (click)="playSpeakable(undefined, lesson.description)" [innerHTML]="lesson.description"></p>
    </div>

    <hr>

    <div class="footer">
      <div class="grade">
        <c-star color="BRONZE"></c-star>
        <h4>79%</h4>
      </div>

      <button [routerLink]="'/student/lesson/' + lesson._id">
        Start Lesson
      </button>
    </div>
  </div>

  <img (click)="onClick()" class="totem" [style.height]="totemHeight + 'px'"
    [style.marginBottom]="totemHeight * 0.167 + 'px'"
    [src]="baseApiUrl + '/images/student-assets%2Ftotem.png' | secureImage | async" />

  <div *ngIf="grade || grade < 70" class="stars">
    <img *ngIf="grade < 80 && grade >= 70" class="bronze star"
      [src]="baseApiUrl + '/images/student-assets%2Fbronze.png' | secureImage | async" />
    <div *ngIf="grade < 90 && grade >= 80" class="star-group">
      <img class="star edge" [src]="baseApiUrl + '/images/student-assets%2Fsilver.png' | secureImage | async" />
      <img class="star" [src]="baseApiUrl + '/images/student-assets%2Fsilver.png' | secureImage | async" />
    </div>
    <div *ngIf="grade >= 90" class="star-group">
      <img class="star edge" [src]="baseApiUrl + '/images/student-assets%2Fgold.png' | secureImage | async" />
      <img class="star" [src]="baseApiUrl + '/images/student-assets%2Fgold.png' | secureImage | async" />
      <img class="star edge" [src]="baseApiUrl + '/images/student-assets%2Fgold.png' | secureImage | async" />
    </div>
  </div>
</div>