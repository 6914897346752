import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { TeacherDashboardComponent } from "./dashboard.component";
import { CosmittTableModule } from "src/components/c-table/c-table.module";
import { CosmittPopoverModule } from "src/components/c-popover/c-popover.module";
import { ProgressionGraphModule } from "./progression-graph/progression-graph.module";
import { HighlightsModule } from "./highlights/highlights.module";

@NgModule({
    declarations: [TeacherDashboardComponent],
    imports: [
        BrowserModule, 
        CosmittModalModule, 
        CosmittInputModule, 
        CosmittSpinnerModule, 
        CosmittTableModule,
        CosmittPopoverModule,
        ProgressionGraphModule,
        HighlightsModule
    ],
    exports: [TeacherDashboardComponent]
})
export class TeacherDashboardModule { }  