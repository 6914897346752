import { NgModule } from "@angular/core";
import { GameMapperComponent } from "./question-mapper.component";
import { FillInTheBlankModule } from "../games/fill-in-the-blank/fill-in-the-blank.module";
import { GroupingModule } from "../games/grouping/grouping.module";
import { MultipleChoiceModule } from "../games/multiple-choice/multiple-choice.module";
import { RankingModule } from "../games/ranking/ranking.module";
import { SelectAndChangeModule } from "../games/select-and-change/select-and-change.module";
import { SelectTheWordModule } from "../games/select-the-word/select-the-word.module";
import { UnscrambleModule } from "../games/unscramble/unscramble.module";
import { FillInTheBlankEditorModule } from "../game-editors/fill-in-the-blank-editor/fill-in-the-blank-editor.module";
import { MultipleChoiceEditorModule } from "../game-editors/multiple-choice-editor/multiple-choice-editor.module";
import { RankingEditorModule } from "../game-editors/ranking-editor/ranking-editor.module";
import { SelectAndChangeEditorModule } from "../game-editors/select-and-change-editor/select-and-change-editor.module";
import { SelectTheWordEditorModule } from "../game-editors/select-the-word-editor/select-the-word-editor.module";
import { UnscrambleEditorModule } from "../game-editors/unscramble-editor/unscramble-editor.module";
import { GameDirective } from "src/directives/game.directive";
import { GroupingEditorModule } from "../game-editors/grouping-editor/grouping-editor.module";
import { SpellingEditorModule } from "../game-editors/spelling-editor/spelling-editor.module";
import { SpellingModule } from "../games/spelling/spelling.module";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [GameMapperComponent, GameDirective],
    imports: [
        BrowserModule,
        // Games
        FillInTheBlankModule, 
        GroupingModule, 
        MultipleChoiceModule, 
        RankingModule, 
        SelectAndChangeModule, 
        SelectTheWordModule, 
        UnscrambleModule,
        SpellingModule,
        // Editors
        FillInTheBlankEditorModule,
        GroupingEditorModule,
        MultipleChoiceEditorModule,
        RankingEditorModule,
        SelectAndChangeEditorModule,
        SelectTheWordEditorModule,
        UnscrambleEditorModule,
        RankingEditorModule,
        SpellingEditorModule
    ],
    exports: [GameMapperComponent]
})
export class QuestionMapperModule {}  