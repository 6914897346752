import { NgModule } from "@angular/core";
import { CosmittSelectModule } from "../c-select/c-select.module";
import { CosmittTextEditorModule } from "../c-text-editor/c-text-editor.module";
import { QuestionMapperModule } from "../question-mapper/question-mapper.module";
import { LessonStepEditorComponent } from "./lesson-step-editor.component";
import { CosmittInputModule } from "../c-input/c-input.module";
import { CosmittButtonGroupModule } from "../c-button-group/c-button-group.module";
import { CommonModule } from "@angular/common";
import { QuestionEditorModule } from "../question-editor/question-editor.module";
import { CosmittModalModule } from "../modal/modal.module";

@NgModule({
    declarations: [LessonStepEditorComponent],
    imports: [
        CosmittSelectModule, 
        CosmittInputModule,
        CosmittTextEditorModule, 
        QuestionMapperModule,
        CosmittButtonGroupModule,
        CommonModule,
        QuestionEditorModule,
        CosmittModalModule
    ],
    exports: [LessonStepEditorComponent]
})
export class LessonStepEditorModule {}
