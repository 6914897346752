import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { GroupingComponent } from "./grouping.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [GroupingComponent],
    imports: [FormsModule, BrowserModule],
    exports: [GroupingComponent]
})
export class GroupingModule {}  