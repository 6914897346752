import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from "@angular/core";
import { SpeakableService } from "src/services/speakable.service";
import { LessonStep } from "src/types/modules";

@Component({
    selector: 'intro-steps',
    templateUrl: './intro-steps.component.html',
    styleUrls: ['./intro-steps.component.scss']
})
export class IntroStepComponent implements OnChanges{

    @Input() steps: LessonStep[];
    @Output() finished: EventEmitter<void> = new EventEmitter<void>();

    currentStep: LessonStep;
    currentStepIndex: number = 0;
    nextStepIndexCheck: number = 0;

    displayContinueText: boolean = false;
    fadeCharactersOut: boolean = false;

    guidedTrigger: EventEmitter<void> = new EventEmitter<void>();

    // Listen to click events on the host element
    @HostListener('click', ['$event'])
    onHostClick(event: MouseEvent) {
        if(this.currentStep.type === 'QUESTION') return;

        if(this.currentStep.type === 'GUIDED_QUESTION') {
            this.guidedTrigger.emit();
            return;
        };

        this.goToNextStep();
    }

    constructor(private speakableService: SpeakableService) { }

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['steps'] && changes['steps'].currentValue) {
            this.currentStep = this.steps[0];
            this.currentStepIndex = 0;
            this.speakableService.playSpeakableByKey(this.currentStep.textData.voicedTextData.key);

            setTimeout(() => {
                if(this.currentStepIndex === 0) {
                    this.displayContinueText = true;
                }
            }, 5000);
        }
    }

    goToNextStep() {
        this.currentStepIndex++;
        this.nextStepIndexCheck = this.currentStepIndex;
        this.displayContinueText = false;

        if(this.currentStepIndex >= this.steps.length) {
            this.fadeCharactersOut = true;

            setTimeout(() => {
                this.finished.emit();
            }, 1000);
            return;
        }

        this.currentStep = this.steps[this.currentStepIndex];
        this.speakableService.playSpeakableByKey(this.currentStep.textData.voicedTextData.key);
    }

    answeredQuestionCorrectly() {
        this.currentStep.firstCharacter.emote = 'smile';
    }

    answeredQuestionIncorrectly() {
        this.currentStep.firstCharacter.emote = 'idle';
    }

    completedQuestion() {
        this.goToNextStep();

        // setTimeout(() => {
        //     if(this.currentStepIndex === this.nextStepIndexCheck) {
        //         this.displayContinueText = true;
        //     }
        // }, 5000);
    }
}