import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";


@Component({
    selector: 'search-input',
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {

    @Input() control: FormControl;
    @Input() searchFn: Function;
    @Input() searchResults: any[];
    @Input() selectResultFn: Function;

    @Input() label?: string = "";
    @Input() actionText?: string = "";
    @Output() actionE: EventEmitter<any> = new EventEmitter<any>();

    constructor() {

    }
}