import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
    selector: 'cl-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent {

    @Input() open!: boolean;
    @Output() openChange = new EventEmitter<boolean>();

    @Input() glass: boolean = false;
    @Input() allowBackgroundClose?: boolean = true;
    @Input() removePadding?: boolean = false;

    @Output() modalClosed: EventEmitter<void> = new EventEmitter<void>();

    constructor() { }

    selectBackground() {
        if(!this.allowBackgroundClose) return;
        this.open = false;
        this.openChange.emit(this.open);
        if(!this.open) this.modalClosed.emit();
    }
}