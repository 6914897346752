import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environment/environment';
import { Curriculum, CurriculumGrade, CurriculumLesson, CurriculumLevel, CurriculumSkill, CurriculumSubject } from 'src/types/modules';

@Injectable({
  providedIn: 'root',
})
export class CurriculumService {
  constructor(private httpClient: HttpClient) {}

  // Curriculum
  async createCurriculum(curriculum: Partial<Curriculum>): Promise<Curriculum> {
    const newCurriculumParams: Partial<Curriculum> = {
      name: curriculum.name,
      description: curriculum.description
    };

    const newCurriculum = (await lastValueFrom(
      this.httpClient.post(environment.apiBaseUrl + `/curriculum`, newCurriculumParams)
    )) as Curriculum;

    return newCurriculum;
  }

  async updateCurriculum(id: string, updates: Partial<Curriculum>): Promise<Curriculum> {
    const curriculumUpdates: Partial<Curriculum> = {
      name: updates.name,
      description: updates.description
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.patch(environment.apiBaseUrl + `/curriculum/${ id }`, curriculumUpdates)
    )) as Curriculum;
    
    return updatedCurriculum;
  }

  async deleteCurriculum(curriculumId: string): Promise<void> {
    await lastValueFrom(this.httpClient.delete(environment.apiBaseUrl + `/curriculum/${curriculumId}`));
  }



  // Subjects
  async createCurriculumSubject(curriculumId: string, subject: Partial<CurriculumSubject>) {
    const newCurriculumSubjectParams: Partial<Curriculum> = {
      name: subject.name,
      description: subject.description
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.post(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject`, newCurriculumSubjectParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async updateCurriculumSubject(curriculumId: string, subjectId: string, subjectUpdates: Partial<CurriculumSubject>) {
    const updatedCurriculumSubjectParams: Partial<Curriculum> = {
      name: subjectUpdates.name,
      description: subjectUpdates.description
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.patch(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }`, updatedCurriculumSubjectParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async deleteCurriculumSubject(curriculumId: string, subjectId: string) {
    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.delete(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }`)
    )) as Curriculum;

    return updatedCurriculum;
  }



  // Grades
  async createCurriculumGrade(curriculumId: string, subjectId: string, grade: Partial<CurriculumGrade>) {
    const newCurriculumGradeParams: Partial<CurriculumGrade> = {
      name: grade.name,
      description: grade.description
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.post(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade`, newCurriculumGradeParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async updateCurriculumGrade(curriculumId: string, subjectId: string, gradeId: string, subjectUpdates: Partial<CurriculumGrade>) {
    const updatedCurriculumGradeParams: Partial<Curriculum> = {
      name: subjectUpdates.name,
      description: subjectUpdates.description
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.patch(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }`, updatedCurriculumGradeParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async deleteCurriculumGrade(curriculumId: string, subjectId: string, gradeId: string) {
    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.delete(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }`)
    )) as Curriculum;

    return updatedCurriculum;
  }



  // Levels
  async createCurriculumLevel(curriculumId: string, subjectId: string, gradeId: string, level: Partial<CurriculumLevel>) {
    const newCurriculumLevelParams: Partial<CurriculumLevel> = {
      name: level.name,
      description: level.description
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.post(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/level`, newCurriculumLevelParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async updateCurriculumLevel(curriculumId: string, subjectId: string, gradeId: string, levelId: string, levelUpdates: Partial<CurriculumLevel>) {
    const updatedCurriculumLevelParams: Partial<CurriculumLevel> = {
      name: levelUpdates.name,
      description: levelUpdates.description,
      lessons: levelUpdates.lessons
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.patch(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/level/${ levelId }`, updatedCurriculumLevelParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async deleteCurriculumLevel(curriculumId: string, subjectId: string, gradeId: string, levelId: string) {
    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.delete(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/level/${ levelId }`)
    )) as Curriculum;

    return updatedCurriculum;
  }



  // Levels
  async createCurriculumLesson(curriculumId: string, subjectId: string, gradeId: string, levelId: string, lesson: Partial<CurriculumLesson>) {
    const newCurriculumLessonParams: Partial<CurriculumLesson> = {
      _id: lesson._id,
      name: lesson.name,
      description: lesson.description
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.post(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/level/${ levelId }/lesson`, newCurriculumLessonParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async updateCurriculumLesson(curriculumId: string, subjectId: string, gradeId: string, levelId: string, lessonId: string, lessonUpdates: Partial<CurriculumLesson>) {
    const updatedCurriculumLessonParams: Partial<CurriculumLesson> = {
      name: lessonUpdates.name,
      description: lessonUpdates.description,
      gradeSkillIds: lessonUpdates.gradeSkillIds
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.patch(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/level/${ levelId }/lesson/${ lessonId }`, updatedCurriculumLessonParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async deleteCurriculumLesson(curriculumId: string, subjectId: string, gradeId: string, levelId: string, lessonId: string) {
    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.delete(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/level/${ levelId }/lesson/${ lessonId }`)
    )) as Curriculum;

    return updatedCurriculum;
  }



  // Skills
  async createGradeSkill(curriculumId: string, subjectId: string, gradeId: string, skill: Partial<CurriculumSkill>) {
    const curriculumGradeSkillParams: Partial<CurriculumSkill> = {
      tag: skill.tag,
      name: skill.name,
      description: skill.description
    };

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.post(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/skill`, curriculumGradeSkillParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async updateGradeSkill(curriculumId: string, subjectId: string, gradeId: string, skillId: string, skill: Partial<CurriculumSkill>) {
    const curriculumGradeSkillParams: Partial<CurriculumSkill> = {
      tag: skill.tag,
      name: skill.name,
      description: skill.description
    };

    if(skill.baseSkillReferenceId) curriculumGradeSkillParams.baseSkillReferenceId = skill.baseSkillReferenceId;

    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.patch(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/skill/${ skillId }`, curriculumGradeSkillParams)
    )) as Curriculum;

    return updatedCurriculum;
  }

  async deleteGradeSkill(curriculumId: string, subjectId: string, gradeId: string, skillId: string) {
    const updatedCurriculum = (await lastValueFrom(
      this.httpClient.delete(environment.apiBaseUrl + `/curriculum/${ curriculumId }/subject/${ subjectId }/grade/${ gradeId }/skill/${ skillId }`))) as Curriculum;

    return updatedCurriculum;
  }
}
