import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { LessonsService } from "src/services/lessons.service";
import { ToastsService } from "src/services/toasts.service";
import { UsersService } from "src/services/users.service";
import { CharacterPosition, Lesson, LessonStep, LessonStepType, Module, StaticLessonStep, validateLessonStep } from "src/types/modules";
import { Question, QuestionType, validateQuestion, verifyQuestion } from "src/types/question";
import { User } from "src/types/users";
import { gameModeMapping, moduleMapping, ModuleType } from "../admin-catalog/ui-mappings";
import { ModulesService } from "src/services/modules.service";
import { CTab } from "src/components/c-tabs/c-tabs.component";


@Component({
    selector: 'admin-lesson-editor-page',
    templateUrl: './lesson-editor.component.html',
    styleUrls: ['./lesson-editor.component.scss']
})
export class AdminLessonEditorPage implements OnInit {

    user: User | null;

    lesson: Lesson;
    lessonId: string;
    parentId: string;
    searchQuestionsModel: string = '';
    searchControl: FormControl = new FormControl('');
    searchResults: Module[] = [];
    module: Module | null;
    moduleType: ModuleType = 'Lessons';
    lessonActionsPopoverVisible: boolean = false;
    popoverVisibleIndex: number = -1;
    loading: boolean = false;

    protected tabs: CTab[] = [
        { text: 'Overview' },
        { text: 'Questions' },
        { text: 'Intro steps' }
    ];
    currentTabIndex: number = 0;

    // Variables for editing and creating questions
    currentQuestion: Question;
    currentQuestionIndex: number = 0;
    creatingQuestion: boolean = false;
    questionEditModalOpen: boolean = false;
    questionSave$: EventEmitter<void> = new EventEmitter<void>();
    questionCloseBackground$: EventEmitter<void> = new EventEmitter<void>();

    // Variables for question generation
    questionsGenerationModalOpen: boolean = false;
    questionGenerationJSON: string = "";
    questionGenerationType: QuestionType = "selectTheWord";

    // Variables for editing and creating lesson steps
    currentLessonStep: LessonStep;
    currentLessonStepIndex: number = 0;
    creatingLessonStep: boolean = false;
    lessonStepEditModalOpen: boolean = false;
    lessonStepSave$: EventEmitter<void> = new EventEmitter<void>();
    lessonStepCloseBackground$: EventEmitter<void> = new EventEmitter<void>();

    gameModeOptions: { label: string, value: QuestionType }[] = [
        { label: 'Select the Word', value: 'selectTheWord' },
        { label: 'Select and Change', value: 'selectAndChange' },
        { label: 'Fill in the Blank', value: 'fillInTheBlank' },
        { label: 'Multiple Choice', value: 'multipleChoice' },
        { label: 'Grouping', value: 'grouping' },
        { label: 'Ranking', value: 'ranking' },
        { label: 'Unscramble', value: 'unscramble' },
        { label: 'Spelling', value: 'spelling' },
    ];

    constructor(
        private usersService: UsersService, 
        private lessonsService: LessonsService, 
        private toastsService: ToastsService, 
        private route: ActivatedRoute,
        private router: Router,
        private modulesService: ModulesService
    ) {
        
    }

    async ngOnInit() {
        // Get the user
        this.user = await this.usersService.getUser();

        if(!this.user) {
            // Get mad that we don't have the user
            console.error("No user was found.")
            return;
        }

        // Get lessonId from the route params
        this.route.params.subscribe(async params => {
            this.lessonId = params['lessonId'];
            this.lesson = await this.lessonsService.getSingleLesson(this.lessonId);
            this.parentId = this.lesson.courseId;
            this.searchControl.setValue(this.parentId);
        });
    }

    lessonActionsPopoverShow() {
        this.lessonActionsPopoverVisible = !this.lessonActionsPopoverVisible;
    }

    lessonActionsPopoverClickOutside() {
        this.lessonActionsPopoverVisible = false;
    }

    goToQaTesting(lesson: any) {
            const lessonId = lesson._id || lesson.id;
            this.router.navigate(['student', 'admin', 'lesson', lessonId, 'qa']);
    }

    onTabSelected(index: number) {
        this.currentTabIndex = index;
    }

    actionFocussedForPopover($event: any, index: any) {
        $event.stopPropagation();

        if(index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }

        this.popoverVisibleIndex = index;
    }

    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }
    
    openQuestionGenerationModal() {
        this.questionsGenerationModalOpen = true;
    }

    async generateQuestions() {
        // Verification of question JSON data
        if(!this.questionGenerationJSON) {
            this.toastsService.addToast({
                title: "Invalid format",
                description: "You must provide JSON data to generate questions."
            });
        }

        try {
            const parsedQuestions = JSON.parse(this.questionGenerationJSON);
            const updatedLesson = await this.lessonsService.addGeneratedQuestionsToLesson(this.lesson._id, this.questionGenerationType, parsedQuestions);
            this.lesson = updatedLesson;
            this.questionsGenerationModalOpen = false;
        } catch(error) {
            this.toastsService.addToast({
                title: "Invalid format",
                description: "You must provide valid JSON data to generate questions."
            });
        }
    }

    saveLesson() {
        try {
            this.lessonsService.updateLesson(this.lessonId, {
                name: this.lesson.name,
                description: this.lesson.description,
                courseId: this.lesson.courseId,
                questions: this.lesson.questions
            });
            this.toastsService.addToast({ title: 'Update Successful', description: 'Module has been successfully updated.' });    
        }catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({ title: 'Server error', description: message });
        }
    }

    searchQuestions() {

    }
    async assessDifficulties() {
        this.loading = true;
    
        try {
            try {
                const response = await this.lessonsService.assessQuestionsDifficulty(this.lesson._id);
                
                this.lesson = {
                    ...this.lesson,
                    questions: this.lesson.questions.map((q, i) => ({
                        ...q,
                        difficulty: response[i]
                    }))
                }
            } catch (error) {
                console.error("Error assessing difficulties:", error);
            }
            
            this.toastsService.addToast({ 
                title: 'Difficulties Assessed', 
                description: 'All question difficuly level has been updated.', 
                type: 'success' 
            });
        } catch (error) {
            this.toastsService.addToast({ 
                title: 'Error', 
                description: 'Failed to assess difficulties.', 
                type: 'error' 
            });
        } finally {
            this.loading = false;
        }
    }
    
    search() {
        if(this.module === null || this.moduleType === null) return;
        this.searchCourses();
    }

    selectCourseSearchResult(module: Module) {
        this.parentId = module._id;
        this.searchControl.setValue(this.parentId);
        this.searchResults = [];
    }

    async searchCourses() {
        const results = await this.modulesService.getCourses(undefined, undefined, this.searchControl.value);
        this.searchResults = results;
    }

    selectEditQuestion(index: number) {
        this.currentQuestionIndex = index;
        this.currentQuestion = this.lesson.questions[this.currentQuestionIndex];
        this.questionEditModalOpen = true;
    }

    openCreateQuestion() {
        this.creatingQuestion = true;
        this.lesson.questions.push({
            type: this.lesson.defaultType || 'selectTheWord',
            data: {
                instruction: this.lesson.defaultInstruction || ''
            }
        } as any);
        this.lesson.questions = [...this.lesson.questions]; // Doing this to get c-table to recognize changes
        this.currentQuestion = this.lesson.questions[this.lesson.questions.length - 1];
        this.currentQuestionIndex = this.lesson.questions.length - 1;
        this.questionEditModalOpen = true;
    }

    questionBackgroundClose() {
        this.questionEditModalOpen = false;

        if(this.creatingQuestion) {
            this.lesson.questions.splice(this.lesson.questions.length - 1, 1);
            this.lesson.questions = [...this.lesson.questions];
            this.creatingQuestion = false;
        }
        // Wait for animation to finish, then set currentQuestion to null
        setTimeout(() => {
            // this.resetAllValues();
        }, 500);
    };

    async deleteRightClickedQuestion() {
        if(this.popoverVisibleIndex === -1) return;
        const updatedLesson = await this.lessonsService.deleteQuestionByIndex(this.lesson._id, this.popoverVisibleIndex);
        this.lesson = updatedLesson;
    }

    async createQuestion() {
        try {
            // Will throw error if question is not valid
            verifyQuestion(this.currentQuestion, this.currentQuestion.type);
            
            const updatedLesson = await this.lessonsService.addQuestionToLesson(this.lessonId, this.currentQuestion);
            this.lesson.questions = updatedLesson.questions;

            this.questionEditModalOpen = false;
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Invalid Question',
                description: error.message
            })
        }
    }

    async saveQuestion() {
        // Check if we're creating and return out of this function if so.
        if(this.creatingQuestion) {
            this.createQuestion();
            return;
        }

        // Request the API to update the lessons question
        try {
            if(!this.currentQuestion) return;
            try {
                validateQuestion(this.currentQuestion);
            } catch(error: any) {
                this.toastsService.addToast({ title: 'Question Error', description: error.message, type: "error" });
                return;
            }

            if(this.currentQuestionIndex === null) return;

            if(this.currentQuestion.data.files) {
                let imageUrls: string[] = this.currentQuestion.data.imageUrls || [];
                for(let file of this.currentQuestion.data.files) {
                    // const response = await this.lessonsService.uploadLessonImage(this.lesson._id, file);
                    // imageUrls.push(response);
                }
                delete this.currentQuestion.data.files;
            }

            this.lesson.questions[this.currentQuestionIndex] = this.currentQuestion;
            await this.lessonsService.updateLesson(this.lesson._id, { questions: this.lesson.questions });
            this.questionEditModalOpen = false;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                // this.resetAllValues();
            }, 500);
            this.toastsService.addToast({ title: 'Question Updated', description: "Successfully updated question.", type: "success" });
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({ title: 'Server error', description: message });
        }
    }

    async deleteLesson() {
        // Do stuff
    }



    // Functions for Lesson Steps

    selectEditStep(index: number) {
        this.currentLessonStepIndex = index;
        this.currentLessonStep = this.lesson.introSteps[this.currentLessonStepIndex];
        this.lessonStepEditModalOpen = true;
    }

    openCreateStep() {
        this.creatingLessonStep = true;
        this.lesson.introSteps.push({
            type: 'STATIC',
            firstCharacter: {
              character: 'Dale',
              emote: 'idle',
              position: 'left'
            },
            secondCharacter: {
                character: '',
                emote: 'idle',
                position: 'left'
              },
            textData: {
                displayText: '',
                voicedTextData: {
                    text: '',
                    key: '',
                    character: 'Dale'
                }
            },
            data: {}
        } as StaticLessonStep);

        this.lesson.introSteps = [...this.lesson.introSteps]; // Doing this to get c-table to recognize changes
        this.currentLessonStep = this.lesson.introSteps[this.lesson.introSteps.length - 1];
        this.currentLessonStepIndex = this.lesson.introSteps.length - 1;
        this.lessonStepEditModalOpen = true;
    }

    stepBackgroundClose() {
        this.lessonStepEditModalOpen = false;

        if(this.creatingLessonStep) {
            this.lesson.introSteps.splice(this.lesson.introSteps.length - 1, 1);
            this.lesson.introSteps = [...this.lesson.introSteps];
            this.creatingLessonStep = false;
        }
        // Wait for animation to finish, then set currentQuestion to null
        setTimeout(() => {
            // this.resetAllValues();
        }, 500);
    };

    async createStep() {
        try {
            const updatedLesson = await this.lessonsService.addStepToLesson(this.lessonId, this.currentLessonStep);
            this.lesson.introSteps = updatedLesson.introSteps;

            this.lessonStepEditModalOpen = false;
        } catch(error: any) {
            this.toastsService.addToast({
                type: 'error',
                title: 'Server error',
                description: error.message
            });
        }
    }

    async saveStep() {
        // Check if we're creating and return out of this function if so.
        if(this.creatingLessonStep) {
            this.createStep();
            return;
        }

        // Request the API to update the lessons question
        try {
            if(!this.currentLessonStep) return;
            try {
                validateLessonStep(this.currentLessonStep);
            } catch(error: any) {
                this.toastsService.addToast({ title: 'Step Validation Error', description: error.message, type: "error" });
                return;
            }

            if(this.currentLessonStepIndex === null) return;
            this.lesson.introSteps[this.currentLessonStepIndex] = this.currentLessonStep;
            await this.lessonsService.updateLesson(this.lesson._id, { introSteps: this.lesson.introSteps });
            this.lessonStepEditModalOpen = false;
            // Wait for animation to finish, then set currentLessonStep to null
            setTimeout(() => {
                // this.resetAllValues();
            }, 500);
            this.toastsService.addToast({ title: 'Lesson Step Updated', description: "Successfully updated lesson step.", type: "success" });
        } catch(error: any) {
            let message: string = "";
            if(typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({ title: 'Server error', description: message });
        }
    }



    // Get colors and texts for UI

    getGameModeField(question: Question, field: string) {
        const mapping = gameModeMapping[question.type];
        if(!mapping) return gameModeMapping['unknown'][field];
        return mapping[field];
    }
}