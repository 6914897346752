<div class="content">
  <div class="header">
    <h3>Admin catalog explorer</h3>
  </div>

  <div class="body">
    <c-tabs [tabs]="tabs" (tabSelected)="onTabSelected($event)">
    </c-tabs>

    <div class="body-content">
      <curriculum-editor 
        *ngIf="curriculumEditorOpen"
        [(curriculum)]="currentCurriculum">
      </curriculum-editor>

      <modules-tab *ngIf="!curriculumEditorOpen && currentTabIndex === 0"></modules-tab>

      <curriculum-tab 
        *ngIf="!curriculumEditorOpen && currentTabIndex === 1"
        (rowSelected)="curriculumRowSelected($event)">
      </curriculum-tab>

      <skills-tab *ngIf="!curriculumEditorOpen && currentTabIndex === 2"></skills-tab>
    </div>
  </div>
</div>


<!-- <div
  tooltip
  tooltipText="A texas state standard skill for evaluating a students ability at identifying the phonetical sounds 'au' and 'ou'"
>
  Hi
</div> -->

<!-- Templates for popups -->
