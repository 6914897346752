import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { CosmittCheckboxModule } from "src/components/c-checkbox/c-checkbox.module";
import { BrowserModule } from "@angular/platform-browser";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { ResetPasswordStepComponent } from "./reset-password-step.component";

@NgModule({
    declarations: [ResetPasswordStepComponent],
    imports: [BrowserModule, RouterModule, FormsModule, CosmittCheckboxModule, CosmittInputModule, CosmittSpinnerModule],
    exports: [ResetPasswordStepComponent]
})
export class ResetPasswordStepModule {}  