import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { ModuleType, moduleMapping } from "src/pages/admin/admin-catalog/ui-mappings";
import { ModulesService } from "src/services/modules.service";
import { GradeLevel, Module, isCategory, isCourse, isCurriculum, isGrade, isLesson, isSubject } from "src/types/modules";


@Component({
    selector: 'module-editor',
    templateUrl: './module-editor.component.html',
    styleUrls: ['./module-editor.component.scss']
})
export class ModuleEditorComponent {

    _parentId: any;
    _name: string;
    _description: string;
    _gradeLevel: GradeLevel | null;

    @Input() module!: Module | null;
    @Input() moduleType!: ModuleType | null;

    @Input() 
    set name(value: string) {
        this._name = value;
        this.nameChange.emit(value);
    }
    get name() {
        return this._name;
    }
    @Output() nameChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() 
    set description(value: string) {
        this._description = value;
        this.descriptionChange.emit(value);
    }
    get description() {
        return this._description;
    }
    @Output() descriptionChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() creating: boolean = false;

    @Input() 
    set gradeLevel(value: GradeLevel | null) {
        this._gradeLevel = value;
        this.gradeLevelChange.emit(value);
    }
    get gradeLevel() {
        return this._gradeLevel;
    }
    @Output() gradeLevelChange: EventEmitter<GradeLevel | null> = new EventEmitter<GradeLevel | null>();

    @Input() 
    set parentId(value: string) {
        if(value === '') this.searchResults = [];
        this._parentId = value;
        this.parentIdChange.emit(value);
        this.searchControl.setValue(value);
    }
    get parentId() {
        return this._parentId;
    }
    @Output() parentIdChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() save$!: EventEmitter<void>;
    @Input() closeBackground$!: EventEmitter<void>;

    @Output() gotoParent: EventEmitter<string> = new EventEmitter<string>();

    searchControl: FormControl = new FormControl('');
    searchResults: Module[] = [];

    gradeLevelOptions: GradeLevel[] = ['PK', 'K', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12'];

    constructor(private modulesService: ModulesService) { }

    save() {
        this.save$.emit();
    }

    discard() {
        this.closeBackground$.emit();
    }

    search() {
        if(this.module === null || this.moduleType === null) return;
        switch(this.moduleType) {
            case 'Lessons':
                this.searchCourses();
                break;
            case 'Courses':
                this.searchCategories();
                break;
            case 'Categories':
                this.searchGrades();
                break;
            case 'Grades':
                this.searchSubjects();
                break;
            case 'Subjects':
                this.searchCurriculum();
                break;
        }
    }

    select(module: Module) {
        this.parentId = module._id;
        this.searchResults = [];
    }

    getType() {
        if(this.module === null || this.moduleType === null) return 'Unknown';
        return moduleMapping[this.moduleType].moduleTypeSingular;
    }

    getParentType() {
        if(this.module === null || this.moduleType === null || this.moduleType === 'Curriculum') return 'Unknown';
        return moduleMapping[this.moduleType].parentModuleTypeSingular;
    }

    nameModelChange(event: any) {
        this.name = event;
        this.nameChange.emit(event);
    }

    descriptionModelChange(event: any) {
        this.description = event;
        this.descriptionChange.emit(event);
    }

    async searchCourses() {
        const results = await this.modulesService.getCourses(undefined, undefined, this.searchControl.value);
        this.searchResults = results;
    }

    async searchCategories() {
        const results = await this.modulesService.getCategories(undefined, undefined, this.searchControl.value);
        this.searchResults = results;
    }

    async searchGrades() {
        const results = await this.modulesService.getGrades(undefined, undefined, this.searchControl.value);
        this.searchResults = results;
    }

    async searchSubjects() {
        const results = await this.modulesService.getSubjects(undefined, undefined, this.searchControl.value);
        this.searchResults = results;
    }

    async searchCurriculum() {
        const results = await this.modulesService.getCurriculum(undefined, undefined, this.searchControl.value);
        this.searchResults = results;
    }
}