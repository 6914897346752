import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { UpgradePopupComponent } from "./upgrade-popup.component";
import { CosmittModalModule } from "../modal/modal.module";
import { CRadioBoxModule } from "../c-radio-box/c-radio-box.module";

@NgModule({
    declarations: [UpgradePopupComponent],
    imports: [RouterModule, BrowserModule, CosmittModalModule, CRadioBoxModule],
    exports: [UpgradePopupComponent]
})
export class UpgradePopupModule {}  