import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { BrowserModule } from "@angular/platform-browser";
import { SpellingEditorComponent } from "./spelling-editor.component";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { OverlayModule } from "@angular/cdk/overlay";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";

@NgModule({
    declarations: [SpellingEditorComponent],
    imports: [CosmittInputModule, FormsModule, BrowserModule, OverlayModule, CosmittModalModule, CosmittSpinnerModule],
    exports: [SpellingEditorComponent]
})
export class SpellingEditorModule {}  