import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MultipleChoiceComponent } from "./multiple-choice.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [MultipleChoiceComponent],
    imports: [FormsModule, BrowserModule],
    exports: [MultipleChoiceComponent]
})
export class MultipleChoiceModule {}  