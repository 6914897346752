import { NgModule } from "@angular/core";
import { CourseCardModule } from "src/components/course-card/course-card.module";
import { TeacherDashboardComponent } from "./dashboard.component";
import { BrowserModule } from "@angular/platform-browser";
import { UpgradePopupModule } from "src/components/upgrade-popup/upgrade-popup.module";
import { CharacterChatModule } from "src/components/character-chat/character-chat.module";
import { CharacterCustomizerModule } from "src/components/character-customizer/character-customizer.module";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { CharacterRenderModule } from "src/components/character-render/character-render.module";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { CharacterIconRenderModule } from "src/components/character-icon-render/character-icon-render.module";

@NgModule({
    declarations: [TeacherDashboardComponent],
    imports: [CourseCardModule, BrowserModule, UpgradePopupModule, CharacterChatModule, CharacterCustomizerModule, CosmittModalModule, CharacterRenderModule, CosmittModalModule, CosmittInputModule, CosmittSpinnerModule, CharacterIconRenderModule],
    exports: [TeacherDashboardComponent]
})
export class TeacherDashboardModule {}  