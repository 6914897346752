import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    ViewChildren
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CChip } from 'src/components/c-chips/c-chips.component';
import { CurriculumService } from 'src/services/curriculum.service';
import { LessonsService } from 'src/services/lessons.service';
import { ModulesService } from 'src/services/modules.service';
import { SkillsService } from 'src/services/skills.service';
import { CosmittSkill, Curriculum, CurriculumGrade, CurriculumLesson, CurriculumLevel, CurriculumSkill, CurriculumSubject, Lesson } from 'src/types/modules';
import { RankingContent } from 'src/types/question';


@Component({
    selector: 'curriculum-editor',
    templateUrl: './curriculum-editor.component.html',
    styleUrls: ['./curriculum-editor.component.scss']
})
export class CurriculumEditorComponent implements OnInit, AfterViewInit, OnDestroy {

    expanded: { [key: string]: boolean } = {};
    fullSkills: { [skillId: string]: CosmittSkill } = {};

    @Input() curriculum: Curriculum;
    @Output() curriculumChange: EventEmitter<Curriculum> = new EventEmitter<Curriculum>();

    @ViewChildren('dropElement') dropElements!: QueryList<ElementRef>;

    confirmModalOpen: boolean = false;
    confirmModalMessage: string = 'Are you sure you want to delete this lesson?';
    confirmModalConfirmBtnText: string = 'Delete';
    confirmModalDeclineBtnText: string = 'Cancel';
    confirmModalConfirmFn: Function = () => { console.log('confirmModalConfirmFn has not been set yet.') };
    confirmModalDeclineFn: Function = () => { console.log('confirmModalDeclineFn has not been set yet.') };

    currentSubject: CurriculumSubject;
    currentSubjectIndex: number;
    currentGrade: CurriculumGrade;
    currentGradeIndex: number;
    currentLevel: CurriculumLevel;
    currentLevelIndex: number;
    currentSkill: CurriculumSkill;
    currentSkillIndex: number;

    private wasDragged = false;
    private dragStartPosition: { x: number, y: number } | null = null;
    private globalMouseUpListener: (() => void) | undefined;
    private skillDragElement: any;

    moduleEditModalOpen: boolean = false;
    moduleEditIsSkill: boolean = false;
    moduleTagModel: string = '';
    moduleNameModel: string = '';
    moduleDescriptionModel: string = '';
    moduleSaveFn: Function = () => { console.warn('moduleSaveFn has not been set yet.') };

    // Variables for lesson search
    lessonSearchModalOpen: boolean = false;
    lessonSearchModel: FormControl<string> = new FormControl();
    lessonSearchResults: Lesson[] = [];
    selectedLesson: Lesson | null;

    // Variables for skill search
    baseSkillSearchModalOpen: boolean = false;
    baseSkillSearchModel: FormControl<string> = new FormControl();
    baseSkillSearchResults: CosmittSkill[] = [];

    constructor(private modulesService: ModulesService, private curriculumService: CurriculumService, private skillsService: SkillsService, private lessonsService: LessonsService, private router: Router, private renderer: Renderer2) {

    }

    async ngOnInit(): Promise<void> {
        const baseSkillIds: Set<string> = new Set();
        
        this.curriculum.subjects.forEach((subject) => {
            subject.grades.forEach((grade) => {
                grade.levels.forEach((level) => {
                    level.lessons.forEach(async (lesson) => {
                        lesson.gradeSkillIds.forEach((id: string) => { baseSkillIds.add(id) });
                    });
                });
            });
        });

        const fullSkills = await this.skillsService.getSkillsByIdList(Array.from(baseSkillIds));
        fullSkills.forEach((skill) => { this.fullSkills[skill._id] = skill });
    }

    ngAfterViewInit() {
        this.globalMouseUpListener = this.renderer.listen('window', 'mouseup', (event: MouseEvent) => {
            this.skillDragEnd(event);
        });
    }

    ngOnDestroy() {
        if (this.globalMouseUpListener) {
            this.globalMouseUpListener();
        }
    }

    toggleExpanded(id: string) {
        this.expanded[id] = !this.expanded[id];
    }

    getChipsFromSkills(skillIds: string[]): CChip[] {
        const chips: CChip[] = skillIds.map((id: string) => {
            const fullSkill = this.fullSkills[id];
            return {
                text: fullSkill.tag
            } as CChip;
        });

        return chips;
    }

    async searchBaseSkills() {
        const query = this.baseSkillSearchModel.value;
        this.baseSkillSearchResults = await this.skillsService.getSkills(undefined, undefined, query);
    }

    selectSkillResult(baseSkill: CosmittSkill) {
        this.baseSkillSearchModel.setValue(baseSkill._id);
        this.baseSkillSearchResults = [];
    }

    // Functions for skills
    selectCreateSkill() {
        this.moduleNameModel = '';
        this.moduleDescriptionModel = '';
        this.moduleEditIsSkill = true;
        this.moduleEditModalOpen = true;
        this.moduleSaveFn = this.createSkill;
    }

    async createSkill() {
        const updatedCurriculum = await this.curriculumService.createGradeSkill(this.curriculum._id, this.currentSubject._id, this.currentGrade._id, {
            tag: this.moduleTagModel,
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel
        });

        this.curriculum = updatedCurriculum;
        this.currentGrade = updatedCurriculum.subjects[this.currentSubjectIndex].grades[this.currentGradeIndex];
        this.moduleEditModalOpen = false;
    }

    selectEditSkill(skill: CurriculumSkill) {
        if (!this.wasDragged) {
            this.currentSkill = skill;
            this.moduleTagModel = skill.tag;
            this.moduleNameModel = skill.name;
            this.moduleDescriptionModel = skill.description;
            this.baseSkillSearchModel.setValue(skill.baseSkillReferenceId);
            this.moduleEditIsSkill = true;
            this.moduleEditModalOpen = true;
            this.moduleSaveFn = this.updateSkill;
        } else {
            this.wasDragged = false;
        }
    }

    async updateSkill() {
        const updatedCurriculum = await this.curriculumService.updateGradeSkill(this.curriculum._id, this.currentSubject._id, this.currentGrade._id, this.currentSkill._id, {
            tag: this.moduleTagModel,
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel,
            baseSkillReferenceId: this.baseSkillSearchModel.value
        });

        this.curriculum = updatedCurriculum;
        this.currentGrade = updatedCurriculum.subjects[this.currentSubjectIndex].grades[this.currentGradeIndex];
        this.moduleEditModalOpen = false;
    }

    selectDeleteSkill(skill: CurriculumSkill) {
        this.currentSkill = skill;
        this.confirmModalOpen = true;
        this.confirmModalMessage = 'Are you sure you want to delete this skill? (This action cannot be undone)';
        this.confirmModalConfirmFn = this.deleteSkill;
    }

    async deleteSkill() {
        const updatedCurriculum = await this.curriculumService.deleteGradeSkill(this.curriculum._id, this.currentSubject._id, this.currentGrade._id, this.currentSkill._id);

        this.curriculum = updatedCurriculum;
        this.currentGrade = updatedCurriculum.subjects[this.currentSubjectIndex].grades[this.currentGradeIndex];
        this.confirmModalOpen = false;
    }

    isMouseOverDropTarget(event: MouseEvent, dropTarget: HTMLElement): boolean {
        const rect = dropTarget.getBoundingClientRect();

        return (
            event.clientX >= rect.left &&
            event.clientX <= rect.right &&
            event.clientY >= rect.top &&
            event.clientY <= rect.bottom
        );
    }

    dropRankingContent(event: CdkDragDrop<CurriculumLesson[]>, content: any, subjectId: string, gradeId: string, level: CurriculumLevel) {
        moveItemInArray(content, event.previousIndex, event.currentIndex);
        this.curriculumService.updateCurriculumLevel(this.curriculum._id, subjectId, gradeId, level._id, {
            ...level
        });
    }

    skillDragStart(event: MouseEvent, skillElement: any, skill: CurriculumSkill) {
        this.currentSkill = skill;
        this.skillDragElement = skillElement;
        this.wasDragged = false;
        this.dragStartPosition = { x: event.clientX, y: event.clientY };
    }

    skillDragEnd(event: MouseEvent) {
        // Determine the final position
        const dragEndPosition = { x: event.clientX, y: event.clientY };

        // Check if the element was dragged
        if (this.dragStartPosition && (this.dragStartPosition.x !== dragEndPosition.x || this.dragStartPosition.y !== dragEndPosition.y)) {
            this.wasDragged = true;

            this.dropElements.forEach((dropElement) => {
                const dropTarget = dropElement.nativeElement;
                if (this.isMouseOverDropTarget(event, dropTarget)) {
                    this.skillDropOnLesson(this.currentSubjectIndex, this.currentGradeIndex, dropTarget.getAttribute('level-index'), dropTarget.getAttribute('lesson-index'));
                }
            });
        }

        if(this.skillDragElement) this.skillDragElement.reset();
    }

    skillDropOnLesson(subjectIndex: number, gradeIndex: number, levelIndex: number, lessonIndex: number) {
        const gradeSkillIds = new Set(this.curriculum.subjects[subjectIndex].grades[gradeIndex].levels[levelIndex].lessons[lessonIndex].gradeSkillIds);
        gradeSkillIds.add(this.currentSkill._id);

        this.curriculumService.updateCurriculumLesson(
            this.curriculum._id,
            this.curriculum.subjects[subjectIndex]._id,
            this.curriculum.subjects[subjectIndex].grades[gradeIndex]._id,
            this.curriculum.subjects[subjectIndex].grades[gradeIndex].levels[levelIndex]._id,
            this.curriculum.subjects[subjectIndex].grades[gradeIndex].levels[levelIndex].lessons[lessonIndex]._id,
            {
                ...this.curriculum.subjects[subjectIndex].grades[gradeIndex].levels[levelIndex].lessons[lessonIndex],
                gradeSkillIds: Array.from(gradeSkillIds)
            }
        );
    }



    // Functions for lesson search
    async searchLessons() {
        const query = this.lessonSearchModel.value;
        const result = await this.lessonsService.getLessons(undefined, undefined, query);
        this.lessonSearchResults = [...result];
    }

    selectLessonsResult(lesson: Lesson) {
        this.lessonSearchModel.setValue(lesson._id);
        this.selectedLesson = lesson;
        this.lessonSearchResults = [];
    }

    discardAddLesson() {
        this.lessonSearchModalOpen = false;
        this.selectedLesson = null;
    }

    async addLessonToLevel() {
        if(!this.selectedLesson) return;

        const updatedCurriculum = await this.curriculumService.updateCurriculumLevel(this.curriculum._id, this.currentSubject._id, this.currentGrade._id, this.currentLevel._id, {
            lessons: [...this.currentLevel.lessons, {
                _id: this.selectedLesson._id,
                name: this.selectedLesson.name,
                description: this.selectedLesson.description,
                gradeSkillIds: []
            } as any]
        });

        this.curriculum = updatedCurriculum;
        this.lessonSearchModalOpen = false;
    }

    // Functions for action buttons in modal
    discard() {
        this.moduleEditModalOpen = false;
        setTimeout(() => {
            this.moduleNameModel = '';
            this.moduleDescriptionModel = '';
        }, 500);
    }

    // Functions for selection
    selectCurriculum() {
        this.moduleNameModel = this.curriculum.name;
        this.moduleDescriptionModel = this.curriculum.description;
        this.moduleEditIsSkill = false;
        this.moduleEditModalOpen = true;
        this.moduleSaveFn = this.saveCurriculum;
    }

    selectSubject(subject: CurriculumSubject, index: number) {
        this.currentSubject = subject;
        this.currentSubjectIndex = index;
        this.moduleNameModel = subject.name;
        this.moduleDescriptionModel = subject.description;
        this.moduleEditIsSkill = false;
        this.moduleEditModalOpen = true;
        this.moduleSaveFn = this.saveSubject;
    }

    selectGrade(subject: CurriculumSubject, grade: CurriculumGrade, subjectIndex: number, gradeIndex: number) {
        if(this.currentGrade && this.currentGrade._id === grade._id) {
            this.currentSubject = subject;
            this.currentSubjectIndex = subjectIndex;
            this.currentGrade = grade;
            this.currentGradeIndex = gradeIndex;
            this.moduleNameModel = grade.name;
            this.moduleDescriptionModel = grade.description;
            this.moduleEditIsSkill = false;
            this.moduleEditModalOpen = true;
            this.moduleSaveFn = this.saveGrade;
        } else {
            this.currentSubject = subject;
            this.currentSubjectIndex = subjectIndex;
            this.currentGrade = grade;
            this.currentGradeIndex = gradeIndex;
        }
    }

    selectLevel(subject: CurriculumSubject, grade: CurriculumGrade, level: CurriculumLevel, index: number) {
        this.currentSubject = subject;
        this.currentGrade = grade;
        this.currentLevel = level;
        this.moduleNameModel = level.name;
        this.moduleDescriptionModel = level.description;
        this.moduleEditIsSkill = false;
        this.moduleEditModalOpen = true;
        this.moduleSaveFn = this.saveLevel;
    }

    selectAddLesson(subjectIndex: number, gradeIndex: number, levelIndex: number) {
        this.currentSubject = this.curriculum.subjects[subjectIndex];
        this.currentSubjectIndex = subjectIndex;
        this.currentGrade = this.curriculum.subjects[subjectIndex].grades[gradeIndex];
        this.currentGradeIndex = gradeIndex;
        this.currentLevel = this.curriculum.subjects[subjectIndex].grades[gradeIndex].levels[levelIndex];
        this.currentLevelIndex = levelIndex;
        this.lessonSearchModalOpen = true;
    }

    selectLesson(lesson: CurriculumLesson, index: number) {
        this.confirmModalConfirmBtnText = 'Go to lesson';
        this.confirmModalDeclineBtnText = 'Nevermind';
        this.confirmModalMessage = 'Would you like to go to the lesson editor page?';

        this.confirmModalConfirmFn = () => {
            const url = this.router.serializeUrl(
                this.router.createUrlTree(['admin', 'lesson', lesson._id])
            );
            window.open(url, '_blank');
        };

        this.confirmModalDeclineFn = () => {
            this.confirmModalOpen = false;
        };

        this.confirmModalOpen = true;
    }

    selectCreateSubject() {
        this.moduleNameModel = '';
        this.moduleDescriptionModel = '';
        this.moduleEditIsSkill = false;
        this.moduleEditModalOpen = true;
        this.moduleSaveFn = this.createSubject;
    }

    selectCreateGrade(subjectIndex: number) {
        this.currentSubjectIndex = subjectIndex;
        this.currentSubject = this.curriculum.subjects[this.currentSubjectIndex];
        this.moduleNameModel = '';
        this.moduleDescriptionModel = '';
        this.moduleEditIsSkill = false;
        this.moduleEditModalOpen = true;
        this.moduleSaveFn = this.createGrade;
    }

    selectCreateLevel(subjectIndex: number, gradeIndex: number) {
        this.currentSubjectIndex = subjectIndex;
        this.currentSubject = this.curriculum.subjects[this.currentSubjectIndex];
        this.currentGradeIndex = gradeIndex;
        this.currentGrade = this.curriculum.subjects[this.currentSubjectIndex].grades[this.currentGradeIndex];
        this.moduleNameModel = '';
        this.moduleDescriptionModel = '';
        this.moduleEditIsSkill = false;
        this.moduleEditModalOpen = true;
        this.moduleSaveFn = this.createLevel;
    }

    // Functions for creation
    async createSubject() {
        const updatedCurriculum = await this.curriculumService.createCurriculumSubject(this.curriculum._id, {
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel
        });

        this.curriculum = updatedCurriculum;
        this.moduleEditModalOpen = false;
    }

    async createGrade() {
        const updatedCurriculum = await this.curriculumService.createCurriculumGrade(this.curriculum._id, this.currentSubject._id, {
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel
        });

        this.curriculum = updatedCurriculum;
        this.moduleEditModalOpen = false;
        this.expanded[this.curriculum.subjects[this.currentSubjectIndex]._id] = true;
    }

    async createLevel() {
        const updatedCurriculum = await this.curriculumService.createCurriculumLevel(this.curriculum._id, this.currentSubject._id, this.currentGrade._id, {
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel
        });

        this.curriculum = updatedCurriculum;
        this.moduleEditModalOpen = false;
        this.expanded[this.curriculum.subjects[this.currentSubjectIndex]._id] = true;
        this.expanded[this.curriculum.subjects[this.currentSubjectIndex].grades[this.currentGradeIndex]._id] = true;
    }

    // Functions for saving
    async saveCurriculum() {
        const updatedCurriculum = await this.modulesService.updateCurriculum(this.curriculum._id, {
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel
        });

        this.curriculum = updatedCurriculum;
        this.moduleEditModalOpen = false;
    }

    async saveSubject() {
        const updatedCurriculum = await this.curriculumService.updateCurriculumSubject(this.curriculum._id, this.currentSubject._id, {
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel
        });

        this.curriculum = updatedCurriculum;
        this.moduleEditModalOpen = false;
    }

    async saveGrade() {
        const updatedCurriculum = await this.curriculumService.updateCurriculumGrade(this.curriculum._id, this.currentSubject._id, this.currentGrade._id, {
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel
        });

        this.curriculum = updatedCurriculum;
        this.moduleEditModalOpen = false;
    }

    async saveLevel() {
        const updatedCurriculum = await this.curriculumService.updateCurriculumLevel(this.curriculum._id, this.currentSubject._id, this.currentGrade._id, this.currentLevel._id, {
            name: this.moduleNameModel,
            description: this.moduleDescriptionModel
        });

        this.curriculum = updatedCurriculum;
        this.moduleEditModalOpen = false;
    }



    // Functions for deleting
    selectDeleteSubject(subjectIndex: number) {
        this.confirmModalOpen = true;
        this.confirmModalMessage = 'Are you sure you want to delete this subject? (This action cannot be undone)';
        this.confirmModalConfirmFn = () => this.deleteSubject(subjectIndex);
    }

    async deleteSubject(subjectIndex: number) {
        const sub = this.curriculum.subjects[subjectIndex];
        const updatedCurriculum = await this.curriculumService.deleteCurriculumSubject(this.curriculum._id, sub._id);

        this.curriculum = updatedCurriculum;
        this.confirmModalOpen = false;
    }

    selectDeleteGrade(subjectIndex: number, gradeIndex: number) {
        this.confirmModalOpen = true;
        this.confirmModalMessage = 'Are you sure you want to delete this grade? (This action cannot be undone)';
        this.confirmModalConfirmFn = () => this.deleteGrade(subjectIndex, gradeIndex);
    }

    async deleteGrade(subjectIndex: number, gradeIndex: number) {
        const sub = this.curriculum.subjects[subjectIndex];
        const grade = sub.grades[gradeIndex];
        const updatedCurriculum = await this.curriculumService.deleteCurriculumGrade(this.curriculum._id, sub._id, grade._id);

        this.curriculum = updatedCurriculum;
        this.confirmModalOpen = false;
    }

    selectDeleteLevel(subjectIndex: number, gradeIndex: number, levelIndex: number) {
        this.confirmModalOpen = true;
        this.confirmModalMessage = 'Are you sure you want to delete this level? (This action cannot be undone)';
        this.confirmModalConfirmFn = () => this.deleteLevel(subjectIndex, gradeIndex, levelIndex);
    }

    async deleteLevel(subjectIndex: number, gradeIndex: number, levelIndex: number) {
        const sub = this.curriculum.subjects[subjectIndex];
        const grade = sub.grades[gradeIndex];
        const level = grade.levels[levelIndex];
        const updatedCurriculum = await this.curriculumService.deleteCurriculumLevel(this.curriculum._id, sub._id, grade._id, level._id);

        this.curriculum = updatedCurriculum;
        this.confirmModalOpen = false;
    }

    selectDeleteLesson(subjectIndex: number, gradeIndex: number, levelIndex: number, lessonIndex: number) {
        this.confirmModalOpen = true;
        this.confirmModalMessage = 'Are you sure you want to remove this lesson?';
        this.confirmModalConfirmFn = () => this.deleteLesson(subjectIndex, gradeIndex, levelIndex, lessonIndex);
    }

    async deleteLesson(subjectIndex: number, gradeIndex: number, levelIndex: number, lessonIndex: number) {
        const sub = this.curriculum.subjects[subjectIndex];
        const grade = sub.grades[gradeIndex];
        const level = grade.levels[levelIndex];
        const lesson = level.lessons[lessonIndex];
        const updatedCurriculum = await this.curriculumService.deleteCurriculumLesson(this.curriculum._id, sub._id, grade._id, level._id, lesson._id);

        this.curriculum = updatedCurriculum;
        this.confirmModalOpen = false;
    }
}
