import { NgModule } from "@angular/core";
import { CRadioBoxComponent } from "./c-radio-box.component";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [CRadioBoxComponent],
    imports: [BrowserModule, FormsModule],
    exports: [CRadioBoxComponent]
})
export class CRadioBoxModule {}  