import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { GAME_CONSTANTS } from "src/constants/game-modes";
import { GENERATING_SPEAKABLE_TEXT } from "src/constants/shared";
import { LessonsService } from "src/services/lessons.service";
import { ModulesService } from "src/services/modules.service";
import { SpeakableService } from "src/services/speakable.service";
import { StudentLessonsService } from "src/services/studentLessons.service";
import { ToastsService } from "src/services/toasts.service";
import { UsersService } from "src/services/users.service";
import { Lesson } from "src/types/modules";
import { Question } from "src/types/question";
import { StudentLesson, StudentLessonRecord } from "src/types/studentLesson";
import { User } from "src/types/users";

const GOLD_MESSAGES = [
    "You're doing great!",
    "You're on a roll!",
    "Keep up the good work!",
    "Hard work really does pay off!"
];

const SILVER_MESSAGES = [
    "So close to a perfect score!",
    "That was awesome!"
];

const BRONZE_MESSAGES = [
    "Not bad! I bet we can do even better though."
];

const NO_MEDAL_MESSAGES = [
    "Don't give up! Let's try again."
];

@Component({
    selector: 'lesson-page',
    templateUrl: './lesson.component.html',
    styleUrls: ['./lesson.component.scss']
})
export class LessonPage implements OnInit {

    user: User;
    studentLesson: StudentLesson | null;

    nextLessonId: string;

    lesson: Lesson;
    lessonId: string;
    currentQuestion: Question;
    currentRecord: StudentLessonRecord | null;
    currentQuestionIndex: number = 0;
    
    numQuestions: number = 20; // How many questions the user has to answer to finish the lesson. Default is 20.
    numQuestionsCompleted: number = 0; // How many questions the user has completed. Lesson ends when this equals numQuestions.
    correctAnswers: number = 0; // How many questions the student has answered correctly.
    incorrectAnswers: number = 0; // How many questions the student has answered incorrectly.
    progress: number = 0; // Percentage user has completed the lesson.

    questionsRandomized: boolean = false; // If true, grabs random question, else goes linearly
    questionsIndexAnswered: number[] = []; // An array of the index's of questions that have already been answered by the user

    introActive: boolean = true;
    introOpen: boolean = true;
    introStepsActive: boolean = false;
    introStartText: string = "Start lesson";

    outroActive: boolean = false;
    outroOpen: boolean = false;

    constructor(private usersService: UsersService, 
                private lessonsService: LessonsService, 
                private studentLessonsService: StudentLessonsService, 
                private modulesService: ModulesService, 
                private route: ActivatedRoute,
                private speakableService: SpeakableService,
                private toastsService: ToastsService) {
        this.usersService.setUserFocusMode(true);
    }

    async ngOnInit() {
        // Get the user
        this.user = await this.usersService.getUser() as User;

        // Get lessonId from the route params
        this.route.params.subscribe(async params => {
            if(this.lessonId !== undefined && params['lessonId'] !== this.lessonId) {
                this.resetPage();
            }
            this.lessonId = params['lessonId'];
            this.lesson = await this.lessonsService.getSingleLesson(this.lessonId);
            this.lessonsService.shuffle(this.lesson.questions);
            await this.getStudentLesson();

            if(this.studentLesson) {
                this.introStepsActive = false;
            } else if(this.lesson.introSteps?.length > 0) {
                this.introStepsActive = true;
            }
        });
    }

    startIntroSteps() {
        this.introActive = false;
        this.introStepsActive = true;
    }

    completeIntroSteps() {
        this.introActive = false;
        this.introStepsActive = false;
        this.startLesson();
    }

    getMedalUrl() {
        if(!this.studentLesson ) return "";
        if(this.studentLesson.highestGrade >= 90) return "../../assets/images/Gold Medal.png";
        if(this.studentLesson.highestGrade > 80) return "../../assets/images/Silver Medal.png";
        if(this.studentLesson.highestGrade > 70) return "../../assets/images/Bronze Medal.png";
        return "../../assets/images/Transparent Medal Whole.png";
    }

    resetPage() {
        this.numQuestionsCompleted = 0;
        this.correctAnswers = 0;
        this.incorrectAnswers = 0;
        this.currentQuestionIndex = 0;
        this.progress = 0;
        this.questionsIndexAnswered = [];
        this.outroActive = false;
        this.outroOpen = false;
        this.introActive = true;
        this.introOpen = true;
        this.studentLesson = null;
        this.currentRecord = null;
    }

    async getStudentLesson() {
        try {
            this.studentLesson = await this.studentLessonsService.getSingleStudentLesson(this.user._id, this.lessonId);
            if(this.studentLesson.records.length > 0 && !this.studentLesson.records[0].completed) {
                this.currentRecord = this.studentLesson.records[0];
                this.numQuestionsCompleted = this.currentRecord.data.questionsCompleted;
                this.correctAnswers = this.currentRecord.data.correctAnswers;
                this.incorrectAnswers = this.currentRecord.data.incorrectAnswers;
                this.introStartText = "Continue lesson";
                this.progress = this.currentRecord.data.questionsCompleted / this.numQuestions * 100;
            }
        } catch(error: any) {
            // No problem, usually just a 404 here.
        }
    }

    async startLesson(): Promise<void> {
        if(!this.user) return;

        if(!this.studentLesson) {
            this.studentLesson = await this.studentLessonsService.createStudentLesson(this.user._id, this.lessonId);
        }

        if(!this.currentRecord) {
            this.studentLesson = await this.studentLessonsService.createStudentLessonRecord(this.studentLesson._id);
            this.currentRecord = this.studentLesson.records[0];
        }

        this.introOpen = false;

        setTimeout(() => {
            this.introActive = false;
        }, 1000);

        this.currentQuestion = this.lesson.questions[0];
        this.numQuestions = GAME_CONSTANTS[this.currentQuestion.type].questionsPerLesson;
        this.progress = this.currentRecord.data.questionsCompleted / this.numQuestions * 100;
    }

    isClickToSpeechActive() {
        return this.speakableService.clickToSpeechActive;
    }

    playSpeakable(url: string | undefined, text?: string) {
        // Return if user isn't actively in text to speech mode
        if(!this.speakableService.clickToSpeechActive) return;
        this.speakableService.getAndPlaySpeakable(url, text);
    }

    getCompleteMessage() {
        const grade = this.calcGrade();
        if(grade >= 90) {
            const randomIndex: number = Math.floor(Math.random() * GOLD_MESSAGES.length);
            return GOLD_MESSAGES[randomIndex];
        }
        if(grade >= 80) {
            const randomIndex: number = Math.floor(Math.random() * SILVER_MESSAGES.length);
            return SILVER_MESSAGES[randomIndex];
        }
        if(grade >= 70) {
            const randomIndex: number = Math.floor(Math.random() * BRONZE_MESSAGES.length);
            return BRONZE_MESSAGES[randomIndex];
        }
        const randomIndex: number = Math.floor(Math.random() * NO_MEDAL_MESSAGES.length);
        return NO_MEDAL_MESSAGES[randomIndex];
    }

    getMedalMessage() {
        const grade = this.calcGrade();
        if(grade >= 90) return "Gold Medal";
        if(grade >= 80) return "Silver Medal";
        if(grade >= 70) return "Bronze Medal";
        return "No Medal";
    }

    getSubMedalMessage() {
        const grade = this.calcGrade();
        if(grade >= 90) return "You earned a gold medal on a lesson!";
        if(grade >= 80) return "You earned a silver medal on a lesson!";
        if(grade >= 70) return "You earned a bronze medal on a lesson!";
        return "You didn't earn a medal this time. Let's try again!";
    }

    getDisplayGrade() {
        return Math.round(this.calcGrade());
    }

    calcGrade() {
        if(this.correctAnswers + this.incorrectAnswers === 0) return 0;
        return this.correctAnswers / (this.correctAnswers + this.incorrectAnswers) * 100;
    }

    answeredCorrectly() {
        if(!this.studentLesson || !this.currentRecord) return;
        this.correctAnswers++;
        this.studentLessonsService.updateStudentLessonRecord(this.studentLesson._id, this.currentRecord._id, { 
            data: {
                correctAnswers: this.correctAnswers,
                incorrectAnswers: this.incorrectAnswers,
                questionsCompleted: this.numQuestionsCompleted
            }
        });
    }

    answeredIncorrectly() {
        if(!this.studentLesson || !this.currentRecord) return;
        this.incorrectAnswers++;
        this.studentLessonsService.updateStudentLessonRecord(this.studentLesson._id, this.currentRecord._id, { 
            data: {
                correctAnswers: this.correctAnswers,
                incorrectAnswers: this.incorrectAnswers,
                questionsCompleted: this.numQuestionsCompleted
            }
        });
    }

    async completedQuestion() {
        if(!this.studentLesson || !this.currentRecord) return;
        const percentageIncrease = 1 / this.numQuestions * 100;
        if(this.progress + percentageIncrease > 100) this.progress = 100;
        else this.progress += percentageIncrease;
        this.numQuestionsCompleted++;

        const grade = this.calcGrade();
        this.currentRecord.grade = grade;

        let recordUpdates: Partial<StudentLessonRecord> = {
            grade: grade,
            data: {
                correctAnswers: this.correctAnswers,
                incorrectAnswers: this.incorrectAnswers,
                questionsCompleted: this.numQuestionsCompleted
            }
        };

        // Feed the student another question if they haven't completed enough questions
        if(this.numQuestionsCompleted < this.numQuestions) {
            this.studentLessonsService.updateStudentLessonRecord(this.studentLesson._id, this.currentRecord._id, recordUpdates);
            this.nextQuestion();
            return;
        }

        recordUpdates.completed = true;
        recordUpdates.completedOn = new Date();

        this.studentLessonsService.updateStudentLessonRecord(this.studentLesson._id, this.currentRecord._id, recordUpdates);


        // TODO: Move all this functionality below to the API. Frontened really shouldn't be handling this and making this many calls.
        // Time to find the students next lesson
        this.outroActive = true;
        this.outroOpen = true;
        // const parentCourse = await this.modulesService.getSingleCourse(this.lesson.courseId);
        // const lessonIndex = parentCourse.lessons.findIndex((l) => l.id === this.lessonId);
        // if(lessonIndex === parentCourse.lessons.length - 1) {
        //     // Need to get the next course
        //     const parentCategory = await this.modulesService.getSingleCategory(parentCourse.categoryId);
        //     const courseIndex = parentCategory.courses.findIndex((c) => c.id === parentCourse._id);
        //     if(courseIndex === parentCategory.courses.length - 1) {
        //         // Need to get the next category
        //         const parentGrade = await this.modulesService.getSingleGrade(parentCategory.gradeId);
        //         const categoryIndex = parentGrade.categories.findIndex((c) => c.id === parentCategory._id);
        //         if(categoryIndex === parentGrade.categories.length - 1) {
        //             // Need to get the next grade
        //             const parentSubject = await this.modulesService.getSingleSubject(parentGrade.subjectId);
        //             const gradeIndex = parentGrade.categories.findIndex((c) => c.id === parentCategory._id);
        //             if(gradeIndex === parentSubject.grades.length - 1) return; // At the end of content, do something?
        //             this.user.curriculumInfo.gradeId = parentSubject.grades[gradeIndex + 1].id;
        //             const newGrade = await this.modulesService.getSingleGrade(this.user.curriculumInfo.gradeId);
        //             this.user.curriculumInfo.categoryId = newGrade.categories[0].id;
        //             const newCategory = await this.modulesService.getSingleCategory(this.user.curriculumInfo.categoryId);
        //             this.user.curriculumInfo.courseId = newCategory.courses[0].id;
        //             const newCourse = await this.modulesService.getSingleCourse(this.user.curriculumInfo.courseId);
        //             this.user.curriculumInfo.lessonId = newCourse.lessons[0].id;
        //             this.nextLessonId = this.user.curriculumInfo.lessonId;
        //             this.user = await this.usersService.updateStudentCurriculum(this.user._id, this.user.curriculumInfo);
        //             return;
        //         }
        //         this.user.curriculumInfo.categoryId = parentGrade.categories[categoryIndex + 1].id;
        //         const newCategory = await this.modulesService.getSingleCategory(this.user.curriculumInfo.categoryId);
        //         this.user.curriculumInfo.courseId = newCategory.courses[0].id;
        //         const newCourse = await this.modulesService.getSingleCourse(this.user.curriculumInfo.courseId);
        //         this.user.curriculumInfo.lessonId = newCourse.lessons[0].id;
        //         this.nextLessonId = this.user.curriculumInfo.lessonId;
        //         this.user = await this.usersService.updateStudentCurriculum(this.user._id, this.user.curriculumInfo);
        //         return;
        //     }
        //     this.user.curriculumInfo.courseId = parentCategory.courses[courseIndex + 1].id;
        //     const newCourse = await this.modulesService.getSingleCourse(this.user.curriculumInfo.courseId);
        //     this.user.curriculumInfo.lessonId = newCourse.lessons[0].id;
        //     this.nextLessonId = this.user.curriculumInfo.lessonId;
        //     this.user = await this.usersService.updateStudentCurriculum(this.user._id, this.user.curriculumInfo);
        //     return;
        // }
        // this.user.curriculumInfo.lessonId = parentCourse.lessons[lessonIndex + 1].id;
        // this.nextLessonId = this.user.curriculumInfo.lessonId;
        // this.user = await this.usersService.updateStudentCurriculum(this.user._id, this.user.curriculumInfo);
    }

    nextQuestion() {
        this.currentQuestionIndex++;
        this.currentQuestion = this.lesson.questions[this.currentQuestionIndex];
        this.numQuestions = GAME_CONSTANTS[this.currentQuestion.type].questionsPerLesson;
    }
}