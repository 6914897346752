import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    Injectable,
    SimpleChanges,
    Input
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TableHeader } from 'src/components/c-table/c-table.component';
import { CurriculumService } from 'src/services/curriculum.service';
import { ModulesService } from 'src/services/modules.service';
import { ToastsService } from 'src/services/toasts.service';
import {
    Curriculum,
    GradeLevel,
    isCategory,
    isCourse,
    isCurriculum,
    isGrade,
    isLesson,
    isSubject,
    Lesson,
    Module
} from 'src/types/modules';
import { getModuleType, moduleMapping, ModuleType, ModuleTypePlural } from '../ui-mappings';
import { LessonsService } from 'src/services/lessons.service';
import { CButtonGroupOption } from 'src/components/c-button-group/c-button-group.component';

type FilterOptions = '' | 'Floater';

@Component({
    selector: 'modules-tab',
    templateUrl: './modules-tab.component.html',
    styleUrls: ['./modules-tab.component.scss'],
})

@Injectable({
    providedIn: 'root'
})
export class ModulesTabComponent implements AfterViewInit {

    creating: boolean = false;
    editing: boolean = false;

    confirmModalOpen: boolean = false;
    confirmModalMessage: string = 'Are you sure you want to delete this curriculum? (This action cannot be undone)';
    confirmModalConfirmBtnText: string = 'Delete';
    confirmModalDeclineBtnText: string = 'Cancel';
    confirmModalConfirmFn: Function = () => { console.log('confirmModalConfirmFn has not been set yet.') };
    confirmModalDeclineFn: Function = () => { console.log('confirmModalDeclineFn has not been set yet.') };

    // Variables for module editing/creating
    editModalOpen: boolean = false;
    moduleSave$: EventEmitter<void> = new EventEmitter<void>();
    closeBackground$: EventEmitter<void> = new EventEmitter<void>();
    nameModel: string = '';
    descriptionModel: string = '';
    parentId: string;

    currentModule: any;
    currentModuleType: ModuleType | null = null;
    currentModuleIndex: number | null = null;

    gradeLevel: GradeLevel | null = null;

    // Variables for search area of tab content
    searchControl: FormControl = new FormControl('');
    searching: boolean = false;

    page: number = 0;
    pageLimit: number = 25;
    pageLimitOptions: number[] = [5, 10, 25, 50];

    // Variables for popover
    popoverVisibleIndex: number = -1;

    moduleTypeDropdownActive: boolean = false;
    moduleType: ModuleType = 'Lessons';
    moduleTypeOptions: ModuleType[] = [
        'Lessons',
        'Courses',
        'Categories',
        'Grades',
        'Subjects',
        'Curriculum',
    ];

    filter: FilterOptions = '';
    filterOptions: CButtonGroupOption[] = [{ label: 'All', value: '' }, { label: 'Floater', value: '' }];

    // Variables for controlling tabs, tab content, and table information
    @ViewChild('actionsTemplate') actionsTemplate: TemplateRef<any>;

    tableHeaders: TableHeader[] = [];
    tableData: any[] = [];

    @Output() rowSelected: EventEmitter<Curriculum> = new EventEmitter<Curriculum>();

    @Input() searchQuery: string;

    constructor(
        private lessonsService: LessonsService,
        private modulesService: ModulesService,
        private toastsService: ToastsService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.moduleSave$.subscribe(() => {
            this.saveModule();
        });

        this.closeBackground$.subscribe(() => {
            this.editModalOpen = false;
            this.creating = false;
        });

        // Check for stored module type in sessionStorage
        const cachedModuleType = sessionStorage.getItem('admin_currentModuleType');
        if (
            cachedModuleType &&
            this.moduleTypeOptions.findIndex((i) => cachedModuleType === i) === -1
        ) {
            this.moduleType = 'Lessons';
            sessionStorage.setItem('admin_currentModuleType', 'Lessons');
        } else if (cachedModuleType)
            this.moduleType = cachedModuleType as ModuleType;

        // Set mappings for search functions
        moduleMapping['Lessons'].searchFunction =
            this.lessonsService.getLessons.bind(this.lessonsService);

        // Set mappings for create functions
        moduleMapping['Lessons'].createFunction =
            this.lessonsService.createLesson.bind(this.lessonsService);

        // Set mappings for update functions
        moduleMapping['Lessons'].updateFunction =
            this.lessonsService.updateLesson.bind(this.lessonsService);

        // Set mappings for delete functions
        moduleMapping['Lessons'].deleteFunction =
            this.lessonsService.deleteLesson.bind(this.lessonsService);
    }

    async ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            const query = params['query'] || '';
            const page = +params['page'] || this.page;
            const limit = +params['limit'] || this.pageLimit;
            this.searchControl.setValue(query, { emitEvent: false });
            this.page = page;
            this.pageLimit = limit;
            this.search();
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.tableHeaders = [
                { key: '_id', label: 'ID', alignment: 'left' },
                { key: 'name', label: 'Name', alignment: 'left' },
                { key: 'skill', label: 'Skills', alignment: 'center' },
                { key: 'questions.length', label: 'Questions', alignment: 'center' },
                {
                    key: 'actions',
                    label: '',
                    alignment: 'right',
                    template: this.actionsTemplate,
                    overflow: 'visible'
                },
            ];
        }, 0);
    }

    async search() {
        this.searching = true;

        const query = this.searchControl.value;
        const page = this.page;
        const limit = this.pageLimit;

        this.tableData = await this.lessonsService.getLessons(page, limit, query);

        this.updateBrowserQueryParams(query, page, limit);

        this.searching = false;
    }

    updateBrowserQueryParams(query: string, page: number, limit: number) {
        const queryParams: { [key: string]: any } = {
            page,
            limit,
            query
        };

        // update the URL's query parameters
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge',
            replaceUrl: false,
        });
    }

    copyId($event: any, id: string) {
        $event.stopPropagation();
        navigator.clipboard.writeText(id);
    }

    rowSelectedFn(rowIndex: number) {
        this.selectItem(this.tableData[rowIndex]);
    }

    selectItem(lesson: Lesson) {
        this.editing = true;
        this.editModalOpen = true;
        this.currentModule = lesson;
        this.descriptionModel = lesson.description;
        this.nameModel = lesson.name;
    }

    actionFocussedForPopover($event: any, index: any) {
        $event.stopPropagation();

        if (index === this.popoverVisibleIndex) {
            this.popoverVisibleIndex = -1;
            return;
        }

        this.popoverVisibleIndex = index;
    }

    actionBlurForPopover() {
        this.popoverVisibleIndex = -1;
    }

    // Functions for modals

    declineFn() {
        this.confirmModalOpen = false;
    }

    openCreateModal($event: any, moduleType?: ModuleTypePlural) {
        $event.stopPropagation();

        if (!moduleType) moduleType = this.moduleType;

        this.creating = true;

        // We're creating a lesson
        this.currentModule = {
            name: '',
            description: '',
        };

        // The following is done to trick the checks into thinking they are of whatever module type.
        if (moduleType === 'Curriculum') {
            this.currentModule.subjects = [];
        } else {
            if (moduleType && moduleMapping[moduleType as ModuleType].parentIdField) {
                const parentIdField = moduleMapping[moduleType as ModuleType].parentIdField;
                if (parentIdField) this.currentModule[parentIdField] = '';
            }
        }

        if (moduleType !== 'Grades') this.gradeLevel = null;
        else this.gradeLevel = 'PK';

        this.editModalOpen = true;
    }

    // filterChange() {
    //     console.log("FILTER CHANGING")
    //     this.search();
    // }

    gotoLessonEditor(lesson: any) {
        const lessonId = lesson._id || lesson.id;
        this.router.navigate(['student', 'admin', 'lesson', lessonId]);
    }

    gotoLessonQaMode(lesson: any) {
        const lessonId = lesson._id || lesson.id;
        this.router.navigate(['student', 'admin', 'lesson', lessonId, 'qa']);
    }

    gotoParent(parentId: string) {
        const moduleType: ModuleType =
            this.currentModuleType || getModuleType(this.currentModule);
        this.closeBackground$.emit();

        if (moduleMapping[moduleType].parentModuleType) {
            this.moduleType =
                moduleMapping[moduleType].parentModuleType || 'Curriculum';
            this.searchControl.setValue(parentId);
            this.search();
        }
    }

    async saveModule() {
        try {
            if (!this.creating && !this.editing) {
                this.toastsService.addToast({
                    title: 'Update Error',
                    description: 'No changes were detected.',
                });

                return;
            }

            if (this.creating) {
                this.createModule();
                return;
            }

            const moduleType: ModuleType = this.currentModuleType || getModuleType(this.currentModule);
            let updates: any = {};

            if (this.editing) {
                updates.name = this.nameModel;
                updates.description = this.descriptionModel;

                if (!moduleMapping[moduleType].parentIdField) {
                    console.log('No parentIdField found. Contact an administrator.');
                    return;
                }

                const parentIdField = moduleMapping[moduleType].parentIdField;

                if (parentIdField) {
                    const currentParentId = this.currentModule[parentIdField];

                    if (
                        moduleType !== 'Curriculum' &&
                        this.parentId &&
                        currentParentId &&
                        this.parentId !== currentParentId
                    ) {
                        this.currentModule[parentIdField] = this.parentId;
                        updates[parentIdField] = this.parentId;
                    }
                }
            }

            // Set to either _id (if its parent module) or id (if it's a sub module)
            const updateId = this.currentModule._id || this.currentModule.id;

            const response = await moduleMapping[moduleType].updateFunction(
                updateId,
                updates
            );
            this.editModalOpen = false;
            this.currentModule.name = updates.name;
            this.currentModule.description = updates.description;
            // Wait for animation to finish, then set currentQuestion to null
            setTimeout(() => {
                this.currentModule = null;
            }, 500);
            this.toastsService.addToast({
                title: 'Update Successful',
                description: 'Module has been successfully updated.',
            });
        } catch (error: any) {
            let message: string = '';
            if (typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({
                title: 'Server error',
                description: message,
            });
        }
    }

    async createModule() {
        this.currentModule.name = this.nameModel;
        this.currentModule.description = this.descriptionModel;
        try {
            let newModule: Module | null = null;

            const moduleType: ModuleType = getModuleType(this.currentModule);

            if (moduleType && moduleMapping[moduleType].parentIdField) {
                const parentIdField = moduleMapping[moduleType].parentIdField;
                if (parentIdField) this.currentModule[parentIdField] = this.parentId;
            }

            if (moduleType === 'Grades') {
                if (this.gradeLevel === null) {
                    this.toastsService.addToast({
                        title: 'Creation Error',
                        description: 'Grade Level must be set.',
                    });
                    return;
                }
                this.currentModule.gradeLevel = this.gradeLevel;
            }

            newModule = await moduleMapping[moduleType].createFunction(this.currentModule);

            this.tableData.push({ ...newModule });
            this.tableData = [...this.tableData];
            this.editModalOpen = false;

            this.toastsService.addToast({
                title: 'Creation Successful',
                description: 'Module has been successfully created.',
            });
        } catch (error: any) {
            let message: string = '';
            if (typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({
                title: 'Server error',
                description: message,
            });
        }
    }

    openDeleteModal(
        $event: any,
        itemForDeletion: Module,
        moduleIndex: number,
        parentModule?: Module,
        subModuleIndex?: number
    ) {
        $event.stopPropagation();

        this.currentModule = itemForDeletion;
        this.currentModuleIndex = moduleIndex;
        this.confirmModalMessage = `Are you sure you want to delete this ${this.getModuleType().toLowerCase()}?`;
        this.confirmModalConfirmFn = this.deleteModule;

        this.confirmModalOpen = true;
        this.popoverVisibleIndex = -1;
    }

    async deleteModule() {
        try {
            let response: any = null;
            if (this.currentModule) {
                if (isLesson(this.currentModule)) {
                    response = await this.lessonsService.deleteLesson(
                        this.currentModule._id
                    );
                } else if (isCourse(this.currentModule)) {
                    response = await this.modulesService.deleteCourse(
                        this.currentModule._id
                    );
                } else if (isCategory(this.currentModule)) {
                    response = await this.modulesService.deleteCategory(
                        this.currentModule._id
                    );
                } else if (isGrade(this.currentModule)) {
                    response = await this.modulesService.deleteGrade(
                        this.currentModule._id
                    );
                } else if (isSubject(this.currentModule)) {
                    response = await this.modulesService.deleteSubject(
                        this.currentModule._id
                    );
                } else if (isCurriculum(this.currentModule)) {
                    response = await this.modulesService.deleteCurriculum(
                        this.currentModule._id
                    );
                }
            }

            if (
                response &&
                response.acknowledged &&
                this.currentModuleIndex !== null
            ) {
                this.toastsService.addToast({
                    title: 'Deletion Successful',
                    description: 'Module has been successfully deleted.',
                });

                this.tableData.splice(this.currentModuleIndex, 1);
                this.tableData = [...this.tableData];
            } else {
                this.toastsService.addToast({
                    title: 'Unknown Error',
                    description:
                        'An unkown error has occurred. Deletion could not be confirmed.',
                });
            }
            this.confirmModalOpen = false;
        } catch (error: any) {
            let message: string = '';
            if (typeof error === 'string') {
                message = error;
            } else {
                message = error.error?.message || error.message;
            }
            this.toastsService.addToast({
                title: 'Server error',
                description: message,
            });
        }
    }

    getModuleType() {
        if (this.moduleType === 'Categories') return 'Category';
        if (this.moduleType === 'Curriculum') return 'Curriculum';
        return (
            this.moduleType.charAt(0).toUpperCase() +
            this.moduleType.substring(1, this.moduleType.length - 1)
        );
    }
}
