import { Injectable } from "@angular/core";
import { GENERATING_SPEAKABLE_TEXT } from "src/constants/shared";
import { ToastsService } from "./toasts.service";
import { environment } from "src/environment/environment";
import { HttpClient } from "@angular/common/http";
import { firstValueFrom } from "rxjs";


@Injectable({
    providedIn: "root"
})
export class SpeakableService {

    // This controls whether the user is actively trying to use our on screen reader. 
    // Active means they're actively clicking on screen items for the service to read.
    clickToSpeechActive: boolean = false;

    // Varialbes to control current audio playing
    currentSpeakableUrl: string = "";
    currentlySpeaking: boolean = false;
    currentAudio: HTMLAudioElement;

    constructor(private toastsService: ToastsService, private httpClient: HttpClient) { }

    toggleClickToSpeechActive() {
        this.clickToSpeechActive = !this.clickToSpeechActive;
        if(!this.clickToSpeechActive && this.currentAudio) {
            this.currentAudio.pause();
            this.currentAudio.currentTime = 0;
            this.currentlySpeaking = false;
        }
    }

    async playSpeakableByKey(speakableKey: string, textToPlay?: string) {
        // Handle a popup or something if we're still generating this speakable
        if(!speakableKey || speakableKey === GENERATING_SPEAKABLE_TEXT) {
            try {
                if (window.speechSynthesis && textToPlay) {
                    let utterance = new SpeechSynthesisUtterance(textToPlay.replace(/(<([^>]+)>)/ig, ''));
                    speechSynthesis.speak(utterance);
                } else {
                    // Can't play without our speech, let them know it is generating
                    this.toastsService.addToast({
                        title: "Still generating",
                        description: "The speech you requested is currently being generated. Try again in a couple minutes.",
                        type: "warning"
                    });
                }
            } catch(error) {
                // Toast for the error
                this.toastsService.addToast({
                    title: "Speech Error",
                    description: "There was a problem trying to play this speech.",
                    type: "error"
                });
            }
            return;
        }

        // Check if user is trying to play a different sounds than what is possibly playing. If so, stop current audio and play new audio
        if(speakableKey !== this.currentSpeakableUrl) {
            if(this.currentAudio) this.currentAudio.pause();
            this.currentlySpeaking = false;
            const response = await firstValueFrom(this.httpClient.get(`${environment.apiBaseUrl}/speakables/${speakableKey}`)) as any;
            this.currentSpeakableUrl = response.imageUrl
            this.currentAudio = new Audio(this.currentSpeakableUrl);
        } else if(this.currentlySpeaking) {
            if(this.currentAudio) this.currentAudio.pause();
            this.currentlySpeaking = false;
            return;
        }

        // Return if we are still speaking
        if(this.currentlySpeaking) return;

        // Start speaking our audio
        this.currentlySpeaking = true;
        this.currentAudio.play();
        this.currentAudio.onended = () => {
            this.currentlySpeaking = false;
        }
    }

    // Get an mp3 file from the URL provided and play audio
    async getAndPlaySpeakable(speakableUrl: string | undefined, textToPlay?: string) {
        // Handle a popup or something if we're still generating this speakable
        if(!speakableUrl || speakableUrl === GENERATING_SPEAKABLE_TEXT) {
            try {
                if (window.speechSynthesis && textToPlay) {
                    let utterance = new SpeechSynthesisUtterance(textToPlay.replace(/(<([^>]+)>)/ig, ''));
                    speechSynthesis.speak(utterance);
                } else {
                    // Can't play without our speech, let them know it is generating
                    this.toastsService.addToast({
                        title: "Still generating",
                        description: "The speech you requested is currently being generated. Try again in a couple minutes.",
                        type: "warning"
                    });
                }
            } catch(error) {
                // Toast for the error
                this.toastsService.addToast({
                    title: "Speech Error",
                    description: "There was a problem trying to play this speech.",
                    type: "error"
                });
            }
            return;
        }

        // Check if user is trying to play a different sounds than what is possibly playing. If so, stop current audio and play new audio
        if(speakableUrl !== this.currentSpeakableUrl) {
            if(this.currentAudio) this.currentAudio.pause();
            this.currentlySpeaking = false;
            this.currentSpeakableUrl = speakableUrl;
            this.currentAudio = new Audio(speakableUrl);
        } else if(this.currentlySpeaking) {
            if(this.currentAudio) this.currentAudio.pause();
            this.currentlySpeaking = false;
            return;
        }

        // Return if we are still speaking
        if(this.currentlySpeaking) return;

        // Start speaking our audio
        this.currentlySpeaking = true;
        this.currentAudio.play();
        this.currentAudio.onended = () => {
            this.currentlySpeaking = false;
        }
    }

    // Get an mp3 file from the URL provided and play audio
    async getAndPlayPhoneme(phoneme: string) {
        // Check if user is trying to play a different sounds than what is possibly playing. If so, stop current audio and play new audio
        if(phoneme !== this.currentSpeakableUrl) {
            if(this.currentAudio) this.currentAudio.pause();
            this.currentlySpeaking = false;
            this.currentSpeakableUrl = phoneme;
            this.currentAudio = new Audio(`${environment.aws.phonemeBucket}/${phoneme.replace(':', '')}.mp3`);
        } else if(this.currentlySpeaking) {
            if(this.currentAudio) this.currentAudio.pause();
            this.currentlySpeaking = false;
            return;
        }

        // Return if we are still speaking
        if(this.currentlySpeaking) return;

        // Start speaking our audio
        this.currentlySpeaking = true;
        this.currentAudio.play();
        this.currentAudio.onended = () => {
            this.currentlySpeaking = false;
        }
    }
}