<cl-modal [(open)]="newStudentModalOpen">
    <c-spinner *ngIf="allInputsDisabled"></c-spinner>
    <div class="new-student-modal-content">
        <div class="text">
            <h3>New student info</h3>
            <p *ngIf="errorText" class="error-text">{{ errorText }}</p>
        </div>
        <div class="inputs">
            <c-input [label]="'Name'" [disabled]="allInputsDisabled" [(cModel)]="newStudentName"></c-input>
            <c-input [label]="'Username'" [disabled]="allInputsDisabled" [(cModel)]="newStudentUsername"></c-input>
            <c-input [label]="'Password'" [disabled]="allInputsDisabled" [(cModel)]="newStudentPassword"></c-input>
        </div>
        <button (click)="createNewStudent()">Create student</button>
        <button (click)="closeNewStudentModal()" class="secondary">Cancel</button>
    </div>
</cl-modal>

<div class="content-holder">
    <div class="text">
        <h2>Students</h2>
        <button (click)="openNewStudentModal()">Add student</button>
    </div>
    <div class="headers">
        <p class="name caption"><strong>Name</strong></p>
        <p class="username caption"><strong>Username</strong></p>
        <p class="current-course caption"><strong>Activity</strong></p>
    </div>
    <div class="list-students-holder">
        <div *ngFor="let student of studentList" class="list-student">
            <div class="char-wrapper">
                <div class="char-image-holder">
                    <character-icon-render [gender]="student.characterInfo.gender"
                        [skinColor]="student.characterInfo.skinColor" [hairColor]="student.characterInfo.hairColor"
                        [helmet]="student.characterInfo.helmet" [eyes]="student.characterInfo.eyes"
                        [head]="student.characterInfo.head" [armor]="student.characterInfo.armor"
                        [back]="student.characterInfo.back">
                    </character-icon-render>
                    <div class="frame"></div>
                </div>
                <div class="status-indicator"
                    [ngStyle]="{'background-color': student.status?.online ? 'green' : 'grey'}">
                </div>
            </div>
            <div class="activity">
                <p class="name"><strong>{{ student.firstName + ' ' + student.lastName }}</strong></p>
                <p class="caption disabled" *ngIf="!student.status?.online">
                    {{ student.status?.lastCheckedIn | date: 'MMM d, y, h:mm a' }}
                </p>

                <p class="caption disabled" *ngIf="student.status?.online">Active</p>
            </div>
            <p class="currently-on">{{ student.username }}</p>

            <p class="current-course">
                <ng-container *ngIf="student.status?.currentlyOn; else noCourse">
                  {{ student.status?.currentlyOn?.name }} | {{ student.status?.currentlyOn?.description }}
                </ng-container>
                <ng-template #noCourse>No activity started</ng-template>
              </p>
              
        </div>
    </div>

    <div *ngIf="studentList.length === 0" class="list-student empty">
        <h3>Empty classroom</h3>
        <p>You have no students yet! Click on the button to the top right to create your first student.</p>
    </div>
</div>