import { NgModule } from '@angular/core';
import { CourseTotemComponent } from './course-totem.component';
import { BrowserModule } from '@angular/platform-browser';
import { SecureImagePipeModule } from 'src/pipes/secure-image.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CourseTotemComponent],
  imports: [BrowserModule, RouterModule, SecureImagePipeModule],
  exports: [CourseTotemComponent],
})
export class CourseTotemModule {}
