import { NgModule } from '@angular/core';
import { CurriculumEditorComponent } from './curriculum-editor.component';
import { CurriculumModuleModule } from './curriculum-module/curriculum-module.module';
import { CommonModule } from '@angular/common';
import { CosmittModalModule } from 'src/components/modal/modal.module';
import { CosmittInputModule } from 'src/components/c-input/c-input.module';
import { CosmittTextEditorModule } from 'src/components/c-text-editor/c-text-editor.module';
import { ConfirmModalModule } from 'src/components/confirm-modal/confirm-modal.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { SearchInputModule } from 'src/components/search-input/search-input.module';

@NgModule({
  declarations: [CurriculumEditorComponent],
  imports: [
    CommonModule,
    CurriculumModuleModule,
    CosmittModalModule,
    CosmittInputModule,
    CosmittTextEditorModule,
    ConfirmModalModule,
    DragDropModule,
    SearchInputModule
],
  exports: [CurriculumEditorComponent],
})
export class CurriculumEditorModule {}
