import { NgModule } from "@angular/core";
import { CosmittArrowComponent } from "./c-arrow.component";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { SecureImagePipeModule } from "src/pipes/secure-image.module";

@NgModule({
    declarations: [CosmittArrowComponent],
    imports: [RouterModule, BrowserModule, SecureImagePipeModule],
    exports: [CosmittArrowComponent]
})
export class CosmittArrowModule {}  