import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CharacterEquipable, CharacterEqupablePieceData, CharacterInfo, HairColor, SkinColor } from 'src/types/users';
import { HttpClient } from '@angular/common/http';
import { lastValueFrom } from 'rxjs';
import { EquipablePiece, EquipableType, Gender } from 'src/types/equipables';
import { EquipablesService } from 'src/services/equipables.service';


@Component({
  selector: 'character-icon-render',
  templateUrl: './character-icon-render.component.html',
  styleUrls: ['./character-icon-render.component.scss']
})
export class CharacterIconRenderComponent implements OnChanges {
  // List of inputs for character info
  // Importing all types seperately to handle onChanges more efficiently and rerendering more effectively
  @Input('gender') _gender: Gender;
  @Input('hairColor') _hairColor: HairColor;
  @Input('skinColor') _skinColor: SkinColor;
  @Input('armor') _armor: CharacterEquipable;
  @Input('helmet') _helmet: CharacterEquipable;
  @Input('head') _head: CharacterEquipable;
  @Input('eyes') _eyes: CharacterEquipable;
  @Input('back') _back: CharacterEquipable | undefined;

  // All peices that will be rendered
  helmetHead: CharacterEqupablePieceData;
  helmetBehindHead: CharacterEqupablePieceData;
  eyes: CharacterEqupablePieceData;
  head: CharacterEqupablePieceData;

  armFrontUpper: CharacterEqupablePieceData;
  torso: CharacterEqupablePieceData;
  armBackUpper: CharacterEqupablePieceData;

  back?: CharacterEqupablePieceData;

  // Decorators for all HTML elements
  helmetHeadSvgData: SafeHtml;
  helmetBehindHeadSvgData: SafeHtml;
  headSvgData: SafeHtml;
  eyesSvgData: SafeHtml;

  armFrontUpperSvgData: SafeHtml;
  torsoSvgData: SafeHtml;
  armBackUpperSvgData: SafeHtml;

  backSvgData: SafeHtml;

  // Controls for skin and hair colors
  skinColors: {[key in SkinColor]: boolean} = {
    "light": false,
    "tan": false,
    "dark": false,
    "darker": false
  }

  hairColors: {[key in HairColor]: boolean} = {
    "red": false,
    "blonde": false,
    "brown": false,
    "darkBrown": false,
    "black": false
  }

  constructor(private equipablesService: EquipablesService, private httpClient: HttpClient, protected sanitizer: DomSanitizer) { 
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['_gender']) {
      const gender = this._gender === 'male' ? 'default' : 'female';
      // If gender changed, we need to reload all character parts
      this.setComponentEquipable('helmetHead', 'helmet', 'helmetHead');
      this.setComponentEquipable('helmetBehindHead', 'helmet', 'helmetBehindHead');
      this.setComponentEquipable('eyes', 'eyes', 'eyes');
      this.setComponentEquipable('head', 'head', 'head');
      this.setComponentEquipable('armFrontUpper', 'armor', 'armFrontUpper');
      this.setComponentEquipable('armFrontLower', 'armor', 'armFrontLower');
      this.setComponentEquipable('torso', 'armor', 'torso');
      this.setComponentEquipable('armBackLower', 'armor', 'armBackLower');
      this.setComponentEquipable('armBackUpper', 'armor', 'armBackUpper');
      this.setComponentEquipable('torsoLower', 'armor', 'torsoLower');
      this.setComponentEquipable('legFrontUpper', 'armor', 'legFrontUpper');
      this.setComponentEquipable('legFrontLower', 'armor', 'legFrontLower');
      this.setComponentEquipable('legBackUpper', 'armor', 'legBackUpper');
      this.setComponentEquipable('legBackLower', 'armor', 'legBackLower');
      if(this._back) this.setComponentEquipable('back', 'back', 'back');
    }

    if(changes['_skinColor']) {
      // Set skin color
      Object.keys(this.skinColors).forEach((sc) => {this.skinColors[sc as SkinColor] = false});
      this.skinColors[this._skinColor] = true;
      this.skinColors = Object.assign({}, this.skinColors);
    }

    if(changes['_hairColor']) {
      // Set hair color
      Object.keys(this.hairColors).forEach((hc) => {this.hairColors[hc as HairColor] = false});
      this.hairColors[this._hairColor] = true;
      this.hairColors = Object.assign({}, this.hairColors);
    }

    if(changes['_helmet']) {
      this.setComponentEquipable('helmetHead', 'helmet', 'helmetHead');
      this.setComponentEquipable('helmetBehindHead', 'helmet', 'helmetBehindHead');
    }

    if(changes['_eyes']) {
      this.setComponentEquipable('eyes', 'eyes', 'eyes');
    }

    if(changes['_head']) {
      this.setComponentEquipable('head', 'head', 'head');
    }

    if(changes['_armor']) {
      this.setComponentEquipable('armFrontUpper', 'armor', 'armFrontUpper');
      this.setComponentEquipable('armFrontLower', 'armor', 'armFrontLower');
      this.setComponentEquipable('torso', 'armor', 'torso');
      this.setComponentEquipable('armBackLower', 'armor', 'armBackLower');
      this.setComponentEquipable('armBackUpper', 'armor', 'armBackUpper');
      this.setComponentEquipable('torsoLower', 'armor', 'torsoLower');
      this.setComponentEquipable('legFrontUpper', 'armor', 'legFrontUpper');
      this.setComponentEquipable('legFrontLower', 'armor', 'legFrontLower');
      this.setComponentEquipable('legBackUpper', 'armor', 'legBackUpper');
      this.setComponentEquipable('legBackLower', 'armor', 'legBackLower');
    }

    if(changes['_back']) {
      if(this._back) this.setComponentEquipable('back', 'back', 'back');
    }
  }

  async setComponentEquipable(field: EquipablePiece, equipableType: EquipableType, piece: EquipablePiece) {
    const partial: CharacterEquipable = (this as any)[`_${equipableType}`];
    if(!partial) return;

    const whole = await this.equipablesService.getEquipable(partial.id);
    let eq = whole.default;

    if(this._gender === 'female' && whole.female) {
      eq = whole.female
    }

    (this as any)[field] = (eq.pieces as any)[piece];
    this.getSvgData(field);
  }

  // Get the offset the equipable is from its base position
  getItemTransform(baseItem: CharacterEqupablePieceData | undefined) {
    if(!baseItem || !baseItem.offset) return '';
    return `translate(${baseItem.offset.x}px, ${baseItem.offset.y}px)`;
  }

  async getSvgData(type: string) {
    if(!(this as any)[type]?.svgUrl) return;
    const data = await lastValueFrom(this.httpClient.get((this as any)[type].svgUrl, { responseType: 'text' }));
    (this as any)[type + 'SvgData'] = this.sanitizer.bypassSecurityTrustHtml(data);
  }
  
}
