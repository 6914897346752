import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { SkillsTabComponent } from './skills-tab.component';
import { CosmittTableModule } from 'src/components/c-table/c-table.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CosmittPopoverModule } from 'src/components/c-popover/c-popover.module';
import { CosmittModalModule } from 'src/components/modal/modal.module';
import { CosmittTextEditorModule } from 'src/components/c-text-editor/c-text-editor.module';
import { CosmittInputModule } from 'src/components/c-input/c-input.module';
import { ClickOutsideModule } from 'src/directives/click-outside.module';
import { ConfirmModalModule } from 'src/components/confirm-modal/confirm-modal.module';

@NgModule({
  declarations: [SkillsTabComponent],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    CosmittTableModule,
    CosmittPopoverModule,
    CosmittModalModule,
    CosmittTextEditorModule,
    CosmittInputModule,
    ClickOutsideModule,
    ConfirmModalModule
  ],
  exports: [SkillsTabComponent],
})
export class SkillsTabModule {}
