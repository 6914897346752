import { NgModule } from "@angular/core";
import { TopNavComponent } from "./top-nav.component";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { UpgradePopupModule } from "../upgrade-popup/upgrade-popup.module";
import { CosmittModalModule } from "../modal/modal.module";
import { CharacterIconRenderModule } from "../character-icon-render/character-icon-render.module";
import { CosmittToggleModule } from "../c-toggle/c-toggle.module";

@NgModule({
    declarations: [TopNavComponent],
    imports: [RouterModule, BrowserModule, UpgradePopupModule, CosmittModalModule, CharacterIconRenderModule, CosmittToggleModule],
    exports: [TopNavComponent]
})
export class TopNavModule {}  