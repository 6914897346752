import { EventEmitter, Injectable } from "@angular/core";

export type ToastType = 'default' | 'success' | 'warning' | 'error' | 'info';

export interface Toast {
    id?: number; // Just a genrally random number. Not too important, just in case there's multiple toasts coming up at once.
    title: string;
    description?: string;
    type?: ToastType;
    actions?: boolean;
    actionConfirmText?: string;
    actionConfirmFn?: Function;
    actionDeclineText?: string;
    actionDeclineFn?: Function;
}

export interface SmallToast {
    id?: number; // Just a genrally random number. Not too important, just in case there's multiple toasts coming up at once.
    message: string;
    type?: ToastType;
}

@Injectable({
    providedIn: 'root'
})
export class ToastsService {

    toasts: Toast[] = [];
    smallToasts: SmallToast[] = [];
    toastAdded$: EventEmitter<Toast> = new EventEmitter<Toast>();
    toastRemoved$: EventEmitter<Toast> = new EventEmitter<Toast>();
    
    constructor() { }

    addToast(toast: Toast) {
        toast.id = Math.random();
        this.toasts.push(toast);
        this.toastAdded$.emit(toast);
    }

    removeToast(toastId: number) {
        const toastIndex = this.toasts.findIndex((toast: Toast) => toast.id === toastId);
        if(toastIndex !== -1) {
            this.toastRemoved$.emit(this.toasts[toastIndex]);
            this.toasts.splice(toastIndex, 1);
        }
    }

    getNextToast(): Toast | null {
        if(this.toasts.length === 0) return null;
        return this.toasts[0];
    }

    addSmallToastMessage(message: string) {

    }
}