<div class="container">
  <div class="lesson-popout" [class.isActive]="isActive">
    <h3>{{ lesson.name }}</h3>
    <p [innerHTML]="lesson.description"></p>
    <div class="badges">
      <!-- <div class="game-type-badge">Select the word</div>
      <div class="lesson-time-badge">~12 minutes</div> -->
    </div>

    <div class="start-button">
      <button [routerLink]="'/student/lesson/' + lesson._id">Start Lesson</button>
    </div>
  </div>

  <img (click)="onClick()" class="totem" [src]="('/images/student-assets%2Ftotem.png' | secureImage) | async" />

  <div *ngIf="grade || grade < 70" class="stars">
    <img
      *ngIf="grade < 80 && grade >= 70"
      class="bronze star"
      [src]="('/images/student-assets%2Fbronze.png' | secureImage) | async"
    />
    <div *ngIf="grade < 90 && grade >= 80" class="star-group">
      <img class="star edge" [src]="('/images/student-assets%2Fsilver.png' | secureImage) | async" />
      <img class="star" [src]="('/images/student-assets%2Fsilver.png' | secureImage) | async" />
    </div>
    <div *ngIf="grade >= 90" class="star-group">
      <img class="star edge" [src]="('/images/student-assets%2Fgold.png' | secureImage) | async" />
      <img class="star" [src]="('/images/student-assets%2Fgold.png' | secureImage) | async" />
      <img class="star edge" [src]="('/images/student-assets%2Fgold.png' | secureImage) | async" />
    </div>
  </div>
</div>
