import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { AuthService } from 'src/services/auth.service';
import { TokenInterceptorService } from 'src/interceptors/token-interceptor.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QuillModule } from 'ngx-quill';
import { LoggedInModule } from 'src/states/logged-in/logged-in.module';
import { ToastsModule } from 'src/components/toasts/toasts.module';
import { CosmittModalModule } from 'src/components/modal/modal.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    // Top Level Modules
    LoggedInModule,
    ToastsModule,
    // External Modules
    BrowserModule,
    AppRoutingModule,
    CosmittModalModule,
    ReactiveFormsModule,
    HttpClientModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    FormsModule,
    QuillModule.forRoot(),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '691721837123-cqasdqbt9s38esbsku8g2p4ktu62ttfp.apps.googleusercontent.com'
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    AuthService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
