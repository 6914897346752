import { NgModule } from "@angular/core";
import { CourseCardComponent } from "./course-card.component";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [CourseCardComponent],
    imports: [RouterModule, BrowserModule],
    exports: [CourseCardComponent]
})
export class CourseCardModule {}  