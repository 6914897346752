import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { RankingEditorComponent } from "./ranking-editor.component";
import { CosmittImageUploaderModule } from "src/components/c-image-uploader/c-image-uploader.module";

@NgModule({
    declarations: [RankingEditorComponent],
    imports: [BrowserModule, CosmittInputModule, FormsModule, DragDropModule, CosmittImageUploaderModule],
    exports: [RankingEditorComponent]
})
export class RankingEditorModule {}  