import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { ProgressionGraphComponent } from "./progression-graph.component";
import { CosmittTableModule } from "src/components/c-table/c-table.module";
import { CosmittPopoverModule } from "src/components/c-popover/c-popover.module";

@NgModule({
    declarations: [ProgressionGraphComponent],
    imports: [
        BrowserModule, 
        CosmittModalModule, 
        CosmittInputModule, 
        CosmittSpinnerModule, 
        CosmittTableModule,
        CosmittPopoverModule
    ],
    exports: [ProgressionGraphComponent]
})
export class ProgressionGraphModule { }  