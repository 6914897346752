import { NgModule } from "@angular/core";
import { CharacterIconRenderComponent } from "./character-icon-render.component";
import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from "@angular/common";

@NgModule({
    declarations: [CharacterIconRenderComponent],
    imports: [BrowserModule, CommonModule],
    exports: [CharacterIconRenderComponent]
})
export class CharacterIconRenderModule {}  