import { InvalidQuestionStructureError } from "../errors/questions.errors";

export type WordType = "noun" | "verb" | "adjective" | "adverb" | "conjunction" | "interjection" | "preposition" | "pronoun" | "punctuation" | "article";
export type PartOfSpeach = "noun" | "verb" | "adjective" | "adverb" | "conjunction" | "interjection" | "preposition" | "pronoun";

export type QuestionType = "selectTheWord" | "selectAndChange" | "multipleChoice" | "fillInTheBlank" | "grouping" | "unscramble" | "ranking" | "spelling" | "spellingMultiStep";

export type QaStatus = 'notChecked' | "flagged" | "manuallyVerified" | "automaticallyVerified";

export interface BaseQuestion {
    _id: string;
    type: QuestionType;
    data: any;
    qaStatus?: QaStatus[];
}

export interface QuestionImage {
    key: string;
}

export const isQuestion = (item: any): item is Question => {
    return item !== undefined && item.type !== undefined && item.data !== undefined;
}

export const verifyQuestion = (question: any, questionType: QuestionType) => {
    switch(questionType) {
        case 'selectTheWord':
            return verifySTWQuestion(question);
        case 'selectAndChange':
            return verifySACQuestion(question);
        case 'multipleChoice':
            return verifyMCQuestion(question);
        case 'fillInTheBlank':
            return verifyFITBQuestion(question);
        case 'grouping':
            return verifyGroupingQuestion(question);
        case 'unscramble':
            return verifyUnscrambleQuestion(question);
        case 'ranking':
            return verifyRankingQuestion(question);
    }
}

// Interfaces for Questions within a module. There are several different types.

// Select the Word game mode.

/** A word for the Select-the-word question */
export interface STWWord {
    text: string;
    correct: boolean; // Whether the word is a correct option or not.
    wordType?: WordType;
    color?: string;
    speechData?: {
        text: string;
    };
}

/** Structure for Select-the-word question */
export interface STWQuestion extends BaseQuestion {
    data: {
        instruction: string;
        sentence: string;
        words: STWWord[];
        images: QuestionImage[];
    }
    type: "selectTheWord";
}

export const isSTWQuestion = (question: Question): question is STWQuestion => {
    return question !== undefined && question.type !== undefined && question.type === "selectTheWord";
}

export const verifySTWQuestion = (question: STWQuestion) => {
    if(!question.data.sentence) {
        throw new InvalidQuestionStructureError("SelectTheWord Question must have field 'sentence' defined.");
    }

    let hasCorrectAnswer = false;

    for(let word of question.data.words) {
        if(word.correct) hasCorrectAnswer = true;
        if(!word.text) throw new InvalidQuestionStructureError("SelectTheWord Word must have field 'word' defined.");
    }

    if(!hasCorrectAnswer) throw new InvalidQuestionStructureError("SelectTheWord Question must have at least one word in 'words' that has field 'correct' set to 'true'.");
}







// Select and Change game mode.

export interface SACWord {
    text: string;
    wordType?: WordType;
    wordIsWrong: boolean; // If true, it means the word is incorrect and the user is supposed to select this word and change it to the correctedWord.
    correctWord?: string; // The corrected version of the word.
    color?: string;
    speechData?: {
        text: string;
        correctWord?: string;
    };
}

/** Structure for Select and Change question */
export interface SACQuestion extends BaseQuestion {
    data: {
        instruction: string;
        sentence: string;
        words: SACWord[];
        images: QuestionImage[];
    }
    type: "selectAndChange";
}

export const isSACQuestion = (question: Question): question is SACQuestion => {
    return question !== undefined && question.type !== undefined && question.type === "selectAndChange";
}

export const verifySACQuestion = (question: SACQuestion) => {
    if(!question.data.sentence) {
        throw new InvalidQuestionStructureError("SelectAndChange Question must have field 'sentence' defined.");
    }

    let hasIncorrectWord = false; // Must have at least 1 incorrect word for the user to choose and make corrections to

    for(let word of question.data.words) {
        if(!word.text) throw new InvalidQuestionStructureError("SelectAndChange Word must have field 'text' defined.");
        if(word.wordIsWrong) {
            hasIncorrectWord = true;
            
            if(!word.correctWord) throw new InvalidQuestionStructureError("SelectAndChange Word that is incorrect must have a defined 'correctWord' field.");
        }
    }

    if(!hasIncorrectWord) throw new InvalidQuestionStructureError("SelectAndChange Question must have at least one word in 'words' that has field 'wordIsWrong' set to 'true'.");
}






export interface MCChoice {
    correct: boolean;
    text: string;
    color?: string;
    speechData?: {
        text: string;
    };
}

/** Structure for a multiple choice question */
export interface MCQuestion extends BaseQuestion {
    data: {
        instruction: string;
        sentence: string;
        choices: MCChoice[];
        speechData?: {
            sentence: string;
            instruction: string;
        }
        images: QuestionImage[];
    }
    type: "multipleChoice";
}

export const isMCQuestion = (question: Question): question is MCQuestion => {
    return question !== undefined && question.type !== undefined && question.type === "multipleChoice";
}

export const verifyMCQuestion = (question: MCQuestion) => {
    if(!question.data.sentence) {
        throw new InvalidQuestionStructureError("MultipleChoice Question must have field 'sentence' defined.");
    }

    let hasCorrectAnswer = false;

    if(question.data.choices.length <= 1) throw new InvalidQuestionStructureError("MultipleChoice Question must have at least 2 choices.");

    for(let choice of question.data.choices) {
        if(choice.correct) hasCorrectAnswer = true;
        if(!choice.text) throw new InvalidQuestionStructureError("MultipleChoice Word must have field 'text' defined.");
    }

    if(!hasCorrectAnswer) throw new InvalidQuestionStructureError("MultipleChoice Question must have at least one word in 'words' that has field 'correct' set to 'true'.");
}







export interface FITBWord {
    text: string;
    wordType?: WordType;
    isBlank: boolean; // If the word is being displayed as a blank
    hint?: string; // The hint for the word. (e.g. if the word is ran, the hint may be "What's the correct past tense word")
    color?: string; // Color of the word. Just used in front-end, not stored in DB
    speechData?: {
        text: string;
        hint?: string;
    };
}

/** Structure for Fill-in-the-blank question */
export interface FITBQuestion extends BaseQuestion {
    data: {
        instruction: string;
        sentence: string;
        words: FITBWord[];
        images: QuestionImage[];
    }
    type: "fillInTheBlank";
}

export const isFITBQuestion = (question: Question): question is FITBQuestion => {
    return question !== undefined && question.type !== undefined && question.type === "fillInTheBlank";
}

export const verifyFITBQuestion = (question: FITBQuestion) => {
    if(!question.data.sentence) {
        throw new InvalidQuestionStructureError("FillInTheBlank Question must have field 'sentence' defined.");
    }

    let hasBlankWord = false; // Must have at least 1 blank word th for the user to fill in

    for(let word of question.data.words) {
        if(!word.text) throw new InvalidQuestionStructureError("FillInTheBlank Word must have field 'text' defined.");
        if(word.isBlank) hasBlankWord = true;
    }

    if(!hasBlankWord) throw new InvalidQuestionStructureError("FillInTheBlank Question must have at least one word in 'words' that has field 'wordIsWrong' set to 'true'.");
}





export interface GroupingGroupItem {
    item: string;
    speechData?: {
        item: string;
    };
}

export interface GroupingGroup {
    title: string;
    description?: string;
    content: GroupingGroupItem[];
    speechData?: {
        title: string;
        description?: string;
    };
}

/** Structure for a Grouping question */
export interface GroupingQuestion extends BaseQuestion {
    data: {
        instruction: string;
        groups: GroupingGroup[];
    };
    type: "grouping";
}

export const isGroupingQuestion = (question: Question): question is GroupingQuestion => {
    return question !== undefined && question.type !== undefined && question.type === "grouping";
}

export const verifyGroupingQuestion = (question: GroupingQuestion) => {
    if(question.data.groups.length <= 1) throw new InvalidQuestionStructureError("Grouping Question must have at least 2 groups.");

    for(let group of question.data.groups) {
        if(!group.title) throw new InvalidQuestionStructureError("Grouping Group must have field 'title' defined.");
        for(let content of group.content) {
            if(!content.item) throw new InvalidQuestionStructureError("Grouping Content must have field 'item' defined.");
        }
    }
}




// Unscramble game mode.

/** A word for the Select-the-word question */
export interface UnscrambleWord {
    correctIndex: number; // What position in the correct sentence this word should be at.
    text: string;
    wordType?: WordType;
    color?: string;
    speechData?: {
        text: string;
    };
}

/** Structure for Select-the-word question */
export interface UnscrambleQuestion extends BaseQuestion {
    data: {
        instruction: string;
        // sentence: string;
        words: UnscrambleWord[];
        images: QuestionImage[];
    };
    type: "unscramble";
}

export const isUnscrambleQuestion = (question: Question): question is UnscrambleQuestion => {
    return question !== undefined && question.type !== undefined && question.type === "unscramble";
}

export const verifyUnscrambleQuestion = (question: UnscrambleQuestion) => {
    // if(!question.data.sentence) throw new InvalidQuestionStructureError("Unscramble Question must have field 'sentence' defined.");
    if(!question.data.instruction) throw new InvalidQuestionStructureError("Unscramble Question must have field 'instruction' defined.");

    let indexesTaken: any[] = [];

    for(let word of question.data.words) {
        if(!word.text) throw new InvalidQuestionStructureError("Unscramble Word must have field 'text' defined.");
        if(indexesTaken[word.correctIndex]) {
            throw new InvalidQuestionStructureError(`Unscramble Question must only have 1 entry for 'correctIndex', found multiple for index '${word.correctIndex}'.`);
        }
    }
}






export interface RankingContent {
    item: string,
    speechData?: {
        item: string;
    };
};

// Ranking game mode.

export interface RankingQuestion extends BaseQuestion {
    data: {
        instruction: string;
        content: RankingContent[];
        images: QuestionImage[];
    };
    type: "ranking",
};

export const isRankingQuestion = (question: Question): question is RankingQuestion => {
    return question !== undefined && question.type !== undefined && question.type === "ranking";
}

export const verifyRankingQuestion = (question: RankingQuestion) => {
    if(!question.data.instruction) throw new InvalidQuestionStructureError("Ranking Question must have field 'instruction' defined.");

    for(let content of question.data.content) {
        if(!content.item) throw new InvalidQuestionStructureError("Ranking Content must have field 'item' defined.");
    }
};





// Spelling game mode

export interface SpellingWordPhoneme {
    phoneme: string;
    speechData?: {
        phoneme: string;
    }
}

export interface SpellingWord {
    word: string;
    speechData?: {
        word: string;
    };
    correctPhonemes: SpellingWordPhoneme[];
    falsePhonemes: SpellingWordPhoneme[];
}

export interface SpellingQuestion extends BaseQuestion {
    data: {
        instruction: string;
        words: SpellingWord[];
    }
    type: "spelling";
}

export function isInstanceSpellingQuestion(object: any): object is SpellingQuestion{
    return object.type && typeof(object.type) == "string" && object.type === "spelling";
}




export type Question = BaseQuestion | STWQuestion | SACQuestion | MCQuestion | FITBQuestion | GroupingQuestion | UnscrambleQuestion | RankingQuestion | SpellingQuestion;



// Throws error if validation fails and should get caught in route
export const validateQuestion = (question: Question) => {
    switch(question.type) {
        case "selectTheWord": 
            verifySTWQuestion(question as STWQuestion);
            break;
        case "selectAndChange": 
            verifySACQuestion(question as SACQuestion);
            break;
        case "multipleChoice": 
            verifyMCQuestion(question as MCQuestion);
            break;
        case "fillInTheBlank": 
            verifyFITBQuestion(question as FITBQuestion);
            break;
        case "grouping": 
            verifyGroupingQuestion(question as GroupingQuestion);
            break;
        case "unscramble": 
            verifyUnscrambleQuestion(question as UnscrambleQuestion);
            break;
        case "ranking": 
            verifyRankingQuestion(question as RankingQuestion);
            break;
    }
}
