import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { SelectTheWordComponent } from "./select-the-word.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [SelectTheWordComponent],
    imports: [FormsModule, BrowserModule],
    exports: [SelectTheWordComponent]
})
export class SelectTheWordModule {}  