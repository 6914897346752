import { Component, EventEmitter, Input, Output } from "@angular/core";


@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

    _open: boolean;

    @Input() 
    set open(value: boolean) {
        if(this._open === value) return;
        this._open = value;
        this.openChange.emit(value);
    }
    get open() {
        return this._open;
    }
    @Output() openChange = new EventEmitter<boolean>();
    @Input() message?: string = "Are you sure you would like to perform this action?";
    @Input() confirmText?: string = "Confirm";
    @Input() declineText?: string = "Decline";
    @Input() confirmFn!: Function;
    @Input() declineFn!: Function;

    constructor() { }
}