import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittPopoverComponent } from "./c-popover.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [CosmittPopoverComponent],
    imports: [BrowserModule, FormsModule],
    exports: [CosmittPopoverComponent]
})
export class CosmittPopoverModule {}  