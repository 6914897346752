import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { ConfirmModalModule } from "src/components/confirm-modal/confirm-modal.module";
import { EquipableCreationPage } from "./equipable-creation.component";
import { CharacterRenderModule } from "src/components/character-render/character-render.module";
import { CosmittSelectModule } from "src/components/c-select/c-select.module";

@NgModule({
    declarations: [EquipableCreationPage],
    imports: [
        BrowserModule,
        FormsModule, 
        CosmittModalModule, 
        ConfirmModalModule,
        CharacterRenderModule,
        CosmittSelectModule
    ],
    exports: [EquipableCreationPage]
})
export class EquipableCreationPageModule {}  