import { NgModule } from "@angular/core";
import { CosmittStarComponent } from "./c-star.component";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { SecureImagePipeModule } from "src/pipes/secure-image.module";

@NgModule({
    declarations: [CosmittStarComponent],
    imports: [RouterModule, BrowserModule, SecureImagePipeModule],
    exports: [CosmittStarComponent]
})
export class CosmittStarModule {}  