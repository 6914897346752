import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { lastValueFrom } from "rxjs";
import { environment } from "src/environment/environment";
import { Equipable } from "src/types/equipables";


@Injectable({
    providedIn: "root"
})
export class EquipablesService {

    constructor(private httpClient: HttpClient) { }

    async getEquipablesByType(type: string, limit: number = 0, page: number = 0): Promise<Equipable[]> {
        let url = environment.apiBaseUrl + `/equipables?type=${type}&limit=${limit}&page=${page}`;
        const equipables = await lastValueFrom(this.httpClient.get(url)) as Equipable[];
        return equipables;
    }

    async getEquipable(equipableId: string): Promise<Equipable> {
        const equipable = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/equipables/${equipableId}`)) as Equipable;
        return equipable;
    }

    async getEquipableByName(name: string): Promise<Equipable> {
        const equipable = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/equipables/byName/${name}`)) as Equipable;
        return equipable;
    }

    async getEquipableByList(idList: string[]): Promise<Equipable[]> {
        let params = new HttpParams();
        params = params.append('list', idList.toString());
        const equipables = await lastValueFrom(this.httpClient.get(environment.apiBaseUrl + `/equipables/by/list`, { params })) as Equipable[];
        return equipables;
    }

    async updateEquipable(equipableId: string, updates: Partial<Equipable>) {
        const equipable = await lastValueFrom(this.httpClient.patch(environment.apiBaseUrl + `/equipables/${equipableId}`, updates)) as Equipable;
        return equipable;
    }

    async createEquipable(newEquipable: Partial<Equipable>) {
        const equipable = await lastValueFrom(this.httpClient.post(environment.apiBaseUrl + `/equipables`, newEquipable)) as Equipable;
        return equipable;
    }

}