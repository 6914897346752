import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { SettingsComponent } from "./settings.component";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CharacterIconRenderModule } from "src/components/character-icon-render/character-icon-render.module";

@NgModule({
    declarations: [SettingsComponent],
    imports: [BrowserModule, RouterModule, FormsModule, CosmittInputModule, CharacterIconRenderModule],
    exports: [SettingsComponent]
})
export class SettingsModule {}  