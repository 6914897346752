import { DragDropModule } from "@angular/cdk/drag-drop";
import { NgModule } from "@angular/core";
import { AdminQaSidebarComponent } from "./admin-qa-sidebar.component";
import { BrowserModule } from "@angular/platform-browser";
import { OverlayModule } from "@angular/cdk/overlay";

@NgModule({
    declarations: [AdminQaSidebarComponent],
    imports: [DragDropModule, BrowserModule, OverlayModule],
    exports: [AdminQaSidebarComponent]
})
export class AdminQaSidebarModule {}  