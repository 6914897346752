<!-- Helmet images -->
<div *ngIf="helmetHead" class="helmetHead" [class]="hairColors" [innerHtml]="helmetHeadSvgData" [style.width]="helmetHead.size.width + 'px'" [style.transform]="getItemTransform(helmetHead)"></div>
<div *ngIf="helmetBehindHead" class="helmetBehindHead" [class]="hairColors" [innerHtml]="helmetBehindHeadSvgData" [style.width]="helmetBehindHead.size.width + 'px'" [style.transform]="getItemTransform(helmetBehindHead)"></div>

<!-- Head -->
<div *ngIf="eyes" class="eyes" [class]="hairColors" [innerHtml]="eyesSvgData" [style.width]="eyes.size.width + 'px'" [style.transform]="getItemTransform(eyes)"></div>
<div *ngIf="head" class="head" [class]="skinColors" [innerHtml]="headSvgData" [style.width]="head.size.width + 'px'"></div>

<!-- Upper body -->
<div *ngIf="armBackUpper" class="armBackUpper" [class]="skinColors" [innerHtml]="armBackUpperSvgData" [style.width]="armBackUpper.size.width + 'px'" [style.transform]="getItemTransform(armBackUpper)"></div>
<div *ngIf="torso" class="torso" [class]="skinColors" [innerHtml]="torsoSvgData" [style.width]="torso.size.width + 'px'" [style.transform]="getItemTransform(torso)"></div>
<div *ngIf="armFrontUpper" class="armFrontUpper" [class]="skinColors" [innerHtml]="armFrontUpperSvgData" [style.width]="armFrontUpper.size.width + 'px'" [style.transform]="getItemTransform(armFrontUpper)"></div>

<!-- Back -->
<div *ngIf="back" class="back" [class]="skinColors" [innerHtml]="backSvgData" [style.width]="back.size.width + 'px'"></div>