import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UnscrambleComponent } from "./unscramble.component";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [UnscrambleComponent],
    imports: [FormsModule, DragDropModule, BrowserModule],
    exports: [UnscrambleComponent]
})
export class UnscrambleModule {}