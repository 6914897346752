import { NgModule } from "@angular/core";
import { CosmittSelectComponent } from "./c-select.component";
import { BrowserModule } from "@angular/platform-browser";
import { ClickOutsideModule } from "src/directives/click-outside.module";

@NgModule({
    declarations: [CosmittSelectComponent],
    imports: [BrowserModule, ClickOutsideModule],
    exports: [CosmittSelectComponent]
})
export class CosmittSelectModule {}  