<div class="lesson-intro-steps" *ngIf="lesson && introStepsActive">
    <intro-steps
        [steps]="lesson.introSteps"
        (finished)="completeIntroSteps()"
    ></intro-steps>
</div>

<div class="lesson-intro" *ngIf="!introStepsActive && introActive" [class.open]="introOpen">
    <!-- <div [style.background-image]="'url(https://prod-cosmitt-learning-app.s3.us-east-2.amazonaws.com/backgrounds/trench.jpg)'" class="lesson-image"></div> -->
    <div *ngIf="lesson" class="main-content">
        <img class="medal" [src]="getMedalUrl()">
        <div class="text">
            <h2 (click)="playSpeakable(lesson.speechData?.name, lesson.name)" [class.speakable]="isClickToSpeechActive()">{{ lesson.name }}</h2>
            <img class="header-decor" src="../../../assets/images/lesson-header-decor.png">
            <p (click)="playSpeakable(lesson.speechData?.description, lesson.description)" [class.speakable]="isClickToSpeechActive()" [innerHTML]="lesson.description"></p>
        </div>
        <div class="buttons">
            <!-- <button [routerLink]="'/student/dashboard'" class="secondary">Back to dashboard</button> -->
            <button *ngIf="lesson.introSteps.length > 0" (click)="startIntroSteps()" class="secondary">Replay tutorial</button>
            <button (click)="startLesson()">{{ introStartText }}</button>
        </div>
    </div>
    <div class="rewards">
        <!-- <p>Rewards</p> -->
        <div class="items">
            <div class="experience">
                <h4>50</h4>
                <p class="caption">exp</p>
            </div>
            <div class="gold">
                <h4>50</h4>
                <p class="caption">gold</p>
            </div>
        </div>
    </div>
</div>

<cl-modal *ngIf="outroActive" [(open)]="outroOpen" [allowBackgroundClose]="false" [glass]="true">
    <div class="lesson-outro">
        <img class="confetti" src="../../assets/images/lesson-complete-confetti.png">
        <div *ngIf="lesson && currentRecord" class="main-content">
            <h4>{{ getCompleteMessage() }}</h4>
            <img *ngIf="currentRecord.grade >= 90" class="medal" src="../../assets/images/Gold Medal.png">
            <img *ngIf="currentRecord.grade >= 80 && currentRecord.grade < 90" class="medal" src="../../assets/images/Silver Medal.png">
            <img *ngIf="currentRecord.grade >= 70 && currentRecord.grade < 80" class="medal" src="../../assets/images/Bronze Medal.png">
            <img *ngIf="!currentRecord || currentRecord.grade < 60" class="medal" src="../../assets/images/Transparent Medal Whole.png">
            <div class="text">
                <h3>{{ getMedalMessage() }}</h3>
                <p>{{ getSubMedalMessage() }}</p>
            </div>
            <div class="buttons">
                <button [routerLink]="'/student/lesson/' + nextLessonId">Next lesson</button>
            </div>
        </div>
    </div>
</cl-modal>

<div class="page-holder">
    <question-mapper *ngIf="currentQuestion" [lessonId]="lesson._id" [question]="currentQuestion" (answeredCorrectly)="answeredCorrectly()" (answeredIncorrectly)="answeredIncorrectly()" (completedQuestion)="completedQuestion()"></question-mapper>
    <section class="stats">
        <lesson-progress [progress]="progress"></lesson-progress>
    </section>
</div>