<div class="question-holder" [class.displayWithoutAnimation]="!animationsOn" [class.animateIn]="sentenceAnimateIn" [class.animateOut]="sentenceAnimateOut">
    <div *ngIf="_imageUrls" class="images">
        <img *ngFor="let url of _imageUrls" [src]="url">
    </div>

    <div id="sentence-area" class="row">
        <p (click)="selectWord(i)" *ngFor="let word of question.data.words; let i = index;" [class.speakable]="isClickToSpeechActive()" [style]="{color: getChoiceColor(i), 'margin-left' : isPunctuation(word.text) ? 0 : ''}"  class="selectable">
            {{ word.text }}
        </p>
    </div>
</div>