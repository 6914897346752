import { Component } from "@angular/core";
import { ClassroomsService } from "src/services/classroom.service";
import { UsersService } from "src/services/users.service";
import { Classroom } from "src/types/classrooms";
import { Student, Teacher } from "src/types/users";


@Component({
    selector: "teacher-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class TeacherDashboardComponent {
    teacher: Teacher;
    classroom: Classroom;

    studentList: Student[] = [];

    allInputsDisabled: boolean = false;

    newStudentModalOpen: boolean = false;
    newStudentName: string = "";
    newStudentUsername: string = "";
    newStudentPassword: string = "";
    errorText: string = "";

    constructor(private usersService: UsersService, private classroomsService: ClassroomsService) {
        this.usersService.setUserFocusMode(true);
        this.init();
    }

    async init() {
        this.teacher = await this.usersService.getUser() as Teacher;
        // this.classroom = await this.classroomsService.getClassroomById(this.teacher.classroomIds[0]);
        this.studentList = await this.usersService.getMultipleStudents(this.teacher.studentList.map((student) => student.id));
    }

    openNewStudentModal() {
        this.newStudentModalOpen = true;
    }

    closeNewStudentModal() {
        this.newStudentModalOpen = false;
    }

    async createNewStudent() {
        try {
            let hasError: boolean = false;

            // Perform checks for validity of form and set error messages
            if (!this.newStudentName) {
                hasError = true;
            }

            if (!this.newStudentUsername) {
                hasError = true;
            }

            if (!this.newStudentPassword) {
                hasError = true;
            }

            if (hasError) {
                this.errorText = "All fields are required.";
                return;
            }

            this.allInputsDisabled = true;

            const nameSplit = this.newStudentName.split(' ');

            // Create object for new user data
            let newUserData: Partial<Student> = {
                firstName: nameSplit[0],
                lastName: nameSplit.length > 1 ? nameSplit[1] : '',
                username: this.newStudentUsername,
                acceptedTermsAndConditions: true,
            }

            const newStudent = await this.usersService.createStudentUnderTeacher(newUserData, this.newStudentPassword, this.teacher._id);

            this.teacher.studentList.push({
                id: newStudent._id,
                firstName: newStudent.firstName,
                lastName: newStudent.lastName,
                username: newStudent.username
            });
            
            this.studentList.push(newStudent as Student);
            this.errorText = '';
            this.newStudentName = '';
            this.newStudentUsername = '';
            this.newStudentPassword = '';
            this.allInputsDisabled = false;
            this.newStudentModalOpen = false;
        } catch (error: any) {
            this.errorText = error.message;
            this.allInputsDisabled = false;
        }
    }
}