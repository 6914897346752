import { NgModule } from "@angular/core";
import { CosmittModalModule } from "src/components/modal/modal.module";
import { RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { AdminLessonEditorPage } from "./lesson-editor.component";
import { QuestionEditorModule } from "src/components/question-editor/question-editor.module";
import { CosmittSelectModule } from "src/components/c-select/c-select.module";
import { CosmittTextareaModule } from "src/components/c-textarea/c-textarea.module";
import { CosmittTabsModule } from "src/components/c-tabs/c-tabs.module";
import { CosmittTableModule } from "src/components/c-table/c-table.module";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { CosmittTextEditorModule } from "src/components/c-text-editor/c-text-editor.module";
import { SearchInputModule } from "src/components/search-input/search-input.module";
import { CosmittPopoverModule } from "src/components/c-popover/c-popover.module";
import { FormsModule } from "@angular/forms";
import { CosmittSpinnerModule } from "src/components/c-spinner/c-spinner.module";
import { LessonStepEditorModule } from "src/components/lesson-step-editor/lesson-step-editor.module";
import { ClickOutsideModule } from "src/directives/click-outside.module";
import { CosmittButtonGroupModule } from "src/components/c-button-group/c-button-group.module";

@NgModule({
    declarations: [AdminLessonEditorPage],
    imports: [
        CosmittModalModule, 
        RouterModule, 
        BrowserModule,
        FormsModule,
        QuestionEditorModule, 
        CosmittSpinnerModule,
        CosmittSelectModule, 
        CosmittInputModule,
        CosmittTextareaModule,
        CosmittTextEditorModule,
        SearchInputModule,
        CosmittTabsModule,
        CosmittTableModule,
        CosmittPopoverModule,
        LessonStepEditorModule,
        ClickOutsideModule,
        CosmittButtonGroupModule
    ],
    exports: [AdminLessonEditorPage]
})
export class AdminLessonEditorModule {};