<cl-modal [(open)]="questionsGenerationModalOpen" [allowBackgroundClose]="true">
    <div class="question-generation">
        <c-select icon="game" label="Game mode" [options]="gameModeOptions" [(cModel)]="questionGenerationType"></c-select>
        <c-textarea [(cModel)]="questionGenerationJSON" label="Question JSON" resize="none"></c-textarea>
        <button (click)="generateQuestions()">Generate</button>
    </div>
</cl-modal>

<cl-modal [(open)]="questionEditModalOpen" (modalClosed)="questionBackgroundClose()">
    <question-editor 
        *ngIf="currentQuestion"
        [lessonId]="lesson._id"
        [(question)]="currentQuestion"
        [creating]="creatingQuestion"
        (save)="saveQuestion()"
        (closeBackground)="questionBackgroundClose()">
    </question-editor>
</cl-modal>

<cl-modal [(open)]="lessonStepEditModalOpen" (modalClosed)="stepBackgroundClose()">
    <lesson-step-editor 
        *ngIf="currentLessonStep"
        [(step)]="currentLessonStep"
        [creating]="creatingLessonStep"
        (save)="saveStep()"
        (closeBackground)="stepBackgroundClose()">
    </lesson-step-editor>
</cl-modal>

<cl-modal [(open)]="questionsGenerationModalOpen" [allowBackgroundClose]="true">
    <div class="question-generation">
        <c-select icon="game" label="Game mode" [options]="gameModeOptions" [(cModel)]="questionGenerationType"></c-select>
        <c-textarea [(cModel)]="questionGenerationJSON" label="Question JSON" resize="none"></c-textarea>
        <button (click)="generateQuestions()">Generate</button>
    </div>
</cl-modal>



<div class="content">
    <div class="header">
        <h3 *ngIf="lesson">{{ lesson.name }}</h3>
        <div class="actions">
            <div class="actions-container" (click)="lessonActionsPopoverShow()" clickOutside (clickOutside)="lessonActionsPopoverClickOutside()">
                <svg width="28" height="6" viewBox="0 0 28 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="6" height="6" rx="3" fill="black"/><rect x="11" width="6" height="6" rx="3" fill="black"/><rect x="22" width="6" height="6" rx="3" fill="black"/></svg>
                <c-popover position="below" [visible]="lessonActionsPopoverVisible">
                    <button class="actions-button" (click)="goToQaTesting(lesson)">
                        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.4677 8.83726L33.8316 15.2012L10.6828 38.3501C9.02731 40.0055 6.76237 40.9049 4.42224 40.836L2.37812 40.7758C2.11352 40.768 1.90084 40.5554 1.89305 40.2908L1.83288 38.2466L0.33353 38.2908L1.83288 38.2466C1.76399 35.9065 2.66335 33.6416 4.31879 31.9861L27.4677 8.83726ZM29.589 6.71594L33.3727 2.93221C35.1301 1.17485 37.9793 1.17485 39.7367 2.93221C41.494 4.68957 41.494 7.53881 39.7367 9.29617L35.9529 13.0799L29.589 6.71594Z"/></svg>
                        <p>QA Test</p>
                    </button>
                    <button class="actions-button" (click)="deleteLesson()">
                        <svg width="38" height="47" viewBox="0 0 38 47" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9V35C5 40.5228 9.47715 45 15 45H23C28.5228 45 33 40.5228 33 35V9M5 9H9.5M5 9H1.5M33 9H28.5M33 9H36.5M28.5 9V9C28.5 5.13401 25.366 2 21.5 2H16.5C12.634 2 9.5 5.13401 9.5 9V9M28.5 9H9.5M23 33C23 28.3137 23 25.6863 23 21M15 33C15 28.3137 15 25.6863 15 21" stroke-linecap="round" stroke-linejoin="round"/></svg>
                        <p>Delete</p>
                    </button>
                </c-popover>
            </div>
        </div>
    </div>

    <div class="body" *ngIf="lesson">
        <c-tabs 
            [tabs]="tabs"
            (tabSelected)="onTabSelected($event)">
        </c-tabs>

        <div *ngIf="currentTabIndex === 0" class="overview">
            <c-input [label]="'Name'" [(cModel)]="lesson.name"></c-input>
            <c-text-editor [label]="'Description'" [(cModel)]="lesson.description"></c-text-editor>
            <search-input [label]="'Parent Course'" [control]="searchControl" [searchResults]="searchResults" [searchFn]="search.bind(this)" [selectResultFn]="selectCourseSearchResult.bind(this)"></search-input>
            <button (click)="saveLesson()">Save</button>
        </div>



        <!-- Questions -->

        <div *ngIf="currentTabIndex === 1" class="tab-content">
            <div class="search-row">
                <div class="input-area">
                    <img (click)="searchQuestions()" class="search-icon" src="../../assets/icons/search.svg">
                    <input (keyup.enter)="searchQuestions()" [(ngModel)]="searchQuestionsModel" placeholder="Search for question by sentence">
                </div>
                
                <div class="actions-container star" (click)="assessDifficulties()">
                    <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2L14.09 8.26L20 9.27L16 13.14L17.18 19.02L12 16.24L6.82 19.02L8 13.14L4 9.27L9.91 8.26L12 2Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                <div class="actions-container star" (click)="assessDifficulties()">
                    <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 2L14.09 8.26L20 9.27L16 13.14L17.18 19.02L12 16.24L6.82 19.02L8 13.14L4 9.27L9.91 8.26L12 2Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>

                <div>
                    <div class="actions-container plus" (click)="openCreateQuestion()">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.66667 13V2M2 7.5H13" stroke-width="3" stroke-linecap="round"/></svg>
                    </div>
                </div>
            </div>
            <c-table
                [headers]="[
                    { key: 'data.sentence', label: 'Sentence', alignment: 'left' },
                    { key: 'type', label: 'Game mode', alignment: 'center', template: questionTypeTemplate },
                    { key: 'difficulty', label: 'Difficulty', default: '0', alignment: 'center' },
                    { key: 'actions', label: '', alignment: 'right', template: actionsTemplate, overflow: 'visible' }
                ]"
                [data]="lesson.questions"
                (rowSelected)="selectEditQuestion($event)"
                [showIndex]="true">
            </c-table>

            <ng-template #actionsTemplate let-row="row" let-index="index">
                <div class="actions-container" (click)="actionFocussedForPopover($event, index)" clickOutside (clickOutside)="actionBlurForPopover()">
                    <svg width="28" height="6" viewBox="0 0 28 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="6" height="6" rx="3" fill="black"/><rect x="11" width="6" height="6" rx="3" fill="black"/><rect x="22" width="6" height="6" rx="3" fill="black"/></svg>
                    <c-popover position="below" [visible]="popoverVisibleIndex === index">
                        <button class="actions-button" (click)="selectEditQuestion(index)">
                            <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.4677 8.83726L33.8316 15.2012L10.6828 38.3501C9.02731 40.0055 6.76237 40.9049 4.42224 40.836L2.37812 40.7758C2.11352 40.768 1.90084 40.5554 1.89305 40.2908L1.83288 38.2466L0.33353 38.2908L1.83288 38.2466C1.76399 35.9065 2.66335 33.6416 4.31879 31.9861L27.4677 8.83726ZM29.589 6.71594L33.3727 2.93221C35.1301 1.17485 37.9793 1.17485 39.7367 2.93221C41.494 4.68957 41.494 7.53881 39.7367 9.29617L35.9529 13.0799L29.589 6.71594Z"/></svg>
                            <p>Edit</p>
                        </button>
                        <button class="actions-button" (click)="deleteRightClickedQuestion()">
                            <svg width="38" height="47" viewBox="0 0 38 47" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9V35C5 40.5228 9.47715 45 15 45H23C28.5228 45 33 40.5228 33 35V9M5 9H9.5M5 9H1.5M33 9H28.5M33 9H36.5M28.5 9V9C28.5 5.13401 25.366 2 21.5 2H16.5C12.634 2 9.5 5.13401 9.5 9V9M28.5 9H9.5M23 33C23 28.3137 23 25.6863 23 21M15 33C15 28.3137 15 25.6863 15 21" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            <p>Delete</p>
                        </button>
                    </c-popover>
                </div>
            </ng-template>

            <ng-template #questionTypeTemplate let-row="row" let-index="index">
                <span class="game-mode-tag" [class]="getGameModeField(lesson.questions[index], 'text')">{{ getGameModeField(lesson.questions[index], 'text') }}</span>
            </ng-template>
        </div>



        <!-- Intro steps -->

        <div *ngIf="currentTabIndex === 2" class="tab-content">
            <div class="search-row">
                <div class="input-area">
                    <img (click)="searchQuestions()" class="search-icon" src="../../assets/icons/search.svg">
                    <input (keyup.enter)="searchQuestions()" [(ngModel)]="searchQuestionsModel" placeholder="Search for step by text">
                </div>

                <div>
                    <div class="actions-container plus" (click)="openCreateStep()">
                        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.66667 13V2M2 7.5H13" stroke-width="3" stroke-linecap="round"/></svg>
                    </div>
                </div>
            </div>
            <c-table
                [headers]="[
                    { key: 'textData.displayText', label: 'Text', alignment: 'left' },
                    { key: 'type', label: 'Type', alignment: 'center' },
                    { key: 'actions', label: '', alignment: 'right', template: actionsTemplate, overflow: 'visible' }
                ]"
                [data]="lesson.introSteps"
                [showIndex]="true"
                (rowSelected)="selectEditStep($event)">
            </c-table>

            <ng-template #actionsTemplate let-row="row" let-index="index">
                <div class="actions-container" (click)="actionFocussedForPopover($event, index)" clickOutside (clickOutside)="actionBlurForPopover()">
                    <svg width="28" height="6" viewBox="0 0 28 6" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="6" height="6" rx="3" fill="black"/><rect x="11" width="6" height="6" rx="3" fill="black"/><rect x="22" width="6" height="6" rx="3" fill="black"/></svg>
                    <c-popover position="below" [visible]="popoverVisibleIndex === index">
                        <button class="actions-button" (click)="selectEditStep(index)">
                            <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27.4677 8.83726L33.8316 15.2012L10.6828 38.3501C9.02731 40.0055 6.76237 40.9049 4.42224 40.836L2.37812 40.7758C2.11352 40.768 1.90084 40.5554 1.89305 40.2908L1.83288 38.2466L0.33353 38.2908L1.83288 38.2466C1.76399 35.9065 2.66335 33.6416 4.31879 31.9861L27.4677 8.83726ZM29.589 6.71594L33.3727 2.93221C35.1301 1.17485 37.9793 1.17485 39.7367 2.93221C41.494 4.68957 41.494 7.53881 39.7367 9.29617L35.9529 13.0799L29.589 6.71594Z"/></svg>
                            <p>Edit</p>
                        </button>
                        <button class="actions-button" (click)="deleteStep(index)">
                            <svg width="38" height="47" viewBox="0 0 38 47" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5 9V35C5 40.5228 9.47715 45 15 45H23C28.5228 45 33 40.5228 33 35V9M5 9H9.5M5 9H1.5M33 9H28.5M33 9H36.5M28.5 9V9C28.5 5.13401 25.366 2 21.5 2H16.5C12.634 2 9.5 5.13401 9.5 9V9M28.5 9H9.5M23 33C23 28.3137 23 25.6863 23 21M15 33C15 28.3137 15 25.6863 15 21" stroke-linecap="round" stroke-linejoin="round"/></svg>
                            <p>Delete</p>
                        </button>
                    </c-popover>
                </div>
            </ng-template>
        </div>
    </div>
</div>