import { NgModule } from "@angular/core";
import { CosmittSelectModule } from "../c-select/c-select.module";
import { CosmittInputModule } from "../c-input/c-input.module";
import { CosmittTextEditorModule } from "../c-text-editor/c-text-editor.module";
import { SearchInputModule } from "../search-input/search-input.module";
import { ModuleEditorComponent } from "./module-editor.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [ModuleEditorComponent],
    imports: [BrowserModule, CosmittInputModule, CosmittSelectModule, CosmittTextEditorModule, SearchInputModule],
    exports: [ModuleEditorComponent]
})
export class ModuleEditorModule {} 