import { NgModule } from '@angular/core';
import { CourseCardModule } from 'src/components/course-card/course-card.module';
import { StudentDashboardComponent } from './dashboard.component';
import { BrowserModule } from '@angular/platform-browser';
import { UpgradePopupModule } from 'src/components/upgrade-popup/upgrade-popup.module';
import { CharacterChatModule } from 'src/components/character-chat/character-chat.module';
import { CharacterCustomizerModule } from 'src/components/character-customizer/character-customizer.module';
import { CosmittModalModule } from 'src/components/modal/modal.module';
import { CharacterRenderModule } from 'src/components/character-render/character-render.module';
import { CourseTotemModule } from 'src/components/course-totem/course-totem.module';
import { SecureImagePipeModule } from 'src/pipes/secure-image.module';

@NgModule({
  declarations: [StudentDashboardComponent],
  imports: [
    CourseCardModule,
    BrowserModule,
    UpgradePopupModule,
    CharacterChatModule,
    CharacterCustomizerModule,
    CosmittModalModule,
    CharacterRenderModule,
    CourseTotemModule,
    SecureImagePipeModule
  ],
  exports: [StudentDashboardComponent],
})
export class StudentDashboardModule {}
