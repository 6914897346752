import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { CosmittInputModule } from "src/components/c-input/c-input.module";
import { SelectTheWordEditorComponent } from "./select-the-word-editor.component";
import { BrowserModule } from "@angular/platform-browser";
import { CosmittImageUploaderModule } from "src/components/c-image-uploader/c-image-uploader.module";

@NgModule({
    declarations: [SelectTheWordEditorComponent],
    imports: [CosmittInputModule, FormsModule, BrowserModule, CosmittImageUploaderModule],
    exports: [SelectTheWordEditorComponent]
})
export class SelectTheWordEditorModule {}  