<cl-modal [(open)]="questionEditOpen" (modalClosed)="questionBackgroundClose()">
    <question-editor 
        *ngIf="question"
        [(question)]="question"
        [creating]="false"
        (save)="saveQuestion()"
        (closeBackground)="questionBackgroundClose()">
    </question-editor>
</cl-modal>



<h2>Step Editor</h2>

<div class="inner-content scrollbar">
    <c-select icon="game" label="Type" [options]="stepTypes" [(cModel)]="modifiedStep.type" (cModelChange)="typeChanged()"></c-select>

    <c-input [(cModel)]="modifiedStep.textData.displayText" label="Display text"></c-input>

    <div class="character-voice-row">
        <c-input [(cModel)]="modifiedStep.textData.voicedTextData.text" label="Voiced text"></c-input>
        <c-select icon="game" label="Character voice" [options]="characterOptions" [(cModel)]="modifiedStep.textData.voicedTextData.character"></c-select>
    </div>

    <hr>

    <c-button-group
        label="First character"
        [options]="characterButtonGroupOptions"
        [(cModel)]="characterOneModel"
    ></c-button-group>

    <div *ngIf="characterOneModel" class="character-row">
        <c-select icon="game" label="Character" [options]="characterOptions" [(cModel)]="modifiedStep.firstCharacter.character"></c-select>
        <c-select icon="game" label="Position" [options]="positionOptions" [(cModel)]="modifiedStep.firstCharacter.position"></c-select>
        <c-select icon="game" label="Emotion" [options]="emoteOptions" [(cModel)]="modifiedStep.firstCharacter.emote"></c-select>
    </div>

    <hr>

    <!-- <c-button-group
        label="Second character"
        [options]="characterButtonGroupOptions"
        [(cModel)]="characterTwoModel"
    ></c-button-group>

    <div *ngIf="characterTwoModel" class="character-row">
        <c-select icon="game" label="Character" [options]="characterOptions" [(cModel)]="modifiedStep.secondCharacter.character"></c-select>
        <c-select icon="game" label="Position" [options]="positionOptions" [(cModel)]="modifiedStep.secondCharacter.position"></c-select>
        <c-select icon="game" label="Emotion" [options]="emoteOptions" [(cModel)]="modifiedStep.secondCharacter.emote"></c-select>
    </div> -->

    <div *ngIf="modifiedStep.type === 'QUESTION'" class="guided-question-options">
        <hr>
        
        <c-input [(cModel)]="modifiedStep.data.answeredIncorrectVoicedText" label="Incorrect voice text"></c-input>

        <p *ngIf="modifiedStep.data?.question" [innerHTML]="modifiedStep.data?.question.data.instruction || 'Question not setup yet...'"></p>
        <button (click)="openQuestionEditor()">Edit question</button>

        <button (click)="addReply()">Add reply</button>

        <div *ngFor="let reply of modifiedStep.data.answerReplies; let i = index">
            <h6>Reply {{ i + 1 }}</h6>
            <c-input [(cModel)]="reply.beforeAnsweringVoicedText" label="Prior to answering text"></c-input>
            <c-input [(cModel)]="reply.answeredCorrectVoicedText" label="Answering text"></c-input>
        </div>
    </div>

    <div *ngIf="modifiedStep.type === 'GUIDED_QUESTION'" class="guided-question-options">
        <hr>
        <p *ngIf="modifiedStep.data?.question" [innerHTML]="modifiedStep.data?.question.data.instruction || 'Question not setup yet...'"></p>
        <button (click)="openQuestionEditor()">Edit question</button>

        <button (click)="addReply()">Add reply</button>

        <div *ngFor="let reply of modifiedStep.data.answerReplies; let i = index">
            <h6>Reply {{ i + 1 }}</h6>
            <c-input [(cModel)]="reply.beforeAnsweringVoicedText" label="Prior to answering text"></c-input>
            <c-input [(cModel)]="reply.answeredCorrectVoicedText" label="Answering text"></c-input>
        </div>
    </div>

</div>

<div class="buttons">
    <button (click)="discard()" class="secondary">Discard</button>
    <button (click)="saveFN()">{{ creating ? 'Create' : 'Save'}}</button>
</div>