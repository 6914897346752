import { Component, Input } from '@angular/core';
import { environment } from 'src/environment/environment';
import { SpeakableService } from 'src/services/speakable.service';
import { CurriculumLesson } from 'src/types/modules';
@Component({
  selector: 'course-totem',
  templateUrl: './course-totem.component.html',
  styleUrl: './course-totem.component.scss',
})
export class CourseTotemComponent {
  @Input() lesson: CurriculumLesson;
  @Input() isActive: boolean = false;
  @Input() stars: number;
  @Input() grade: number;
  @Input() background: string;

  totemHeight: number = window.innerHeight * 0.6;
  baseApiUrl: string = environment.apiBaseUrl;

  constructor(private speakableService: SpeakableService) {}

  onClick() {
    this.isActive = !this.isActive;
  }

  isClickToSpeechActive() {
    return this.speakableService.clickToSpeechActive;
  }

  playSpeakable(url: string | undefined, text: string) {
    // Return if user isn't actively in text to speech mode
    if(!this.speakableService.clickToSpeechActive) return;
    this.speakableService.getAndPlaySpeakable(url, text);
}
}
