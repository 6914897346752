<div class="top-row">
    <div>
        <c-input
            label="Title"
            type="text"
            [placeholder]="getType() + ' name'"
            [(cModel)]="name"
            (cModelChange)="nameModelChange($event)"
        ></c-input>
    </div>
    <div *ngIf="moduleType === 'Grades'" class="grade-level">
        <c-select label="Grade Level" [options]="gradeLevelOptions"></c-select>
    </div>
</div>

<c-text-editor label="Description" [(cModel)]="description" (cModelChange)="descriptionModelChange($event)"></c-text-editor>

<div *ngIf="getParentType() !== 'Unknown'">
    <!-- <h6>Parent {{ getParentType() }}</h6> -->
    <search-input [label]="'Parent ' + getParentType()" actionText="Go to {{ getParentType() }}" (actionE)="gotoParent.emit(parentId)" [control]="searchControl" [searchResults]="searchResults" [searchFn]="search.bind(this)" [selectResultFn]="select.bind(this)"></search-input>
</div>

<div class="buttons">
    <button (click)="discard()" class="secondary">Discard</button>
    <button (click)="save()">{{ creating ? 'Create' : 'Save'}}</button>
</div>