import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { FillInTheBlankComponent } from "./fill-in-the-blank.component";
import { BrowserModule } from "@angular/platform-browser";

@NgModule({
    declarations: [FillInTheBlankComponent],
    imports: [FormsModule, BrowserModule],
    exports: [FillInTheBlankComponent]
})
export class FillInTheBlankModule {}  