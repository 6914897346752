import { Component, Input } from '@angular/core';
import { CurriculumLesson } from 'src/types/modules';

@Component({
  selector: 'course-totem',
  templateUrl: './course-totem.component.html',
  styleUrl: './course-totem.component.scss',
})
export class CourseTotemComponent {
  @Input() lesson: CurriculumLesson;
  @Input() isActive: boolean = false;
  @Input() stars: number;
  @Input() grade: number;

  constructor() {}

  onClick() {
    this.isActive = !this.isActive;
  }
}
