import { isLesson, isCourse, isCategory, isGrade, isSubject, isCurriculum } from "src/types/modules";
import { QuestionType } from "src/types/question";

export type GameModeMappingKeys = QuestionType | 'unknown';

export const gameModeMapping: { [key in GameModeMappingKeys]: any } = {
    selectTheWord: {
        backgroundColor: "#C0E2DB",
        textColor: "#107662",
        text: "STW"
    },
    selectAndChange: {
        backgroundColor: "#C2DEEA",
        textColor: "#199CD4",
        text: "SAC"
    },
    fillInTheBlank: {
        backgroundColor: "#C8E3C3",
        textColor: "#559249",
        text: "FITB"
    },
    multipleChoice: {
        backgroundColor: "#E9C6D0",
        textColor: "#DF4170",
        text: "MC"
    },
    grouping: {
        backgroundColor: "#CBC6E9",
        textColor: "#614FD0",
        text: "GR"
    },
    ranking: {
        backgroundColor: "#EADEC5",
        textColor: "#D09E36",
        text: "RN"
    },
    unscramble: {
        backgroundColor: "#DDD2E6",
        textColor: "#9A5FC9",
        text: "UN"
    },
    spelling: {
        backgroundColor: "#EAD4BF",
        textColor: "#CE7624",
        text: "SP"
    },
    spellingMultiStep: {
        backgroundColor: "#EAD4BF",
        textColor: "#CE7624",
        text: "SP"
    },
    unknown: {
        backgroundColor: "#CBCBCB",
        textColor: "#717171",
        text: "UK"
    },
};

export type QaMappingKeys = 'reviewed' | 'flagged' | 'unknown';

export const qaMapping: { [key in QaMappingKeys]: any } = {
    reviewed: {
        backgroundColor: "#D5F7F0",
        textColor: "#107662",
        text: "Reviewed"
    },
    flagged: {
        backgroundColor: "#EED1D2",
        textColor: "#BD2E2E",
        text: "Flagged"
    },
    unknown: {
        backgroundColor: "#FBEFD6",
        textColor: "#D09E36",
        text: "Not checked"
    }
}

export type ModuleType = 'Lessons' | 'Courses' | 'Categories' | 'Grades' | 'Subjects' | 'Curriculum';
export type ModuleTypePlural = ModuleType | 'Questions';

export const isModuleTypePluralModuleType = (plural: ModuleTypePlural): plural is ModuleType => {
    return plural !== 'Questions';
}

export interface ModuleMapping {
    moduleTypeSingular: string;
    subModuleTypePlural: ModuleTypePlural;
    subModuleType: string;
    parentModuleTypeSingular?: string;
    parentModuleType?: ModuleType;
    parentIdField?: string;
    searchFunction: Function;
    createFunction: Function;
    updateFunction: Function;
    deleteFunction: Function;
}

export const getModuleType = (module: any): ModuleType => {
    if(isLesson(module)) {
        return 'Lessons';
    } else if(isCourse(module)) {
        return 'Courses';
    } else if(isCategory(module)) {
        return 'Categories';
    } else if(isGrade(module)) {
        return 'Grades';
    } else if(isSubject(module)) {
        return 'Subjects';
    } else if(isCurriculum(module)) {
        return 'Curriculum';
    }
    throw new Error(`Module type for ${module} was not found.`);
}

export const moduleMapping: {[key in ModuleType]: ModuleMapping} = {
    'Lessons': {
        moduleTypeSingular: 'Lesson',
        subModuleTypePlural: 'Questions',
        subModuleType: 'Question',
        parentModuleTypeSingular: 'Course',
        parentModuleType: 'Courses',
        parentIdField: 'courseId',
        searchFunction: () => { console.log('Search function has not been set yet.') },
        createFunction: () => { console.log('Create function has not been set yet.') },
        updateFunction: () => { console.log('Update function has not been set yet.') },
        deleteFunction: () => { console.log('Delete function has not been set yet.') }
    },
    'Courses': {
        moduleTypeSingular: 'Course',
        subModuleTypePlural: 'Lessons',
        subModuleType: 'Lesson',
        parentModuleTypeSingular: 'Category',
        parentModuleType: 'Categories',
        parentIdField: 'categoryId',
        searchFunction: () => { console.log('Search function has not been set yet.') },
        createFunction: () => { console.log('Create function has not been set yet.') },
        updateFunction: () => { console.log('Update function has not been set yet.') },
        deleteFunction: () => { console.log('Delete function has not been set yet.') }
    },
    'Categories': {
        moduleTypeSingular: 'Category',
        subModuleTypePlural: 'Courses',
        subModuleType: 'Course',
        parentModuleTypeSingular: 'Grade',
        parentModuleType: 'Grades',
        parentIdField: 'gradeId',
        searchFunction: () => { console.log('Search function has not been set yet.') },
        createFunction: () => { console.log('Create function has not been set yet.') },
        updateFunction: () => { console.log('Update function has not been set yet.') },
        deleteFunction: () => { console.log('Delete function has not been set yet.') }
    },
    'Grades': {
        moduleTypeSingular: 'Grade',
        subModuleTypePlural: 'Categories',
        subModuleType: 'Category',
        parentModuleTypeSingular: 'Subject',
        parentModuleType: 'Subjects',
        parentIdField: 'subjectId',
        searchFunction: () => { console.log('Search function has not been set yet.') },
        createFunction: () => { console.log('Create function has not been set yet.') },
        updateFunction: () => { console.log('Update function has not been set yet.') },
        deleteFunction: () => { console.log('Delete function has not been set yet.') }
    },
    'Subjects': {
        moduleTypeSingular: 'Subject',
        subModuleTypePlural: 'Grades',
        subModuleType: 'Grade',
        parentModuleTypeSingular: 'Curriculum',
        parentModuleType: 'Curriculum',
        parentIdField: 'curriculumId',
        searchFunction: () => { console.log('Search function has not been set yet.') },
        createFunction: () => { console.log('Create function has not been set yet.') },
        updateFunction: () => { console.log('Update function has not been set yet.') },
        deleteFunction: () => { console.log('Delete function has not been set yet.') }
    },
    'Curriculum': {
        moduleTypeSingular: 'Curriculum',
        subModuleTypePlural: 'Subjects',
        subModuleType: 'Subject',
        searchFunction: () => { console.log('Search function has not been set yet.') },
        createFunction: () => { console.log('Create function has not been set yet.') },
        updateFunction: () => { console.log('Update function has not been set yet.') },
        deleteFunction: () => { console.log('Delete function has not been set yet.') }
    },
}